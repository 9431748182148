<template>
  <div class="container">
    <div class='close' @click="closePanel()">×</div>
    <StartListRiderInfo v-if="this.pages.getPopupType() == 'riders'" />
    <RaceInfo v-if="this.pages.getPopupType() == 'race'" />
    <TeamsInfo v-if="this.pages.getPopupType() == 'teams'" />
    <RiderCountryInfo v-if="this.pages.getPopupType() == 'ridercountry'" />
    <SituationGroupInfo v-if="this.pages.getPopupType() == 'situationgroup'" />
    <TimetablePointInfo v-if="this.pages.getPopupType() == 'timetablepointinfo'" />
  </div>
</template>

<script>
  import StartListRiderInfo from '@/components/StartListRiderInfo'
  import RaceInfo from '@/components/RaceInfo'
  import TeamsInfo from '@/components/TeamsInfo'
  import RiderCountryInfo from '@/components/RiderCountryInfo'
  import SituationGroupInfo from '@/components/SituationGroupInfo'
  import TimetablePointInfo from '@/components/TimetablePointInfo'
  import { useStartlistStore } from '@/store/startlist'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'Popup',

    setup() {
      const startlist = useStartlistStore()
      const pages = usePagesStore()

      return {
        startlist,
        pages
      }
    },

    methods: {
      closePanel() {
        switch(this.pages.getPopupType()) {
          case "timetablepointinfo":
            this.pages.setPopupType('race')
          break

          default:
            this.pages.setVisiblePopup(false)
            this.pages.setPopupType('')
          break
        }
      }
    },

    components: {
      StartListRiderInfo,
      RaceInfo,
      TeamsInfo,
      RiderCountryInfo,
      SituationGroupInfo,
      TimetablePointInfo
    },
  }
</script>


<style scoped>

  .container {
    width: 100%;
    height: 100%;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    z-index: 9999;
    background: white;
    padding: 0 10px;
    overflow: auto;
  }

  .close {
    position: absolute;
    top: 1px;
    right: 0px;
    font-size: 40px;
    color: #CCC;
    cursor: pointer;
    background: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


</style>