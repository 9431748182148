<template>
  <div class="container_rider">
    <div class="position">{{ rider.dor }}</div>
    <div class="flagname">
      <div v-if="rider.pai !== ''" class="country_div" style="height:14px"><img class="country" :src="require(`@/assets/flags/${rider.pai}.png`)" /></div>
      <div class="fullname">{{ rider.afu }}</div>
    </div>
  </div>

</template>

<script>
  
  import { useTvStore } from '@/store/tv'

  export default {
    name: 'tv-situationgroupriders',

    props: {
      key: Number,
      rider: Object,
    },


    setup() {
      const tv = useTvStore()

      return {
        tv
      }
    },
    

  }
</script>

<style scoped>

 .container_rider {
    display: grid;
    align-items: center;
    grid-template-columns: 40px auto;
    padding: 3px 0px;
    font-size: 18px;
    width: 100%;
    color: white;
    background: black;
    height: 20px;
  }

  .flagname {
    display: flex;
    align-items: center;
    padding-left: 5px;
    position: relative;
  }

  .country {
    height: 14px;
    display:flex;    
  }

  .position {
    text-align: center;
    justify-content: center;
  }

  .country_div {
    display: flex;
    width: 30px;
  }

  .result {
    text-align: right;
  }

  .fullname {
    width: 135px;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  /*.fullname:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, transparent 115px, black);
  }*/

  .surname {
    font-weight: bold;
  }
</style>