<template>
    <div class="container_riders">
      <RiderCountryItem
          v-for="(country, index) in startlist.stats.countries"
          :key="index"
          :country="country"
          class="startlistrider child-element"
      ></RiderCountryItem>
    </div>
</template>

  export default {

  }

<script>

  import { useStartlistStore } from '@/store/startlist'
  import RiderCountryItem from '@/components/RiderCountryItem'

  export default {
    name: 'ridercountry',

    setup() {
      const startlist = useStartlistStore()

      return {
        startlist
      }
    },

    components: { 
        RiderCountryItem
    },
  }
</script>

<style scoped>


  .startlistrider {
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    transition: 0.3s;
  }

  .startlistrider:hover {
    background: white;
  }

  .startlistrider:last-child {
    border-bottom: 1px solid #dbdbdb;
  }


  .startlistrider:first-child {
    border-top: 1px solid #dbdbdb;
  }

</style>
