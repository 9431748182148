<template>  
  <div class="container">
    <div class="position">{{ parseInt(final.pos) }}</div>
    <div style="height:15px"><img class="country" :src="require(`@/assets/flags/${final.cou}.png`)" /></div>
    <div class="fullname">{{ final.nam.slice(0,1) }}. <span class="surname">{{ final.sur }}</span></div>
    <div class="result">{{ final.res }}</div>
  </div>
</template>

<script>


  export default {
    name: 'tv-list1item',

    props: {
      key: Number,
      final: Object,
    },

  }
</script>

<style scoped>

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px 200px 80px;
    padding: 5px 0px;
    margin: 3px 0px;
    font-size: 18px;
    width: 100%;
    color: white;
    background: black;
    height: 20px;
  }

  .country {
    height: 15px;
    display:flex;
  }

  .result {
    text-align: right;
  }
  
  .fullname {
    text-transform: uppercase;
  }

  .surname {
    font-weight: bold;
  }


</style>