<template>
  <div class="container_raceinfo">
    <div v-if="this.timetable.getTimetable().data.length !== 0">
      <div class="separator" style="margin-top:0px; padding-top: 0px;">{{ $t('timetable') }}</div>
      <RaceTimetable />
    </div>
    <div v-if="typeof this.update.getGeneral().weather !== 'undefined' && this.update.getGeneral().weather.length > 0">
      <div class="separator">{{ $t('weather') }}</div>
      <div>
        <div class="titulo_linea">{{ $t('start') }}</div>
        <Weather point='start' />
      </div>
      <div>
        <div class="titulo_linea">{{ $t('finish') }}</div>
        <Weather point='finish' />
      </div>
    </div>
    <div v-if="bestRiders().length > 0 || typeof getRiderYoungest().dor !== 'undefined' || typeof getRiderOldest().dor !== 'undefined'">
      <div class="separator">{{ $t('startlist') }}</div>
      <div v-if="bestRiders().length > 0">
        <div class="titulo_linea">{{ $t('ten_best_riders' + this.config.getGenere()) }}</div>
        <div class="linea" v-for="rider in bestRiders().slice(0,10)" :key="rider.dor" @click="clickRider(rider.dor)">
          <span class="position">{{ rider.dor }}</span>
          <span><img v-if="getRiderYoungest().pai" class="country" :src="require(`@/assets/flags/${rider.pai}.png`)" /></span>
          <span class="ridername">{{ rider.nfu }} <span style="text-transform:uppercase;font-weight:bold;">{{ rider.afu }}</span></span>
          <span></span>
        </div>
      </div>
      <div v-if="typeof getRiderYoungest().dor !== 'undefined'">
        <div class="titulo_linea">{{ $t('youngest' +  this.config.getGenere()) }}</div>
        <div class="linea" @click="clickRider(getRiderYoungest().dor)">
          <span class="position">{{ getRiderYoungest().dor }}</span>
          <span><img v-if="getRiderYoungest().pai" class="country" :src="require(`@/assets/flags/${getRiderYoungest().pai}.png`)" /></span>
          <span class="ridername">{{ getRiderYoungest().nfu }} <span style="text-transform:uppercase;font-weight:bold;">{{ getRiderYoungest().afu }}</span></span>
          <span style="text-align: right;">{{ getRiderYoungest().age }}</span>
        </div>
      </div>
      <div v-if="typeof getRiderOldest().dor !== 'undefined'">
        <div class="titulo_linea">{{ $t('oldest') }}</div>
        <div class="linea" @click="clickRider(getRiderOldest().dor)">
          <span class="position">{{ getRiderOldest().dor }}</span>
          <span><img v-if="getRiderOldest().pai" class="country" :src="require(`@/assets/flags/${getRiderOldest().pai}.png`)" /></span>
          <span class="ridername">{{ getRiderOldest().nfu }} <span style="text-transform:uppercase;font-weight:bold;">{{ getRiderOldest().afu }}</span></span>
          <span style="text-align: right;">{{ getRiderOldest().age }}</span>
        </div>
      </div>
    </div>
    <div v-if="this.config.data.stats.lasttopten.length !== 0">
      <div class="separator">{{ $t('race_lasttop10') }}</div>
      <RaceTop10 />
    </div>
    <div v-if="this.config.data.stats.lastwinners.length !== 0">
      <div class="separator">{{ $t('race_history') }}</div>
      <RaceHistory />
    </div>
  </div>
</template>


<script>

  import RaceTop10 from '@/components/RaceTop10.vue'
  import RaceTimetable from '@/components/RaceTimetable.vue'
  import RaceHistory from '@/components/RaceHistory.vue'
  import Weather from '@/components/Weather.vue'
  import { useStartlistStore } from '@/store/startlist'
  import { useConfigStore } from '@/store/config'
  import { useUpdateStore } from '@/store/update'
  import { useTimetableStore } from '@/store/timetable'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'raceinfo',

    setup() {
      const startlist = useStartlistStore()
      const config = useConfigStore()
      const update = useUpdateStore()
      const timetable = useTimetableStore()
      const pages = usePagesStore()

      return {
        startlist,
        config,
        update,
        timetable,
        pages
      }
    },

    created() {
      this.bestRiders()
    },

    methods: {
      getRiderOldest() {

        let return_data = {}

        let data = this.startlist.getStats().age.oldest
        let rider = this.startlist.getStartlist().filter((rider) => rider.dor === data.dor)[0]

        if(typeof rider === 'undefined')
          return return_data

        return_data.nfu = rider.nfu
        return_data.afu = rider.afu
        return_data.pai = rider.pai
        return_data.age = data.age
        return_data.dor = rider.dor

        return return_data
      },

      getRiderYoungest() {

        let return_data = {}

        let data = this.startlist.getStats().age.youngest
        let rider = this.startlist.getStartlist().filter((rider) => rider.dor === data.dor)[0]

        if(typeof rider === 'undefined')
          return return_data

        return_data.nfu = rider.nfu
        return_data.afu = rider.afu
        return_data.pai = rider.pai
        return_data.age = data.age
        return_data.dor = rider.dor

        return return_data
      },

      bestRiders() {
        
        let best = this.startlist.getStats().best
        let startlist = this.startlist.getStartlist()
        let return_data = []

        best.forEach(element => {
          startlist.filter(element2 => {
            if(element2.dor === element) {
              return_data.push(element2)
            }
          })
        })

        return return_data
      },

      /*async clickRider(dorsal) {

        var rider = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.startlist.setSelectedRider(rider[0])
        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('riders')

        if(this.isMobile()) {
          this.pages.setVisiblePopup(true)
        }
      },*/

      async clickRider(dorsal) {
        var rider = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('riders')

        if(this.isMobile()) {          
          if(this.config.getConfig().general.fcintegration === '1') {
            this.setPageVisible("https://firstcycling.com/m2/rider.php?r=" + rider[0].ifc)
            this.pages.setVisiblePopup(false)
          } else {
            this.startlist.setSelectedRider(rider[0])
            this.pages.setVisiblePopup(true)
          }     
        } else {
          this.startlist.setSelectedRider(rider[0])
        }
      },

      setPageVisible(url) {
        this.pages.setPageVisible('custom')
        this.pages.setCustomUrl(url)
        window.scrollTo(0,0)
      },
      
      isMobile() {
        return screen.width < 768
      },
    },

    components: { 
        RaceTop10,
        RaceHistory,
        Weather,
        RaceTimetable,
    },

  }
</script>


<style scoped>

  @media (max-width: 768px) {

    .container_raceinfo {
      margin-top: 40px !important;
      margin-bottom: 10px !important;
      padding-top: 0px !important;
    }
  }

  .container_raceinfo {
    height: calc(100% - 50px);
    width: 100%;
    overflow: auto;
    padding-top: 40px;
  }
    
  .separator {
    display: flex !important;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

  .titulo_linea {
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    color: #888;
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: #fafafa;
  }

  .linea {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px auto 60px;
    padding: 12px 10px;
    font-size: 12px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
    cursor: pointer;
  }

  .linea:hover {
    background: #fafafa;
  }

  .country {
    height: 12px;
    display:flex;
  }

</style>