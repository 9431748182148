<template>
  <div class="container">
    <div style="height:20px"><img class="thumb" :src="team.thu" /></div>
    <div class="nombre"><img v-if="team.pai !== ''" class="country" :src="require(`@/assets/flags/${team.pai}.png`)" /><span style="text-transform: uppercase; font-weight: bold;">{{ team.nom }}</span></div>
    <div class="result">{{ team.tot }}</div>
  </div>
</template>


<script>

  export default {
    name: 'situationgroupinfoteam',

    props: {
        id: Number,
        team: Object
    },

    

  }

</script>



<style scoped>

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 40px auto 50px;
    padding: 12px 10px;
    font-size: 12px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
  }

  .country {
    height: 12px;
    display:flex;
    margin-right: 10px;
  }

  .nombre {
    display: flex;
    align-items: center;
  }

  .thumb {
    height: 20px;
  }

  .result {
    text-align: right;
  }


</style>