<template>
  <div class="container_filter_main">    
    <div class="container_filter">
      <!--<div class="title_filter">Categoria</div>-->
      <div class="group_filter">
        <div 
          v-for="(team, index) in this.startlist.getTeams().sort((a, b) => (a.nom > b.nom) ? 1 : -1)"
          :key="index"
          :team="team.nom"
          class="filter" 
          @click="toggleCategory(team.nom)"
          :class="{ filteractive: this.results.data.filterProperties['cat'].includes(team.nom) }" 
        >{{ team.nom }}</div>
      </div>
    </div>
    <div class="container_filter">
      <!--<div class="title_filter">Genero</div>-->
      <div class="group_filter">
        <div 
          class="filter" 
          @click="toggleGender('M')"
          :class="{ filteractive: this.results.data.filterProperties['gen'].includes('M') }" 
        >Hombres</div>
        <div 
          class="filter" 
          @click="toggleGender('F')"
          :class="{ filteractive: this.results.data.filterProperties['gen'].includes('F') }" 
        >Mujeres</div>
      </div>
    </div>
    <div class="container_filter">
      <div class="group_filter">
        <div
          class="filter"
          @click="toggleIntermediate(classification.tip)"
          v-for="(classification, index) in this.getIntermedios()"
          :class="{ filteractive: this.results.getFilterClassification().includes(classification.tip) }"
          :key="index"
          :classification="classification"
        >
          <div v-if="classification.tip == '-1'">META</div>
          <div v-if="classification.tip != '-1'">{{ classification.dis }}m</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { useResultsStore } from '@/store/results'
  import { useConfigStore } from '@/store/config'

  export default {

    setup() {
      const config = useConfigStore()
      const results = useResultsStore()
      const startlist = useStartlistStore()

      return {
        results,
        startlist,
        config
      }
    },

    data() {
      return {
        selectedGender: null,
        selectedCategory: [],
        selectedIntermediate: null,
        backgroundcolor: this.config.getConfig().general.color1,
        forecolor: this.config.getConfig().general.fore_color
      };
    },

    

    methods: {

      toggleGender(gender) {
        if (this.selectedGender === gender) {
          this.selectedGender = null; // Desmarcar si ya está marcado
          this.setProperty('gen', []);
        } else {
          this.selectedGender = gender; // Marcar el seleccionado
          this.setProperty('gen', [this.selectedGender]);
        }
      },

      toggleCategory_old(category) {
        const index = this.results.data.filterProperties['cat'].indexOf(category);
        if (index === -1) {
          // Si la categoría no está seleccionada, agregarla
          this.results.data.filterProperties['cat'].push(category);
        } else {
          // Si la categoría ya está seleccionada, eliminarla
          this.results.data.filterProperties['cat'].splice(index, 1);
        }
        //this.setProperty('cat', this.selectedCategory);
      },

      toggleCategory(category) {
        if (this.selectedCategory === category) {
          this.selectedCategory = null; // Desmarcar si ya está marcado
          this.setProperty('cat', []);
        } else {
          this.selectedCategory = category; // Marcar el seleccionado
          this.setProperty('cat', this.selectedCategory);
        }
      },      

      toggleIntermediate(intermediate) {
        if (this.selectedIntermediate === intermediate) {
          this.selectedIntermediate = null; // Desmarcar si ya está marcado
          //this.setProperty('cat', []);
        } else {
          this.selectedIntermediate = intermediate; // Marcar el seleccionado
          this.results.setFilterClassification(intermediate)
          //this.setProperty('cat', this.selectedCategory);
        }
      },

      setProperty(type, value) {
        this.results.data.filterProperties[type] = value
      },

      getIntermedios() {
        let categoriaSeleccionada = this.results.data.filterProperties['cat']
        return this.config.getConfig().classifications.filter((item) => item.cat == categoriaSeleccionada)
      }
    }

  }
</script>

<style scoped>

  .container_filter_main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    background: #fafafa;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 10px;
  }

  .container_filter {
    display: flex;
    gap: 10px;
    flex-direction: column;
    border-bottom: 1px solid #dfdfdf; 
    padding-bottom: 10px;
  }

  .container_filter:last-child {
    border-bottom: none; 
    padding-bottom: 0px;
  }

  .group_filter {
    display: flex;
    width: 100%;
    gap: 5px;
    overflow: auto;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .group_filter:last-child {    
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .title_filter {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    width: 20%;
  }

  
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    padding: 5px;
    background: #fff;
    border: 1px solid #dbdbdb;
    cursor:pointer;
    transition: 0.1s;
    border-radius: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .filteractive {
    background-color: #000;
    color: #fff;
  }
  
</style>