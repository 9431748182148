<template>
  <div class="titulo">{{ this.raceStatus() }}</div>
  <div class="contenedor_maillots">
    <SummaryMaillotsItem
      v-for="(dorsal, index) in this.getData()"
      :key="index"
      :clasificaciones="dorsal"
      :tienenThumb="this.tienenThumb()"
    ></SummaryMaillotsItem>
  </div>
</template>

<script>
  import { useUpdateStore } from '@/store/update'  
  import { useResultsStore } from '@/store/results'
  import { useStartlistStore } from '@/store/startlist'
  import SummaryMaillotsItem from '@/components/SummaryMaillotsItem'

  export default {
    name: 'summarymaillots',

    setup() {
      const update = useUpdateStore()
      const results = useResultsStore()
      const startlist = useStartlistStore()

      return {
        update,
        results,
        startlist
      }      
    },

    components: {
      SummaryMaillotsItem
    },

    methods: {

      getData() {
        var maillots

        if(this.startedRace()) {
          if(this.hayResultadosFinales())
            maillots = this.update.getResultsRidersFinal()
          else
            maillots = this.update.getResultsRidersVirtual()
        }
        else
          maillots = this.update.getResultsRidersStart()

        const maillots2 = Object.entries(maillots)
          .map(([dorsal, clas]) => ({ dorsal, clas: clas }))
          .sort((a, b) => a.clas[0] - b.clas[0]);

        return maillots2
      },

      tienenThumb() {
        var maillots = this.getData()

        for(let i=0; i<maillots.length; i++){
          let dorsal = maillots[i].dorsal

          if(this.datosRiderFiltrado(dorsal))
            return true
        }

        return false
      },

      datosRiderFiltrado(dorsal) {
        var datosRider = this.datosRider(dorsal)

        if(typeof datosRider.data.sum.thu !== 'undefined' && datosRider.data.sum.thu !== "")
          return true
        else
          return false
      },

      datosRider(dorsal) {
        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })
        
        return filtradoPorTipo[0]
      },

      raceStatus() {
        if(this.startedRace()) {
          if(this.hayResultadosFinales())
            return this.$t("final_classification")
          else
            return this.$t("virtual_classification")
        }
        else
          return this.$t("start_classification")
      },

      startedRace() {
        return this.update.getUpdate().situation[0].km > 0
      }, 

      hayResultadosFinales() {

        var filtradoPorTipo0 = this.update.getResultsFinal().filter(function(item) {
          if(item.tip == 0)
            return item
        })

        var filtradoPorTipo1 = this.update.getResultsFinal().filter(function(item) {
          if(item.tip == 1)
            return item
        })
        
        if((typeof filtradoPorTipo0[0] !== "undefined" && filtradoPorTipo0[0].riders.length > 0) || (typeof filtradoPorTipo1[0] !== "undefined" && filtradoPorTipo1[0].riders.length > 0))
          return true
        else
          return false

      },

    }

  }
</script>

<style scoped>

  @media (max-width: 768px) {
    .contenedor_maillots {
      justify-content: center !important;
      padding: 0 10px !important;
      background: #fff !important;
      border: 0 !important;
      width: calc(100% - 20px) !important;
      margin-top: 10px !important;
    }

    .titulo {
      display: none !important;
    }
  }

  .contenedor_maillots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 13px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  }

  .separator {
    display: flex !important;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

  .titulo {
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    color: #888;
  }

</style>