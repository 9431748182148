/**
 * Mocking client-server processing
 */

 import axios from 'axios'
 
 export default {
 
   getUpdate(datos, callback) {
 
     //let url = 'https://tracker.helmuga.cloud/livetracker_update.php?idcarrera=' + datos
     let url = 'https://tracker.helmuga.cloud/resources/livetracker/' + datos + '/update.json?ts=' + Date.now()
 
     axios.get(url)
         .then(response => { 
           callback(response.data)
         })
   }

 }
 