<template>  
  <div class="container">

    <Transition name="column">
      <div class="column" v-if="this.tv.data.tvShowColumn">
        <ResultsLastTop10 v-if="this.tv.data.tvPanelType === 'top10'" />
        <ResultsHistory v-if="this.tv.data.tvPanelType === 'history'" />
        <Rider v-if="this.tv.data.tvPanelType === 'rider'" />
        <Startlist v-if="this.tv.data.tvPanelType === 'startlist'" />
        <Team v-if="this.tv.data.tvPanelType === 'team'" />
        <SituationNotStarted v-if="0" />
        <Map v-if="this.tv.data.tvPanelType === 'map'" />
      </div>
    </Transition>
    
    <Transition name="column">
      <div class="column_hidden" v-if="this.tv.data.tvPanelType === 'situation'">        
        <Situation v-if="this.tv.data.tvPanelType === 'situation'" />
        
        
        <ProfileClimb 
          :km_inicio="89"
          :km_final="106.7"
        ></ProfileClimb>
        
      </div>
    </Transition>

    <FreeText ref="freetext" />
    
    
  </div>
</template>

<script>
  
  import Pusher from 'pusher-js'
  import { useTvStore } from '@/store/tv'
  import { useConfigStore } from '@/store/config'
  import { useStartlistStore } from '@/store/startlist'
  import Situation from '@/components/tv/Situation.vue'  
  import SituationNotStarted from '@/components/SituationNotStarted.vue'  
  import Map from '@/components/Map.vue'  
  import ResultsLastTop10 from '@/components/tv/ResultsLastTop10.vue'  
  import ResultsHistory from '@/components/tv/ResultsHistory.vue'  
  import Rider from '@/components/tv/Rider.vue'  
  import Startlist from '@/components/tv/Startlist.vue'  
  import Team from '@/components/tv/Team.vue'  
  import FreeText from '@/components/tv/FreeText.vue'  
  import ProfileClimb from '@/components/ProfileClimb.vue'  

  export default {
    name: 'tv-layoutmain',

    setup() {
      const config = useConfigStore()
      const tv = useTvStore()
      const startlist = useStartlistStore()

      return {
        config,
        tv,
        startlist
      }
    },

    created() {
      this.subscribe()
      this.setSelectedRider()
      this.initGetUpdate()
    },

    methods: {
      subscribe () {
        let pusher = new Pusher('05fa2ae2b557f051fa8d', { cluster: 'eu' })
        let raceid = this.config.getConfig().general.raceid
        let self = this
        
        pusher.subscribe('my-channel')
        pusher.bind(raceid, data => {
          self.tratarComando(data)
        })
      },

      initGetUpdate() {

        const self = this
        let raceid = this.config.getConfig().general.raceid
        
        this.tv.updateUpdate(raceid)

        this.intervalid1 = setInterval(function(){

          self.tv.updateUpdate(raceid)

        }, 5000);
        
      },

      tratarComando(data) {

        switch(data.class) {
          case "perfil":
            this.tv.data.tvPanelType = 'situation'
            this.tv.data.selectedSituationGroup = ''
          break

          case "texto_libre":
            this.$refs.freetext.showPanel(JSON.parse(data.elemento)[0].titulo)
          break

          case "info_team":
            this.tv.data.tvShowColumn = true
            var team = data.dispositivo
            this.tv.data.selectedTeam = this.startlist.getTeams().filter((elemento) => elemento.nom === team)[0]
            this.tv.data.tvPanelType = 'team'
          break

          case "tipo_subtitulo":
            this.tv.data.selectedSituationData = data.elemento
          break

          case "info_grupo_corredores":
            this.tv.data.selectedSituationGroup = data.elemento
          break;

          case "info_riders_full":            
            this.tv.data.tvShowColumn = true
            this.tv.data.tvPanelType = 'startlist'
          break

          case "info_km":
            this.tv.data.tvShowColumn = true
            this.tv.data.tvPanelType = 'rider'
          break

          case "ocultar_paneles":
            this.tv.data.tvShowColumn = false
            this.$refs.freetext.hidePanel()
          break
        }

      },

      setSelectedRider() {

        let dorsal = 51

        var filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.startlist.setSelectedRider(filtradoPorTipo[0])
      }

    },

    components: {
      SituationNotStarted,
      Map,
      ResultsLastTop10,
      ResultsHistory,
      Rider,
      Startlist,
      Team,
      Situation,
      FreeText,
      ProfileClimb
    },
  }

</script>

<style scoped>

  .column-enter-active, .column-leave-active {
    transition: all 0.5s;
  }

  .column-enter-from, .column-leave-to {
    /*opacity: 0;*/
    transform: translateX(-500px);
  }

  .container {
		position:absolute;
    top: 0;
    left: 0;
		width:1920px;
		height:1080px;
		background-color:#3eff3f;
    /*background: black;*/
	}
	
  .column {
		position:absolute;
    top: 0;
    left: 0;
		width: calc(500px - 140px);
		height: calc(1080px - 90px);
    padding:45px;
		background-color:#000;
    /*border-right: 3px solid white;*/
	}
	
  .column_hidden {
		position:absolute;
    top: 0;
    left: 0;
    padding-top:45px;
	}

  .rider {
    color: white;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
  }

</style>