<template>
  <div class="container_intermediatemain">
    <IntermediateMainItem
      v-for="(intermediate, index) in this.update.getResultsIntermediate()"
      :key="index"
      :intermediate="intermediate"
      :showTitulo="'1'"
      class="point"
    ></IntermediateMainItem>
  </div>
</template>


<script>
  import { useUpdateStore } from '@/store/update'
  import IntermediateMainItem from '@/components/IntermediateMainItem.vue'

  export default ({
    setup() {
      const update = useUpdateStore()

      return {
        update
      }
    },

    mounted() {
      this.updateIframeHeight()
    },

    updated() {
      this.updateIframeHeight()
    },

    methods: {     
      
      updateIframeHeight() {
        let message = { element: 'helmuga-intermediate', height: parseInt(document.getElementsByClassName('container_intermediatemain')[0].scrollHeight + 20), width: document.getElementsByClassName('container_intermediatemain')[0].scrollWidth };	
        window.top.postMessage(message, "*");
      },

    },

    components: {
      IntermediateMainItem
    },
  })
</script>

<style scoped>
  
  .point {
    border: 1px solid #dbdbdb;
    background: #fafafa;
    margin-bottom: 10px;
  }

  .point:last-child {
    margin-bottom: 0px;
  }

</style>