<template>
    <div class="container_riders">
      
      <teams-item
          v-for="(team, index) in this.startlist.getTeams().sort((a, b) => (a.nom > b.nom) ? 1 : -1)"
          :key="index"
          :team="team"
      ></teams-item>

    </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import TeamsItem from '@/components/TeamsItem.vue'

  export default {
    name: 'teams',

    setup() {
      const startlist = useStartlistStore()

      return {
        startlist
      }
    },

    components: {
      TeamsItem 
    },
  }
</script>

<style scoped>

.startlistrider {
  border-bottom: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  transition: 0.3s;
}

.startlistrider:hover {
  background: white;
}

.startlistrider:last-child {
  border-bottom: 1px solid #dbdbdb;
}


.startlistrider:first-child {
  border-top: 1px solid #dbdbdb;
}



</style>
