/**
 * Mocking client-server processing
 */

 import axios from 'axios'
 
 export default {
 
   getConfig(datos, callback) {
 
    let url = 'https://tracker.helmuga.cloud/resources/livetracker/config/' + datos.carrera + '_' + datos.anyo + '_' + datos.etapa + ".json?ts=" + Date.now()
 
    axios.get(url)
    .then(response => { 
      callback(response.data)
    })
   },

   getConfigURL(dominio, callback) {
 
    let url = 'https://tracker.helmuga.cloud/resources/livetracker/configurl/' + dominio + '.json?ts=' + Date.now()

    axios.get(url)
    .then(response => { 
      callback(response.data)
    })
  }

 }
 