<template>
  <div>
    <div v-if="!['M', 'F'].includes(riderData.dnf)">
      <div v-if="riderData.dnf == ''" class="container" @click="clickRider" >
        <div class="bib">{{ riderData.dor }}</div>
        <div v-if="riderData.data.sum !== undefined && riderData.data && riderData.data.sum.thu != ''" class="thumb"><img :src="riderData.data.sum.thu"></div>
        <div v-if="riderData.data.sum === undefined || !riderData.data || riderData.data.sum.thu == ''" class="icon" v-html="iconData('user')"></div>
        <div class="container_name">
          <div class="container_name_data">
            <span class="country" v-if="riderData.pai"><img :src="require(`@/assets/flags/${riderData.pai}.png`)" /></span>
            <div class="name">{{ riderData.nfu }} <span class="surname">{{ riderData.afu }}</span><br>
            <span class="team">
              <span class="thumb_team" v-if="this.getTeam(riderData).thu"><img :src="this.getTeam(riderData).thu"></span>
              <span>{{ riderData.neq }}</span>
            </span>
            </div>
          </div>
          <div class="maillots" v-html="maillotsRiders(rider.dor)"></div>
        </div>
      </div>
      
      <div v-if="riderData.dnf" class="container_dnf" @click="clickRider" >
        <div class="bib_dnf">{{ riderData.dor }}</div>
        <div v-if="riderData.data.sum !== undefined && riderData.data && riderData.data.sum.thu != ''" class="thumb_dnf"><img :src="riderData.data.sum.thu"></div>
        <div v-if="riderData.data.sum === undefined || !riderData.data || riderData.data.sum.thu == ''" class="icon" v-html="iconData('user')"></div>
        <div class="container_name">
          <div class="container_name_data">
            <span class="country_dnf" v-if="riderData.pai"><img :src="require(`@/assets/flags/${riderData.pai}.png`)" /></span>
            <div class="name_dnf">{{ riderData.nfu }} <span class="surname">{{ riderData.afu }}</span><br>
            <span class="team_dnf">
              <span class="thumb_team_dnf" v-if="this.getTeam(riderData).thu"><img :src="this.getTeam(riderData).thu"></span>
              <span>{{ riderData.neq }}</span>
            </span>
            </div>
          </div>
          <div class="dnf">{{ riderData.dnf }}</div>
        </div>
      </div>
    </div>

    <div v-if="['M', 'F'].includes(riderData.dnf)">
      <div class="container" @click="clickRider">
        <div class="bib">{{ riderData.dor }}</div>
        <div v-if="riderData.data.sum !== undefined && riderData.data && riderData.data.sum.thu != ''" class="thumb"><img :src="riderData.data.sum.thu"></div>
        <div v-if="riderData.data.sum === undefined || !riderData.data || riderData.data.sum.thu == ''" class="icon" v-html="iconData('user')"></div>
        <div class="container_name">
          <div class="container_name_data">
            <span class="country" v-if="riderData.pai"><img :src="require(`@/assets/flags/${riderData.pai}.png`)" /></span>
            <div class="name">{{ riderData.nfu }} <span class="surname">{{ riderData.afu }}</span><br>
            <span class="team">
              <span class="thumb_team" v-if="this.getTeam(riderData).thu"><img :src="this.getTeam(riderData).thu"></span>
              <span>{{ riderData.neq }}</span>
            </span>
            </div>
          </div>
          <div class="dnf">{{ riderData.eco }}</div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  import { useStartlistStore } from '@/store/startlist'
  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'start-list-rider',

    props: {
        id: Number,
        rider: Object
    },

    setup() {
      const startlist = useStartlistStore()
      const update = useUpdateStore()
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        startlist,
        update,
        config,
        pages
      }
    },

    computed: {
      riderData: function() {
        var datosRider = this.rider

        var filtradoPorTipo = null
        filtradoPorTipo = this.update.getDNF().filter(function(rider) {
          return rider.dor == datosRider.dor
        })

        if(typeof filtradoPorTipo[0] === 'undefined') 
          datosRider.dnf = ''
        else
        {
          datosRider.dnf = filtradoPorTipo[0].dnf.toUpperCase()
        }
        
        if(datosRider.pai == '' && typeof datosRider.data.sum?.pai !== 'undefined')
          datosRider.pai = datosRider.data.sum.pai
        

        return datosRider
      },
    },

    methods: {

      async clickRider() {
        if(this.rider.data.length == 0)
          this.startlist.setSelectedRider(this.basicRider())
        else
          this.startlist.setSelectedRider(this.rider)

        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('riders')

        if(this.isMobile()) {          
          if(this.config.getConfig().general.fcintegration === '1') {
            this.setPageVisible("https://firstcycling.com/rider.php?r=" + this.rider.ifc)
            this.pages.setVisiblePopup(false)
          }
          else {
            this.startlist.setSelectedRider(this.rider)
            this.pages.setVisiblePopup(true)
          }     
        } else {
          this.startlist.setSelectedRider(this.rider)
        }
      },

      setPageVisible(url) {
        this.pages.setPageVisible('custom')
        this.pages.setCustomUrl(url)
        window.scrollTo(0,0)
      },

      basicRider() {
        var rider_new = {}
        var data_new = {}

        var sum = new Object()
        sum.nom = ""
        sum.thu = ""
        sum.pai = ""
        sum.fec = ""
        var tea = []
        var res = []

        data_new.sum = sum
        data_new.tea = tea
        data_new.res = res

        rider_new = this.rider
        rider_new.data = data_new

        return rider_new
      },

      maillotsRiders(dorsal) {  
        
        var html = ""
        var maillots

        if(this.startedRace())
          maillots = this.update.getResultsRidersVirtual()[dorsal]
        else
          maillots = this.update.getResultsRidersStart()[dorsal]

          
        if(typeof maillots !== "undefined") {
          for(var i=0;i<maillots.length;i++) {
            var thumb = this.getClassificationThumb(maillots[i])
            html += thumb
          }
        }
        
        return html
      },

      getClassificationThumb(idtipo) {
        var classification_idiomas = this.config.getClassifications().filter(function(item){
            return item.tip == idtipo
        });

        if(typeof classification_idiomas[0] !== "undefined") {
          return "<img style='height:20px;' src='" + classification_idiomas[0].thumb + "'>"
        } else {
          return ""
        }
        
      },

      startedRace() {
        return this.update.getUpdate().situation[0].km > 0
      },        
      
      getTeam(rider) {
        //if(typeof code[0].thu === 'undefined')
        //  return ""

        if(rider.eco != "")
          return this.startlist.getTeams().filter((team) => team.uci == rider.eco)
        else
          return this.startlist.getTeams().filter((team) => team.nom == rider.neq)
      },

      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {
          case "user":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "startlist":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "star":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2 2.3607 7.26543h7.6393l-6.1803 4.49027 2.3606 7.2654-6.1803-4.4902-6.18034 4.4902 2.36068-7.2654-6.18034-4.49027h7.63932z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          default:
            break;
        }
        
      },

      isMobile() {
        return screen.width < 768
      },


    }

}
</script>


<style lang="css" scoped>

  @media (max-width: 768px) {
    .country, .country_dnf {
      display: none !important;
    }

    .container, .container_dnf {
      grid-template-columns: 50px 50px auto !important;
    } 

    .thumb_team, .thumb_team_dnf {
      display: none !important;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 50px 50px auto;
    align-items: center;
    padding: 12px 0px;
    font-size: 14px;
    width: 100%;
    margin-right: 5px;
    background: #FAFAFA;
    cursor: pointer;
  }
  
  .container_dnf {
    display: grid;
    grid-template-columns: 50px 50px auto;
    align-items: center;
    padding: 12px 0px;
    font-size: 14px;
    width: 100%;
    margin-right: 5px;
    background: #FAFAFA;
    cursor: pointer;
  }


  .dnf {
    color: #DDD;
    text-align: center;
  }
  
  .lineborder {    
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #dbdbdb;
    cursor: pointer;
  }

  .bib {
    text-align: center;
    padding: 0 10px;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bold;
    color:#777;
  }

  .bib_dnf {
    text-align: center;
    padding: 0 10px;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bold;
    color:#BBB;
  }
  
  .thumb {
    padding: 0 10px;    
    display: flex;
    justify-content: center;
  }

  .thumb img {
    height:35px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }  
  
  .thumb_dnf {
    padding: 0 10px;    
    display: flex;
    justify-content: center;
  }

  .thumb_dnf img {
    height:35px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .thumb_team {
    margin-right: 3px;
    display: flex;
  }

  .thumb_team img{
    height: 13px;
  }

  .thumb_team_dnf {
    margin-right: 3px;
    display: flex;
  }

  .thumb_team_dnf img{
    height: 13px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .country{
    height:13px;
    margin: 0 10px;
    border: 1px solid #eee;
  }

  .country img {
    height:13px;
  }

  .country_dnf{
    height:13px;
    padding: 0 10px;
  }

  .country_dnf img {
    height:13px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .container_name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container_name_data {
    display: flex;
    align-items: center;
  }

  .name {
    text-align: left;
    padding: 0 10px;
    font-size: 14px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -ms-user-select: none; 
    -moz-user-select: none; 
    -o-user-select:none;
    user-select:none;
  }

  .name_dnf {
    text-align: left;
    padding: 0 10px;
    font-size: 14px;
    color: #BBB;
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -ms-user-select: none; 
    -moz-user-select: none; 
    -o-user-select:none;
    user-select:none;
  }

  .surname {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .team {
    text-align: left;
    color: #777;
    font-size: 11px;
    display:flex;    
    align-items: center;
    margin-top: 5px;
  }

  .team_dnf {
    text-align: left;
    color: #BBB;
    font-size: 11px;
    display:flex;    
    align-items: center;
    margin-top: 5px;
  }

  .maillots {
    /*height: 20px;*/
    margin-right: 10px;
    text-align: right;
  }

  .maillots img {
    height: 20px;
  }

  .dnf {
    margin-right: 10px;
    background-color: #BBB;
    padding: 0 5px;
    font-size: 12px;
    color: white;
  }

  .icon {
    text-align: center;
  }

  .bike {
    display: flex;
    height: 8px;
  }

  .bike img {
    margin-left: 5px;
    height: 8px;
  }

</style>