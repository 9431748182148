<template>
  <div class="row">
    <span class="position" v-html="positionFormat(results.pos)"></span>
    <span class="name">{{ results.nom }} {{ results.fec.substr(0, 4) }}<span 
        v-if="results.eta"
        class="stage">, S{{ results.eta }}
      </span>
    </span>
    <span class="category">{{ results.cat }}</span>
  </div>
</template>

<script>

  export default {
      name: 'startlist-rider-info-results',

      props: {
        id: Number,
        results: Object
      },

      methods: {
        positionFormat(position) {
          
          switch(position){
            case "1":
              return `<span class='position_text' style='background: gold;padding: 0 5px;border-radius: 5px;color:white;'>${position}</span>`

            case "2":
              return `<span class='position_text' style='background: silver;padding: 0 5px;border-radius: 5px;color:white;'>${position}</span>`

            case "3":
              return `<span class='position_text' style='background: #CD7F32;padding: 0 5px;border-radius: 5px;color:white;'>${position}</span>`
            
            default:
              return `<span class='position_text'>${position}</span>`
          }


        }
      }

  }
</script>

<style scoped>

  .row {    
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.2fr;
    padding: 5px 0;
    border-bottom: 1px solid #dbdbdb;
    font-size: 12px;
  }

  .position {
    text-align: center;
  }

  .category {
    text-align: center;
    color: #888;
  }
  
  
</style>