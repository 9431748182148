import { defineStore } from 'pinia'
import configAPI from "@/api/config"


export const useConfigStore = defineStore({
  id: "configStore",

    state: () => ({
      data: {
        general: {
          city_finish: "",
          city_start: "",
          date_start: "",
          distance: "",
          nameplain: "",
          race: "",
          raceid: "",
          stage: "",
          year: "",
          languages: "",
          color1: "",
          color2: "",
          fore_color: "",
          font: "",
          pages: [],
          sponsor: {
            img: "",
            url: ""
          },
          starttimes: [],
          stages: [],
          genere: ""
        },
        classifications: [],
        stats: {}
      }
    }),

    actions: {

      getConfig() {
        return this.data
      },

      setConfig(datos) {
        this.data = datos
      },

      setPages(datos) {
        this.data.general.pages = datos
      },

      getClassifications() {
        return this.data.classifications
      },

      fetchConfig(datos) {
        return new Promise((resolve, reject) => {            
          configAPI.getConfig(datos, resolve)
        })
      },

      fetchConfigURL(dominio) {
        return new Promise((resolve, reject) => {            
          configAPI.getConfigURL(dominio, resolve)
        })
      },

      getGenere() {
        return this.data.general.genere
      },

      updateConfig(datos) {         
        this.fetchConfig(datos)
        .then((data) => {
          this.setConfig(data)
        })        
      },
  }
});
