<template>
    <div class="container_commentary">
      <div v-if="showLess && isMobile() && renderComponent">
        <CommentaryItem
          v-for="(item, index) in getCommentaries().slice(0, 10)"      
          :key="index"
          :id="index"
          :item="item">
        </CommentaryItem>
        <div v-if="getCommentaries().length > 10" @click="showLess = false" class="show">{{ $t('show_more') }}</div>
      </div>

      <div v-if="(!showLess || !isMobile()) && renderComponent">
        <CommentaryItem
          v-for="(item, index) in getCommentaries()"      
          :key="index"
          :id="index"
          :item="item">
        </CommentaryItem>
        <div v-if="isMobile() && !showLess" @click="showLess = true" class="show">{{ $t('show_less') }}</div>
      </div>
    </div>
</template>


<script>
  
  import { useUpdateStore } from '@/store/update'
  import CommentaryItem from '@/components/CommentaryItem'

  export default {
    name: 'commentary',

    setup() {
      const update = useUpdateStore()

      return { update }
    },

    components: {
      CommentaryItem,            
    },

    data () {
      return { 
        showLess: true,
        renderComponent: true
      }
    },

    mounted() {
      /*const plugin = document.createElement("script")
      plugin.setAttribute(
        "src",
        "https://platform.twitter.com/widgets.js"
      );
      plugin.async = true
      document.head.appendChild(plugin)*/

    },

    methods: {
      isMobile() {
        return screen.width < 768
      },

      getCommentaries() {
        return this.update.data.commentary.filter(item => item.tex && item.tex[this.$i18n.locale])
      },

      refresh() {
        window.twttr.widgets.load()

        var nuevo_comentario = this.update.getUpdate().commentary[0]
        var ultimo_comentario = this.update.getLastCommentaryId()

        /*if(this.hayAudios() && nuevo_comentario['idm'] != ultimo_comentario) {
          this.forzarRenderComentarios()          
          this.update.setLastCommentaryId(nuevo_comentario['idm'])
        }*/
      },

      addCommentaryItem(item) {
        const newCommentaryItem = this.$createElement(CommentaryItem, {
          props: {
            item: item
          }
        })

        const container = this.$el.querySelector('.container_commentary')
        container.appendChild(newCommentaryItem.$el)
      },
      
      forzarRenderComentarios() {
        this.renderComponent = false

        this.$nextTick(() => {
          this.renderComponent = true
        });
      },

      hayAudios() {
        var comentarios = this.update.getUpdate().commentary

        return comentarios.some(function(arrVal) {
            return "aud" === arrVal.tip
        })
      }
    }

  }
</script>

<style scoped>

  @media (max-width: 768px) {
    
  }

  .container_commentary {
    display:flex;
    flex-direction: column;
  }

  .show {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    text-transform: uppercase;
    font-size: 12px;
    justify-content: center;
    background: #fafafa;
    color: #999;
    border-bottom: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    cursor: pointer;
    transition: 0.3s;
  }

  .show:hover {
    background: #FFF;
  }
  

</style>