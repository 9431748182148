<template>  
  <div>
    <RaceTimetableItem
      v-for="(final, index) in this.timetable.getTimetable().data.filter((item) => item.idh != '')"
      :key="index"
      :final="final"
    ></RaceTimetableItem>
  </div>
</template>

<script>

  import { useTimetableStore } from '@/store/timetable'
  import RaceTimetableItem from '@/components/RaceTimetableItem.vue'

  export default {
    name: 'racetimetable',

    setup() {
      const timetable = useTimetableStore()

      return {
        timetable
      }
    },

    components: { 
        RaceTimetableItem,
    },

  }
</script>