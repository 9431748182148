/**
 * Mocking client-server processing
 */

 import axios from 'axios'

 export default {
 
   getTimetable (datos, callback) {
  
    //let url = 'https://tracker.helmuga.cloud/timetable_bbdd.php?idcarrera=' + datos
    let url = 'https://tracker.helmuga.cloud/resources/livetracker/' + datos + '/timetable.json?ts=' + Date.now()
        
    axios.get(url)
    .then(response => { 
      callback(response.data)
    })
 
   }
 }
 