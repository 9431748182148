import { createApp } from 'vue'
import App from './App.vue'

import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import VueApexCharts from "vue3-apexcharts"
import VueWaveSurfer from 'vue-wave-surfer'
import Embed from 'v-video-embed'
import VueAnalytics from 'vue3-analytics'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueSocialSharing from 'vue-social-sharing'

import { initializeApp } from "firebase/app";
import { initializeAnalytics, getAnalytics } from "firebase/analytics";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, far, fab);


function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const $body = document.querySelector('body');
let scrollPosition = 0;

export default {
  enable() {
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
  },
  disable() {
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }
}

//TWITTER 
window.twttr = (function (d,s,id) {
  var t, js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return; js=d.createElement(s); js.id=id;
  js.src="https://platform.twitter.com/widgets.js"; fjs.parentNode.insertBefore(js, fjs);
  return window.twttr || (t = { _e: [], ready: function(f){ t._e.push(f) } });
  }(document, "script", "twitter-wjs"));


const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  legacy: false,
  globalInjection: true,
})

const firebaseConfig = {
  apiKey: "AIzaSyC3gNjwRxT7PH88J7Nb_Cc9rAfJXpFk3dU",
  authDomain: "live-tracker-analytics.firebaseapp.com",
  projectId: "live-tracker-analytics",
  storageBucket: "live-tracker-analytics.appspot.com",
  messagingSenderId: "76144625736",
  appId: "1:76144625736:web:62277b6ef492294c25707d",
  measurementId: "G-88MK8K46CK"
};

// Initialize Firebase
//const appfirebase = initializeApp(firebaseConfig)
//const analytics = getAnalytics(appfirebase)
//const analytics = initializeAnalytics(appfirebase, {config: { cookie_flags: "max-age=7200;secure;samesite=none" }})


const myapp = createApp(App)
myapp.use(createPinia())
myapp.use(i18n)
myapp.use(VueApexCharts)
myapp.use(VueWaveSurfer)
myapp.use(Embed)
myapp.use(VueSocialSharing)
myapp.component(VueCountdown.name, VueCountdown)
myapp.component('font-awesome-icon', FontAwesomeIcon);


/*myapp.use(VueGtag, {
  config: { 
    id: 'G-FVEVLR4FV4',
    //id: 'UA-165440911-1'
  }
})*/

/*myapp.use(VueAnalytics, {
  id: 'UA-165440911-1',
  params: {
    //send_page_view: false,
    cookie_flags: 'max-age=7200;secure;samesite=none'
  }
})*/

myapp.mount('#app')


