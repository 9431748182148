import { defineStore } from 'pinia'
import tvAPI from "@/api/tv"


export const useTvStore = defineStore({
  id: "tvStore",

    state: () => ({
      data: {
        tvPanelType: '',
        tvShowColumn: false,
        selectedTeam: {},
        selectedSituationGroup: '',
        selectedSituationData: 'riders',
        update: {}
    }
  }),

  actions: {

    setUpdate(data) {
      this.data.update_data = data
    },

    getUpdate() {
      return this.data.update_data
    },

    fetchUpdate(raceid) {
      return new Promise((resolve, reject) => {            
        tvAPI.getUpdate(raceid, resolve)
      })
    },

    updateUpdate(raceid) {         
      this.fetchUpdate(raceid)
      .then((data) => {
        this.setUpdate(data)
      })        
    }

  }
});
