<template>
  <div>
    <div class="titulo">
      <div class="km" v-if="item.km > 0"><span class="icon" v-html="iconData('km')" style="margin-right: 3px;"></span>{{ item.km }}km</div>
      <div class="fecha">{{ formattedDate(item.hor) }}</div>
    </div>
    <div class="texto" v-html="getTextoVideo()"></div>
    <YoutubeVue3 
      ref="youtube" 
      :videoid="getYoutubeId(getURLVideo())"
      width="100%" 
      height="320"  
      />
  </div>
</template>

<script>
  import moment from 'moment'
  import { useConfigStore } from '@/store/config'
  import { YoutubeVue3 } from 'youtube-vue3'
  
  export default {
    name: 'commentary-audio',
    
    setup() {
      const config = useConfigStore()

      return {
        config,
      }
    },

    props: {
      item: Object
    },

    components: {
      YoutubeVue3
    },

    methods: {

      getURLVideo() {
        var commentary = this.item.tex[this.$i18n.locale]

        let div = document.createElement('div');
        div.innerHTML = commentary;
        var audioURL = div.getElementsByClassName('video_commentary')

        return audioURL[0].innerHTML
      },
      
      getTextoVideo() {

        var commentary = this.item.tex[this.$i18n.locale]

        let div = document.createElement('div');
        div.innerHTML = commentary;
        var audioURL = div.getElementsByClassName('text_commentary')

        return audioURL[0].innerHTML
      },

      getYoutubeId(url) {
        var video_id = url.split('v=')[1]
        var ampersandPosition = video_id.indexOf('&')
        if(ampersandPosition != -1) {
          video_id = video_id.substring(0, ampersandPosition)
        }
        return video_id
      },

      formattedDate(timestamp) {
        return moment(parseInt(timestamp)).format('HH:mm\\h')
      },

      iconData(name) {
        switch (name) {
          case "km":
            return '<svg fill="none" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#BBB" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m6.0325 15.2873 5.9676 6.7127 5.9676-6.7127c4.577-5.1485.9217-13.2873-5.9676-13.2873-6.88931 0-10.54461 8.1388-5.9676 13.2873z"/><circle cx="12" cy="10" r="3"/></g></svg>'
          
          case "fecha":
            return '<svg fill="none" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#BBB" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6v6l4 4"/></g></svg>'
          
          default:
            break;
        }
      },
    }
  }
  

</script>

<style scoped>
  @media (max-width: 768px) {
    .fecha {
      padding-right: 10px !important;
    }

    .km {
      padding-left: 10px !important;
    }

    .container_wave {
      padding: 0 10px !important;
    }

    .texto {
      padding: 0 10px !important;
      width: calc(100% - 20px) !important;
    }

  }

  .container_wave {
    display: flex;
  }

  .titulo {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .km {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #AAA;
  }

  .fecha {
    color: #CCC;
    font-size: 12px;
    margin-left: auto
  }

  .texto {
    text-align: left;
    font-size: 14px;
    width: 100%;
    margin-bottom: 5px;
  }

  .button_container {
    display: flex;
    justify-content: flex-start;
    width: 60px;
    align-items: center;
  }

  .wave_container {
    width: calc(100% - 60px);
  }

  .button {
    cursor: pointer;
  }
  
  .icon {
    height: 12px;
  }

  .wave {
    width: 100%;
  }

</style>
