<template>
  <div class="container" :class="{ noriderinfo: final.nom !== '' }">
    <div class="position">{{ indice + 1 }}</div>
    <template v-if="final.nom">
      <div class="bib noriderinfo">{{ final.dor }}</div>
      <div class="container_name">
        <span class="name">{{ final.nom }}</span>
      </div>
    </template>
    <template v-else>
      <div class="container_thumb">
        <img v-if="thumbnailSrc" class="thumb" :src="thumbnailSrc">
        <div v-else class="icon" v-html="iconData('user')"></div>
      </div>
      <div class="bib">{{ bibNumber }}</div>
      <div class="container_name">
        <img v-if="flagSrc" class="country" :src="flagSrc" />
        <div class="name"><span v-html="displayName"></span><br><span class="team">{{ teamName }}</span></div>
      </div>
    </template>
    <div v-if="this.config.getConfig().general.type != 'laps'" class="result">
      {{ getResult() }} 
      <span v-if="this.config.getConfig().general.type == 'popular'"><br><span class="ritmo">{{ getRitmo() }} min/km</span></span>
    </div>
    <div v-if="this.config.getConfig().general.type == 'laps'" class="result">
      {{ getResultLaps() }} 
      <span v-if="this.firsttime.lap == this.final.lap" class="laps"><br>{{ final.lap }}
      <span class="icon_laps"><font-awesome-icon :icon="['fast', 'rotate-left']" size="" :style="{ color: '#aaa' }" style="font-size:12px;color: #aaa;" /></span></span>
    </div>
  </div>
</template>

<script>
import { useStartlistStore } from '@/store/startlist'
import { usePagesStore } from '@/store/pages'
import { useConfigStore } from '@/store/config'
import { useResultsStore } from '@/store/results'

export default {
  name: 'resultsfull-livetiming',
  props: {
    key: Number,
    indice: Number,
    firsttime: Object,
    final: Object,
  },
  setup() {
    const startlist = useStartlistStore()
    const pages = usePagesStore()
    const config = useConfigStore()
    const results = useResultsStore()

    return {
      startlist,
      pages,
      config,
      results
    }
  },

  computed: {
    datosRiderFiltrado() {
      if (this.config.getConfig().general.type === 'ttt') {
        return this.datosTeam(this.final.dor) || {}
      } else {
        return this.datosRider(this.final.dor) || {}
      }
    },

    thumbnailSrc() {
      return this.datosRiderFiltrado.data?.sum?.thu || this.datosRiderFiltrado.thu || ''
    },

    flagSrc() {
      const pai = this.datosRiderFiltrado.pai || this.datosRiderFiltrado.data?.sum?.pai || ''
      return pai ? require(`@/assets/flags/${pai}.png`) : ''
    },

    displayName() {
      if (this.config.getConfig().general.type === 'ttt') {
        return `<span class="surname_results">${this.datosRiderFiltrado.nom}</span>`
      } else {
        return `${this.datosRiderFiltrado.nfu} <span class="surname_results">${this.datosRiderFiltrado.afu}</span>`
      }
    },

    teamName() {
      return this.datosRiderFiltrado.neq || ''
    },

    bibNumber() {
      return (this.config.getConfig().general.type === 'ttt') ? this.datosRiderFiltrado.uci : this.final.dor
    },
  },
  
  methods: {
    
    datosRider(dorsal) {
      return this.startlist.riders.find(item => item.dor === dorsal) || {}
    },

    datosTeam(dorsal) {
      return this.startlist.teams.find(item => item.num == dorsal) || {}
    },

    getResultLaps() {
      if(typeof this.final.tim === 'undefined')
        return this.final.res

      if(this.config.getConfig().general.type == "popular")
        return this.aMinSegundos(this.final.tim)

      if(this.indice == 0)
        return this.aMinSegundos(this.final.tim)
      else {
        if(this.firsttime.lap == this.final.lap)
          return '+ ' + this.aMinSegundos(this.final.tim - this.firsttime.tim)
        else
          return '+ ' + parseInt(this.firsttime.lap - this.final.lap) + ' ' + this.$t('laps')
      }
    },

    getResult() {
      if(typeof this.final.tim === 'undefined')
        return this.final.res

      if(this.config.getConfig().general.type == "popular")
        return this.aMinSegundos(this.final.tim)

      if(this.indice == 0)
        return this.aMinSegundos(this.final.tim)
      else
        return '+ ' + this.aMinSegundos(this.final.tim - this.firsttime.tim)
    },
    
    getResult_old() {
      const res = this.final.res
      return res.startsWith('**') ? res.replace('**', '') + " " + this.$t('laps') : res
    },
    
    iconData(panel) {
      const icons = {
        user: '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>',
        startlist: '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>',
        star: '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2 2.3607 7.26543h7.6393l-6.1803 4.49027 2.3606 7.2654-6.1803-4.4902-6.18034 4.4902 2.36068-7.2654-6.18034-4.49027h7.63932z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>',
      }
      return icons[panel] || ''
    },

    aMinSegundos(milisegundos) {
      const value = milisegundos / 1000;
      const sec = parseInt(value, 10); 
      let hours   = Math.floor(sec / 3600); 
      let minutes = Math.floor((sec - (hours * 3600)) / 60);
      let seconds = sec - (hours * 3600) - (minutes * 60); 

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}

      if(hours > 0)
        return hours + ":" + minutes + ':' + seconds;
      else
        return minutes + ':' + seconds;
    },

    getRitmo() {

      let classifications = this.config.getConfig().classifications
      let tip = this.results.getFilterClassification()
      let cat = this.results.data.filterProperties['cat'] 
      let filteredClassifications = classifications.filter(item => item.tip === tip && item.cat === cat)

      if(filteredClassifications.length > 0) {
        let ritmo = (this.final.tim / 1000 / 60) / (filteredClassifications[0].dis / 1000)
        return this.aMinSegundos(ritmo * 60000)
      }
      else
        return ""
    }
  },
}
</script>

<style scoped>
@media (max-width: 900px) {
  .container {
    grid-template-columns: 50px 50px auto 50px !important;
  }
  .container.noriderinfo {
    grid-template-columns: 40px 40px auto 50px !important;
  }
  .country, .bib {
    display: none !important;
  }
}

.container {
  display: grid;
  align-items: center;
  grid-template-columns: 50px 50px 50px auto 100px;
  padding: 12px 10px;
  font-size: 14px;
  width: calc(100% - 22px);
  border-right: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  background: white;
}

.container:first-child {
  border-top: 1px solid #dbdbdb;
}

.noriderinfo {
  grid-template-columns: 50px 100px auto 100px;
}

.position {
  text-align: center;
}

.bib {
  text-align: center;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: bold;
  color: #777;
}

.container_thumb {
  text-align: center;
}

.thumb {
  height: 35px;
  margin: 0 10px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
}

.country {
  height: 13px;
  padding: 0 10px;
}

.container_name {
  display: flex;
  align-items: center;
}

.name {
  text-align: left;
  padding: 0 10px;
}

.surname {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.team {
  text-align: right;
  color: #777;
  font-size: 11px;
}

.icon_laps {
  margin-left: 3px;
}

.icon {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result {
  text-align: right;
}

.ritmo {
  font-size: 10px;  
  color: #888;
}

.laps {
  font-size: 12px;  
  color: #888;
}
</style>

<style>

  .surname_results {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

</style>