<template>  
  <div class="container">
    <div class="thumb"><img :src="final.data.sum.thu"></div>
    <div class="flag"><img class="country" :src="require(`@/assets/flags/${final.pai}.png`)" /></div>
    <div class="fullname">{{ final.dor }} {{ final.afu }}</div>
  </div>
</template>

<script>


  export default {
    name: 'tv-list2item',

    props: {
      key: Number,
      final: Object,
    },

  }
</script>

<style scoped>

  .container {
    display: flex;
    font-size: 18px;
    width: 180px;
    color: white;
    background: black;
    height: 200px;
    flex-direction: column;
    align-items: center;
  }

  .country {
    height: 15px;
    display:flex;
  }

  .thumb img {
    height: 125px;
    margin-bottom: 10px;
  }

  .flag {
    height:15px;
    margin-bottom: 10px;
  }

  .result {
    text-align: right;
  }

  .fullname {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
  }

  .surname {
    font-weight: bold;
  }


</style>