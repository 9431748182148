<template>
  <div class="container">
    <div class="container_options">
      <span 
        class="option"
        @click="setLanguage(lang)"
        v-for="(lang, i) in this.config.getConfig().general.languages" 
        :key="`Lang${i}`" 
        :value="lang">
        <img :src="urlFlagImage(lang)">
      </span>
    </div>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'

  export default {
    name: 'languages-selector',

    setup() {
      const config = useConfigStore()

      return {
        config
      }
    },

    methods: {
      setLanguage(lang) {

        if(lang == "va")
          this.$i18n.locale = "ca"
        else
          this.$i18n.locale = lang
      },

      urlFlagImage(pais) {
        return "https://tracker.helmuga.cloud/images/flags/" + pais + ".png"
      }
    }

  }
</script>

<style scoped>

  .container {
    display:flex;
    align-items: center;
    margin-right: 10px;
  }

  .container_options {
    display: flex;
  }

  .option {
    text-transform: uppercase;
    margin: 0 5px;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: bold;
    color: #888;
    font-size: 12px;
  }

  .option:hover {
    color: black;
  }

  .option img {
    height: 20px;
  }

</style>