<template>
  <div class="container" v-if="datosRiderFiltrado && this.config.getConfig().general.type == 'itt'">
    <div class="position">{{ formattedDate(parseInt(final.tim)) }}</div>
    <div class="container_thumb">
      <img v-if="datosRiderFiltrado.data.sum !== undefined && datosRiderFiltrado.data && datosRiderFiltrado.data.sum.thu != ''" class="thumb" :src="datosRiderFiltrado.data.sum.thu">
      <div v-if="datosRiderFiltrado.data.sum === undefined || !datosRiderFiltrado.data || datosRiderFiltrado.data.sum.thu == ''" class="icon" v-html="iconData('user')"></div>
    </div>    
    <div class="bib">{{ datosRiderFiltrado.dor }}</div>
    <div class="container_name">
      <img class="country" v-if="datosRiderFiltrado.pai" :src="require(`@/assets/flags/${datosRiderFiltrado.pai}.png`)" />
      <div class="name">{{ datosRiderFiltrado.nfu }} <span class="surname">{{ datosRiderFiltrado.afu }}</span><br>
      <span class="team">{{ datosRiderFiltrado.neq }}</span></div>
    </div>
  </div>

  <div class="container" v-if="datosRiderFiltrado && this.config.getConfig().general.type == 'ttt'">
    <div class="position">{{ formattedDate(parseInt(final.tim)) }}</div>
    <div class="container_thumb">
      <img v-if="datosRiderFiltrado.thu != ''" class="thumb" :src="datosRiderFiltrado.thu">
      <div v-if="datosRiderFiltrado.thu == ''" class="icon" v-html="iconData('user')"></div>
    </div>    
    <div class="bib">{{ datosRiderFiltrado.uci }}</div>
    <div class="container_name">
      <img class="country" v-if="datosRiderFiltrado.pai" :src="require(`@/assets/flags/${datosRiderFiltrado.pai}.png`)" />
      <div class="name"><span class="surname">{{ datosRiderFiltrado.nom }}</span></div>
    </div>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { useConfigStore } from '@/store/config'
  import moment from 'moment'

  export default {
    name: 'resultsfull-item',

    props: {
      key: Number,
      final: Object,
    },

    setup() {
      const startlist = useStartlistStore()
      const config = useConfigStore()

      return {
        startlist,
        config
      }
    },

    computed: {      
      datosRiderFiltrado() {
        var datosRider

        if(this.config.getConfig().general.type == 'itt') {
          datosRider = this.datosRider(this.final.dor)

          if(datosRider.pai == '' && typeof datosRider.data.sum.pai !== 'undefined')
            datosRider.pai = datosRider.data.sum.pai
        }
        else {
          datosRider = this.datosTeam(this.final.dor)
        }

        return datosRider
      }
    },

    methods: {

      datosRider(dorsal) {

        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        if(typeof filtradoPorTipo[0] !== "undefined")
          return filtradoPorTipo[0]
        else
          return ""

      },      

      datosTeam(dorsal) {

        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.teams.filter(function(item) {
          return item.num == dorsal
        })

        if(typeof filtradoPorTipo[0] !== "undefined")
          return filtradoPorTipo[0]
        else
          return ""

      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },

      formattedDate(timestamp) {
        return moment(parseInt(timestamp)).format('HH:mm');
      },      

      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {
          case "user":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "startlist":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "star":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2 2.3607 7.26543h7.6393l-6.1803 4.49027 2.3606 7.2654-6.1803-4.4902-6.18034 4.4902 2.36068-7.2654-6.18034-4.49027h7.63932z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          default:
            break;
        }
        
      }

    }


  }

</script>

<style scoped>

  @media (max-width: 900px) {

    .container {
      grid-template-columns: 50px 50px auto !important;
    }

    .country {
      display: none !important;
    }

    .bib {
      display: none !important;
    }

  }

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 50px 50px auto;
    padding: 12px 10px;
    font-size: 14px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;    
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
  }

  .position {
    text-align: center;
    letter-spacing: 1px;
    font-size: 12px;
  }

  .position_variation {
    text-align: center;
    font-size: 10px;
  }

  .bib {
    text-align: center;
    padding: 0 10px;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bold;
    color:#777;
  }

  .container_thumb {
    text-align: center;
  }
  
  .thumb {
    height:35px;
    margin: 0 10px;    
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .country{
    height:13px;
    margin: 0 10px;
    border: 1px solid #eee;
  }

  .container_name {
    display: flex;
    align-items: center;
  }

  .name {
    text-align: left;
    padding: 0 10px;
  }

  .surname {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .icon {
    text-align: center;
  }

  .team {
    text-align: right;
    color: #777;
    font-size: 11px;
  }

  .result {
    text-align: center;
  }
</style>