<template>
  <div class="container_menuitem" @click="classificationSelected(classification.tip)">
    <div class="text">{{ classification.cat }}</div>
  </div>
</template>

<script>
  import { useResultsStore } from '@/store/results'

  export default {

    name: 'resultsmenu-item',

    props: {
      id: Number,
      classification: Object
    },

    setup() {
      const results = useResultsStore()

      return {
        results
      }
    },

    methods: {
      classificationSelected(tipo) {
        this.results.setFilterClassification(tipo)
      },      

      imageIntermedio(num) {
        return "https://tracker.helmuga.cloud/images/time_" + num + ".png";
      },

      getNombreClasificacion() {
        if(typeof this.classification.langs[this.$i18n.locale] !== 'undefined')
          return this.classification.langs[this.$i18n.locale]
        else
          return this.$t('ranking_' + this.classification.tip)
      },
    }

  }

</script>

<style scoped>

  .container_menuitem {
    display: flex;
    align-items: center;
    background: #fafafa;
    border: 1px solid #dbdbdb;
    flex-basis: 150px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    cursor: pointer;    
    justify-content: center;
    transition: 0.3s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -ms-user-select: none; 
    -moz-user-select: none; 
    -o-user-select:none;
    user-select:none;
    border-radius: 5px;
  }

  .container_menuitem:hover {
    background: white;
  }

  .thumb {
    height: 30px;
    margin-left: 10px;
  }

  .thumb_icon {
    height: 20px;
    margin-left: 10px;
  }

  .text {
    margin: 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
  }

</style>
