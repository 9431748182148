<template>
  <div>
    <div class="container">
      <div class="separator_winner" v-if="this.config.getConfig().general.stage == 'NA'">{{ $t('winner' + this.config.getGenere()) }}</div>
      <div class="separator_winner" v-if="this.config.getConfig().general.stage != 'NA'">{{ $t('winner_stage' + this.config.getGenere()) }}</div>
      <div v-if="this.getWinner()" @click="clickRider(this.getWinner().dor)">
        <div class="thumb" v-if="typeof this.getWinner().data.sum !== 'undefined' && this.getWinner().data.sum.thu"><img :src="this.getWinner().data.sum.thu"></div>
        <div class="name">{{ this.getWinner().nfu }}</div>
        <div class="surname">{{ this.getWinner().afu }}</div>
        <div class="team">{{ this.getWinner().neq }}</div>
      </div>
      <div v-if="this.getWinner() == ''" @click="clickRider(this.getWinnerSinStartlist().dor)">
        <div class="name">#{{ this.getWinnerSinStartlist().dor }}</div>
        <div class="surname">{{ this.getWinnerSinStartlist().nom }}</div>
      </div>
      <div v-if="this.showSummaryMaillots()">
        <div class="separator">{{ $t('leaders') }}</div>
        <SummaryMaillots />
      </div>
    </div>
  </div>
</template>

<script>

  import { useUpdateStore } from '@/store/update'
  import { useStartlistStore } from '@/store/startlist'
  import { useConfigStore } from '@/store/config'
  import SummaryMaillots from '@/components/SummaryMaillots'
  import { usePagesStore } from '@/store/pages'

  export default ({
    setup() {
      const update = useUpdateStore()
      const startlist = useStartlistStore()
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        update,
        startlist,
        config,
        pages
      }
    },
    
    components: {
      SummaryMaillots
    },

    methods: {
      
      getWinner() {

        var filtradoPorTipo = null
        filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
          return item.tip == "0"
        })
        var etapa = filtradoPorTipo[0]
        return this.datosRider(etapa.riders[0].dor)
      },

      getWinnerSinStartlist() {
        return this.update.getResultsFinal()[0].riders[0]
      },

      datosRider(dorsal) {

        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        if(typeof filtradoPorTipo[0] !== "undefined")
          return filtradoPorTipo[0]
        else
          return ""

      },
      
      showSummaryMaillots() {
        if(typeof this.update.getResultsRidersFinal().length === 'undefined')
          return true
        else
          return false

      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },
      
      /*clickRider(dorsal) {
        var rider = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.startlist.setSelectedRider(rider[0])
        this.pages.setPopupType('riders')

        if(this.isMobile()) {
          this.pages.setVisiblePopup(true)
        }
      },*/

      async clickRider(dorsal) {
        var rider = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('riders')

        if(this.isMobile()) {          
          if(this.config.getConfig().general.fcintegration === '1')
            this.setPageVisible("https://firstcycling.com/m2/rider.php?r=" + rider[0].ifc)
          else {
            this.startlist.setSelectedRider(rider[0])
            this.pages.setVisiblePopup(true)
          }     
        } else {
          this.startlist.setSelectedRider(rider[0])
        }
      },

      async setPageVisible(url) {
        this.pages.setCustomUrl('about:blank')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPageVisible('custom')
        this.pages.setCustomUrl(url)
        window.scrollTo(0,0)
      },

      isMobile() {
        return screen.width < 768
      },

    }
  })

</script>

<style scoped>

  @media (max-width: 768px) {
    
    .separator {
      display: flex !important;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .thumb {
    text-align: center;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .thumb img {
    height: 100px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .name {
    text-align: center;
  }

  .surname {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 5px;
  }

  .team {
    color: #777;
    font-size: 11px;
    text-align: center;
  }

  .title {    
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .separator {
    display: none;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

  .separator_winner {
    display: flex;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

</style>