import { defineStore } from 'pinia'
import timetableAPI from "@/api/timetable"


export const useTimetableStore = defineStore({
  id: "timetableStore",
    state: () => ({
      data: {
        data: {}
      }
    }),

    actions: {

      getTimetable() {
        return this.data
      },

      setTimetable(datos) {
        this.data = datos
      },

      fetchTimetable(datos) {
        return new Promise((resolve, reject) => {            
          timetableAPI.getTimetable(datos, resolve)
        })
      },

      updateTimetable(datos) {         
        this.fetchTimetable(datos)
        .then((data) => {
          this.setTimetable(data)
        })        
      }
  }
});
