<template>
  <div class="container">
    <div style="height:12px"><img class="country" :src="require(`@/assets/flags/${country.cou}.png`)" /></div>
    <div><span style="text-transform: uppercase; font-weight: bold;">{{ nombrePais(country.cou.toUpperCase()) }}</span></div>
    <div class="result">{{ country.tot }}</div>
  </div>
</template>


<script>

  export default {
    name: 'situationgroupinfocountry',

    props: {
        id: Number,
        country: Object
    },

    methods: {
      nombrePais(codigo) {

        if(codigo == "CC")
          codigo = "CA"

        let regionNames = new Intl.DisplayNames([this.$i18n.locale], {type: 'region'})
        return regionNames.of(codigo)
      },
    }

  }

</script>



<style scoped>

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 40px auto 50px;
    padding: 12px 10px;
    font-size: 12px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
  }

  .country {
    height: 12px;
    display:flex;
  }

  .result {
    text-align: right;
  }


</style>