/**
 * Mocking client-server processing
 */

 import axios from 'axios'

 export default {
 
   getStartlist (datos, callback) {
 
    let carrera = datos.carrera
    let anyo = datos.anyo

    //let url = 'https://tracker.helmuga.cloud/startlist.php?carrera=' + carrera + '&anyo=' + anyo
    let url = 'https://tracker.helmuga.cloud/resources/livetracker/' + carrera + '-' + anyo + '/startlist.json?ts=' + Date.now()
        
    axios.get(url)
    .then(response => { 
      callback(response.data)
    })
 
   }
 }
 