<template>
  <div class="layout_commentary">
    <Commentary class="layout_commentary_component"/>
  </div>
</template>

<script>
import { useUpdateStore } from '@/store/update'
import Commentary from '@/components/Commentary.vue'

export default {
  name: 'LayoutCommentary',

  data() {
    return {
    };
  },

  setup() {

    const update = useUpdateStore()

    return {
      update,
    }
  },

  components: {
    Commentary,
  },

}

</script>

<style scoped>

  .layout_commentary {
    overflow: auto;
    width: calc(100% - 20px);
    display: flex;
    justify-content: center;
  }

  .layout_commentary_component {
    padding-left: 10px;
    width: calc(100% - 20px);
    max-width: 800px;
  }

  

  @media (max-width: 768px) {

    .layout_commentary {
      padding: 0px;
    }

    .layout_commentary_component {
      padding-left: 0px;
      width: 100%;
    }

  }


</style>