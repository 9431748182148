<template>
  <div class="container_team">
    <div class="title">{{ this.tv.data.selectedTeam.nom }}</div>
    <RaceInfo type="simple" fullracename="1" />
    <List4 ref="list4" />
  </div>

</template>

<script>
  
  import { useTvStore } from '@/store/tv'
  import List4 from '@/components/tv/List4.vue'
  import RaceInfo from '@/components/tv/RaceInfo.vue'  

  export default {
    name: 'tv-startlist',

    setup() {
      const tv = useTvStore()

      return {
        tv
      }
    },

    mounted() {
      this.$refs.list4.init()
    },

    components: { 
      List4,
      RaceInfo
    },

  }
</script>

<style scoped>

  .title {
    font-size: 30px;
    color: white;
    font-weight: bold;
    height: 35px;
    text-transform: uppercase;
    width: 360px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

</style>