<template>
  <div class="map_container">
    <div id="mapid"></div>
  </div>
</template>

<script>
  import { onMounted, ref } from "vue";
  var LabeledMarker = require('leaflet-labeled-circle');
  import leaflet from "leaflet";
  import 'leaflet/dist/leaflet.css';
  import { useMapStore } from '@/store/map'
  import { useUpdateStore } from '@/store/update'
  import { useTimetableStore } from '@/store/timetable'
  import { useStartlistStore } from '@/store/startlist'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'
  import "leaflet-velocity"

  export default {
    
    name: "timetablepointmap",

    props: {
      km_inicio: Number,
      km_final: Number,
      data: Object,
    },

    setup(props) {

      const map = useMapStore()
      const update = useUpdateStore()
      const timetable = useTimetableStore()
      const startlist = useStartlistStore()
      const config = useConfigStore()
      const pages = usePagesStore()
      
      let mymap
      let marker = []

      onMounted(() => {
        

        if(screen.width < 768) {
          mymap = leaflet.map("mapid", {
            dragging: false,
            tap: false
          })
        } else {
          mymap = leaflet.map("mapid", {
            dragging: true,
            tap: true
          })
        }

        mymap.on('load', onMapLoad)

        leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles by <a href="http://mapc.org">MAPC</a>, Data by <a href="http://mass.gov/mgis">MassGIS</a>',
          tileOptions: {crossOriginKeyword: null}
        }).addTo(mymap)

        let geojsonLayer = leaflet.geoJson(mapData(), {style: {
					weight: 8,
					color: config.data.general.fore_color
				}}).addTo(mymap)
        
        leaflet.geoJson(mapData(), {style: {
          weight: 3,
          color: config.data.general.color1
        }}).addTo(mymap)

        var posicion, latlng, icon
        
        switch(props.data.hit) {

          case "3":
            latlng = leaflet.latLng(mapData().coordinates[0][1], mapData().coordinates[0][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/start.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            posicion = mapData().coordinates.length - 1

            latlng = leaflet.latLng(mapData().coordinates[posicion][1], mapData().coordinates[posicion][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_' + props.data.hit + '_' + props.data.cat.toLowerCase() + '.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)
          break

          case "start":
            latlng = leaflet.latLng(mapData().coordinates[0][1], mapData().coordinates[0][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/start.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            latlng = leaflet.latLng(mapData().coordinates[100][1], mapData().coordinates[100][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_1km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            latlng = leaflet.latLng(mapData().coordinates[200][1], mapData().coordinates[200][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_2km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            latlng = leaflet.latLng(mapData().coordinates[299][1], mapData().coordinates[299][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_3km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

          break

          case "finish":
            posicion = mapData().coordinates.length - 100

            latlng = leaflet.latLng(mapData().coordinates[posicion][1], mapData().coordinates[posicion][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_lastkm.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            posicion = mapData().coordinates.length - 200

            latlng = leaflet.latLng(mapData().coordinates[posicion][1], mapData().coordinates[posicion][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_2km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            latlng = leaflet.latLng(mapData().coordinates[0][1], mapData().coordinates[0][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_3km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            latlng = leaflet.latLng(props.data.lat, props.data.lon)
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/finish.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)
          break

          default:
            posicion = mapData().coordinates.length - 100

            latlng = leaflet.latLng(mapData().coordinates[posicion][1], mapData().coordinates[posicion][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_1km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            posicion = mapData().coordinates.length - 200

            latlng = leaflet.latLng(mapData().coordinates[posicion][1], mapData().coordinates[posicion][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_2km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            latlng = leaflet.latLng(mapData().coordinates[0][1], mapData().coordinates[0][0])
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_3km.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)

            latlng = leaflet.latLng(props.data.lat, props.data.lon)
            icon = leaflet.icon({
              iconUrl: 'https://tracker.helmuga.cloud/images/profile_' + props.data.hit + '_' + props.data.cat.toLowerCase() + '.png',
              iconSize: [18, 18]
            })

            leaflet.marker(latlng, {icon: icon}).addTo(mymap)
          break

        }

        mymap.fitBounds(geojsonLayer.getBounds())

      });

      const mapData = function() {
        var datos = Object.assign({}, map.getMap()[0]);
        datos.coordinates = datos.coordinates.slice(props.km_inicio * 100, props.km_final * 100)
        
        return datos

      }

      const removeMarkers = function() {

        marker.forEach(function(item) {
          mymap.removeLayer(item)
          item.remove()
        })
        
      }

      const addMarker = function(idgrupo, texto, coordinates) {

        var idgrupo_texto = idgrupo

        if(idgrupo == 0)
          idgrupo_texto = "P"

        if(marker[idgrupo])
        {
          marker[idgrupo].remove()
        }

        var feature = {
          "type": "Feature",
          "properties": {
            "text": idgrupo_texto,
            "labelPosition": coordinates.slice().reverse()
          },
          "geometry": {
            "type": "Point",
            "coordinates": coordinates.slice().reverse()
          }
        };

        marker[idgrupo] = new LabeledMarker(
          feature.geometry.coordinates.slice().reverse(),
          feature, {
            markerOptions: markerStyle()
          }).addTo(mymap);

        marker[idgrupo].bindTooltip(texto, tooltipStyle())

        marker[idgrupo].on('click', leaflet.bind(onClickMarker, null, "situation-group-" + idgrupo))
      }

      const onClickMarker = function(idhorario) {
        abrirPopup(idhorario)
      }

      const onMapLoad = function() {
        pages.setLoading(false)
      }

      const markerStyle = function() {
        return {
          "radius": 9,
          "fillOpacity": 1,
          "fillColor": "#0087d2",
          "color": "#000000",
          "weight": 1,
        }
      }

      const tooltipStyle = function() {
        return {
          "direction": "top",
          "opacity": 1,
          "offset": [0, -10]
        }
      }
      
      const routeColor = function() {
        return config.getConfig().general.color1
      }

      const abrirPopup = function(idhorario) {
        pages.data.selectedTimetablePoint = idhorario
        pages.setPopupType('timetablepointinfo')

        if(isMobile())
          pages.setVisiblePopup(true)
      }

      const isMobile = function() {
        return screen.width < 768
      }

      return {
        map,
        update,
        timetable,
        startlist,
        addMarker,
        removeMarkers,
        config,
        pages
      }
      
    },

    methods: {
    }

  }
</script>

<style scoped>

  .map_container, #mapid {
    width:100%;
    height: 250px;
  }

</style>