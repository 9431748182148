<template>  
  <div>
    <RaceTop10Item
      v-for="(final, index) in this.config.data.stats.lasttopten"
      :key="index"
      :final="final"
    ></RaceTop10Item>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'
  import RaceTop10Item from '@/components/RaceTop10Item.vue'

  export default {
    name: 'racetop10',

    setup() {
      const config = useConfigStore()

      return {
        config
      }
    },

    components: { 
        RaceTop10Item,
    },

  }
</script>