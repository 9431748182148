<template>
  <div class="loading">
    <iframe 
      id='ytplayer' 
      type='text/html' 
      width='100%' 
      height='100%' 
      style="background-color: #FFFFFF"
      :src="urlYoutube()"
      frameborder='0' />
  </div>
</template>

<script>


  export default {
    name: 'situation-group',

    props: {
      idYoutube: String
    },

    methods: {
      urlYoutube() {
        return "https://www.youtube.com/embed/" + this.idYoutube + "?autoplay=1"
      }
    }


  }

</script>

<style scoped>

  .loading {
    background-color: #aaa;
  }

</style>
