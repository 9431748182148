<template>
  <div class="container_situationmain" v-if="this.config.getConfig().general.type == 'road'">
    <BannerApp v-if="this.pages.getPageVisible() === 'situation'" />
    <SituationNotStarted v-if="update.getGeneral().started > 0 && Date.now() < update.getGeneral().started"></SituationNotStarted>
    <Situation v-if="update.getGeneral().started > 0 && Date.now() > update.getGeneral().started && !this.finishStageData()"></Situation>
    <SituationFinished v-if="update.getGeneral().started > 0 && Date.now() > update.getGeneral().started && this.finishStageData()"></SituationFinished>
    <SituationCancelled v-if="update.getGeneral().started < 0"></SituationCancelled>
  </div>

  <div class="container_situationmain" v-if="this.config.getConfig().general.type == 'itt' || this.config.getConfig().general.type == 'ttt'">
    <BannerApp v-if="this.pages.getPageVisible() === 'situation'" />
    <div class="container_itt">
      <div v-if="this.getIntermediate().length < 4">
        <ResultsFullLiveTiming
          :idclasificacion="-9999"
        />
      </div>
      <ResultsFullLiveTiming
        v-for="(elemento, index) in this.getIntermediate()"
        :key="index"
        :idclasificacion="parseInt(elemento.tip)"
      />
    </div>
  </div>

  <div class="container_situationmain" v-if="this.config.getConfig().general.type == 'laps'">
    <ResultsFullLiveTimingLaps
      :idclasificacion="this.pages.getCategory() * -1"
    />  
  </div>
</template>

<script>

  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'
  import { useResultsStore } from '@/store/results'
  import { usePagesStore } from '@/store/pages'
  import Situation from '@/components/Situation.vue'
  import SituationNotStarted from '@/components/SituationNotStarted.vue'  
  import SituationFinished from '@/components/SituationFinished.vue'  
  import SituationCancelled from '@/components/SituationCancelled.vue'  
  import ResultsFullLiveTiming from '@/components/ResultsFullLiveTiming'
  import ResultsFullLiveTimingLaps from '@/components/ResultsFullLiveTimingLaps'
  import BannerApp from '@/components/BannerApp'

  export default {
    name: 'situation-main',

    setup() {
      const update = useUpdateStore()
      const config = useConfigStore()
      const results = useResultsStore()
      const pages = usePagesStore()

      return {
        update,
        config,
        results,
        pages
      }
    },

    mounted() {
      this.updateIframeHeight()
    },

    updated() {
      this.updateIframeHeight()
    },

    methods: {     
      
      updateIframeHeight() {
        let message = { element: 'helmuga-situation', height: parseInt(document.getElementsByClassName('container_situationmain')[0].scrollHeight + 20), width: document.getElementsByClassName('container_situationmain')[0].scrollWidth };	
        window.top.postMessage(message, "*");
      },

      finishStageData() {

        var filtradoPorTipo = null

        filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
          return item.tip == 0
        })

        if(filtradoPorTipo.length > 0 && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false

      },

      getIntermediate() {
        return this.config.getClassifications().filter((item) => item.tip < 0 && item.tip > -9999)
      }

    },

    components: {
      Situation,
      SituationNotStarted,
      SituationFinished,
      SituationCancelled,
      ResultsFullLiveTiming,
      ResultsFullLiveTimingLaps,
      BannerApp
      
    }

  }


</script>


<style scoped>


  @media (max-width: 400px) {
    .container_itt {
      flex-direction: column !important;
    }
  }

  .container_itt {
    display: flex;
    flex-wrap: wrap;
  }

  .container_itt > *{
    /*flex: 1 0 calc(50% - 10px);*/
    flex-basis: calc(50% - 10px); 
    margin: 5px;
  }

</style>