<template>
  <div class="container_startlistdashboard">
    <div class="container_startlist">
      <div class="container_startlist_menu" v-if="!isMobile() && this.startlist.getStartlist().length > 0"> 
        <div class="container_menuitem" @click="this.clickOption('riders')">
          <div class="icon" v-html="iconData('user')"></div>
          <div class="text" data-v-30d205b0="">{{ $t('riders' +  this.config.getGenere()) }}</div>
        </div>
        <div v-if="this.startlist.stats?.countries" class="container_menuitem" @click="this.clickOption('teams')">
          <div class="icon" v-html="iconData('startlist')"></div>
          <div class="text" data-v-30d205b0="">{{ $t('teams') }}</div>
        </div>
        <div v-if="!this.startlist.stats?.countries" class="container_menuitem" @click="this.clickOption('teams')">
          <div class="icon" v-html="iconData('startlist')"></div>
          <div class="text" data-v-30d205b0="">{{ $t('category') }}</div>
        </div>
        <div v-if="this.startlist.stats?.countries" class="container_menuitem" @click="this.clickOption('ridercountry')">
          <div class="icon" v-html="iconData('flag')"></div>
          <div class="text" data-v-30d205b0="">{{ $t('countries') }}</div>
        </div>
      </div>
      <div class="title" style="border: 1px solid #dbdbdb;justify-content: center;padding: 20px;text-align: center;" v-if="this.startlist.getStartlist().length == 0">
        {{ $t('no_startlist_available') }}
      </div>
      <div class="filter_container" v-if="this.panelVisible == 'riders' && this.startlist.getStartlist().length > 0">
        <div class="icon" style="margin-right: 3px;margin-left:0;height: 18px;" v-html="iconData('filter')"></div>
        <div style="width:100%"><input type="text" class="filter" v-model="filterValue" :placeholder="$t('filter')"></div>
      </div>
      <div class="container_izda">
        <Startlist :style="setPosition('riders')" class="startlist" :filterValue="this.filterValue"></Startlist>
        <Teams :style="setPosition('teams')" class="startlist"></Teams>
        <RiderCountry v-if="this.panelVisible == 'ridercountry'" class="startlist"></RiderCountry>
      </div>
      <div class="menu_mobile" v-if="isMobile()">
        <div class="container_resultsmenu">
          <div class="container_menuitem" @click="this.panelVisible = 'riders'">
            <div class="icon" style="margin:0" v-html="iconData('user')"></div>
          </div>
          <div class="container_menuitem" @click="this.panelVisible = 'teams'">
            <div class="icon" style="margin:0" v-html="iconData('startlist')"></div>
          </div>
          <div v-if="this.startlist.stats?.countries" class="container_menuitem" @click="this.panelVisible = 'ridercountry'">
            <div class="icon" style="margin:0" v-html="iconData('flag')"></div>
          </div>
        </div>
      </div>
    </div>    
    <div class="rider_info" v-if="!isMobile() && this.startlist.getStartlist().length > 0">
      <StartListRiderInfo v-if="this.pages.getPopupType() == 'riders' && this.config.getConfig().general.fcintegration === '0'" />
      <LayoutIframe  v-if="this.pages.getPopupType() == 'riders' && this.config.getConfig().general.fcintegration === '1'" />
      <TeamsInfo v-if="this.pages.getPopupType() == 'teams'" />
      <RiderCountryInfo v-if="this.pages.getPopupType() == 'ridercountry'" />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import { useStartlistStore } from '@/store/startlist'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'
  import Startlist from '@/components/Startlist.vue'
  import StartListRiderInfo from '@/components/StartListRiderInfo.vue'
  import TeamsInfo from '@/components/TeamsInfo.vue'
  import Teams from '@/components/Teams.vue'
  import RiderCountry from '@/components/RiderCountry.vue'
  import RiderCountryInfo from '@/components/RiderCountryInfo.vue'
  import LayoutIframe from '@/layouts/LayoutIframe.vue'

  export default {
    name: 'LayoutStartlist',

    setup() {
      const startlist = useStartlistStore()
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        startlist,
        config,
        pages
      }
    },

    data() {
      return {
        panelVisible: 'riders',
        filterValue: ''
      }
    },

    components: {
      Startlist,
      Teams,
      StartListRiderInfo,
      TeamsInfo,
      RiderCountry,
      RiderCountryInfo,
      LayoutIframe
      //StartlistRiderInfo: defineAsyncComponent(() => import('@/components/StartListRiderInfo.vue')),      
    },    

    methods: {

      clickOption(option) {
        this.pages.setPopupType(option)
        this.panelVisible = option
      },

      setPosition(page) {
        if(page == this.panelVisible) {
          return {
            "left": "0px"
          }
        }
        else {
          return {
            "left": "-30000px"
          }
        }
      },
      
      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {
          case "startlist":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "user":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>'
                    
          case "flag":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m20 15.0233c-1.1667.6644-4.4 1.6987-8 .104-1.5-.8305-5.2-2.0972-8-.1039m0 6.9092v-18.93604c2.8-1.99328 6.5-.72663 8 .10391 3.6 1.59462 6.8333.56037 8-.10405v11.95968" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'
          
          case "filter":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg"><path d="m2 7h20m-16 5h12m-7 5h2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          default:
            break;
        }
        
      },    
      
      doFilter() {
        console.log()
      },
      
      isMobile() {
        return screen.width < 768
      },

    }

  }
</script>


<style scoped>

  @media (max-width: 768px) {

    .container_startlistdashboard {
      flex-direction: column;
      width: calc(100% - 20px) !important;
      height: calc(100% - 10px) !important;
      padding: 10px 10px 0px 10px !important;
    }

    .container_startlist {
      width: 100% !important;
      height: 100% !important;
    }

    .startlist {
      width: 100% !important;
      height: calc(100% - 50px) !important;
      padding-right: 0px !important;
      margin-right: 10px !important;
      border-bottom: 1px solid #dbdbdb !important;
    }

    .rider_info {
      width: calc(100% - 20px) !important;
      height: calc(100% - 102px) !important;
      position: fixed;
      top: 0;
      left: 0;
      padding-right: 10px !important;
      padding-top: 50px !important;
    }

    .container_resultsmenu {
      justify-content: space-around !important;
      align-items: center !important;
      padding: 0 5px !important;
    }

    .container_menuitem {
      all: unset !important;
      height: 30px !important;
      display: flex !important;
      align-items: center !important;
    }
  }

  .container_startlistdashboard {
    width: 100%;
    display: flex;
    padding: 10px;
  }

  .container_startlist {
    display: flex;
    width: 50%;
    height: calc(100% - 20px);
    flex-direction: column;
  }

  .container_startlist_menu {
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    overflow: auto;
    gap: 10px;
  }

  .menu {
    width: 100%;
    height: 50px;
  }

  .icon {
    margin-left: 10px;
  }

  .startlist {
    height: 100% !important;
    overflow-y: auto;
    padding-right: 10px;
    flex: 1;
    position: absolute;
    left: 0px;
    width: calc(100% - 10px);


    /*width: 50%;
    height: calc(100% - 20px);
    overflow-y: auto;
    padding-right: 10px;
    display: grid;
    grid-template-columns: 50px 50px auto;
    align-items: center;
    grid-auto-rows: 60px;*/
  }

  .rider_info {
    width: 50%;
    height: calc(100% - 30px);
    background: white;
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    background: #fafafa;
    border-top: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }

  .container_menuitem {
    display: flex;
    align-items: center;
    background: #fafafa;
    border: 1px solid #dbdbdb;
    flex-basis: 150px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;    
    justify-content: space-between;
    transition: 0.3s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -ms-user-select: none; 
    -moz-user-select: none; 
    -o-user-select:none;
    user-select:none;
    border-radius: 5px;
  }

  .container_menuitem:hover {
    background: white;
  }

  .container_izda {
    position:relative;
    width: 100%; 
    height:100%;
  }

  .text {
    margin: 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
  }
  
  .menu_mobile {
    height: 50px;
  }

  .container_resultsmenu {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .filter_container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    
  }

  .filter {
    border-bottom: 1px solid #dbdbdb;
    padding: 5px;
    width: calc(100% - 12px);
    display: flex;
    color: #777;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
  

</style>
