<template>
  <div class="container_perfil">
    <div class="perfil_climb"></div>
  </div>
</template>


<script>
  import { useConfigStore } from '@/store/config'
  import { useMapStore } from '@/store/map'
  import { SVG } from '@svgdotjs/svg.js'
  import chroma from "chroma-js"

  export default {
    name: "profileclimb",

    setup() {
      const map = useMapStore()
      const config = useConfigStore()

      return {
        map,
        config
      }
    },

    data() {
      return {
        svgId : "thisID",
        svgContainer: null,
        foreColor: this.config.getConfig().general.fore_color
      }
    },

    props: {
      km_inicio: Number,
      km_final: Number,
    },

    mounted() {
      this.createProfile()
    },

    methods: {

      createProfile() {

        var draw = SVG().addTo('.perfil_climb')

        let km_inicio = this.km_inicio;
        let km_final = this.km_final;

        let coordenadas_local = this.map.getMap()[0].coordinates.slice(km_inicio * 100, km_final * 100 + 1)
        let offset = 1;
        let precision_perfil = 100;
        let max_elevation = 0;
        let color_fondo = []

        for(var i=0; i<coordenadas_local.length; i++) {
          if(max_elevation < coordenadas_local[i][2])
            max_elevation = coordenadas_local[i][2];
        }

        let altura_perfil = 150;
        let anchura_perfil = 350;        
        
        draw.clear();
        draw.height(altura_perfil);
        draw.width(anchura_perfil);
        
        let polyline = "";
        let distancia_barras = 1; // 1 = cada km
        
        for(i=0; i<coordenadas_local.length; i = i + precision_perfil)
        {

          let elevation_grafica = coordenadas_local[i][2] * altura_perfil / max_elevation;
          let km_grafica = parseInt(i) * anchura_perfil / parseInt(coordenadas_local.length);

          let altitud_inicio = coordenadas_local[i][2];
          let altitud_fin

          polyline += parseInt(km_grafica) + "," + parseInt(altura_perfil) + " "; // punto 1
          polyline += parseInt(km_grafica) + "," +  (parseInt(altura_perfil - elevation_grafica) * offset) + " "; // punto 2

          if(typeof coordenadas_local[i + precision_perfil] !== "undefined")
          {
            elevation_grafica = coordenadas_local[i + precision_perfil][2] * altura_perfil / max_elevation;
            km_grafica = parseFloat(i + precision_perfil) * anchura_perfil / parseFloat(coordenadas_local.length);
            altitud_fin = coordenadas_local[i + precision_perfil][2];					

            polyline += parseInt(km_grafica) + "," + (parseInt(altura_perfil - elevation_grafica) * offset) + " "; // punto 3
            polyline += parseInt(km_grafica) + "," + parseInt(altura_perfil) + " "; // punto 4            
          }
          else
          {
            elevation_grafica = coordenadas_local[coordenadas_local.length - 1][2] * altura_perfil / max_elevation;
            km_grafica = parseFloat(i + precision_perfil) * anchura_perfil / parseFloat(coordenadas_local.length);
            altitud_fin = coordenadas_local[coordenadas_local.length - 1][2];					

            polyline += parseInt(km_grafica) + "," +  (parseInt(altura_perfil - elevation_grafica) * offset) + " ";
            polyline += parseInt(km_grafica) + "," +  parseInt(altura_perfil) + " ";
          }

          let color = this.getColorBarra(altitud_inicio, altitud_fin, distancia_barras);

          polyline = draw.polyline(polyline).fill(color);
          polyline = "";

          color_fondo.push(this.obtenerColorTexto(color))

        }

        for(i = 0; i < coordenadas_local.length; i = i + precision_perfil)
        {
          let elevation_grafica = coordenadas_local[i][2] * altura_perfil / max_elevation;
          let km_grafica = parseInt(i) * anchura_perfil / parseInt(coordenadas_local.length);
          let ancho_barra = (anchura_perfil / parseInt(coordenadas_local.length)) * precision_perfil
          let barra = 0

          let altitud_inicio = coordenadas_local[i][2];
          let altitud_fin, line, altura_linea_km, text, ancho_text, x_texto, porcentaje, y

          let kms_totales = coordenadas_local.length / 100;
          let cada_cuantos_kms = 1;
          let distancia_kms = (anchura_perfil / kms_totales);

          if(typeof coordenadas_local[i + precision_perfil] !== "undefined")
          {
            elevation_grafica = coordenadas_local[i + precision_perfil][2] * altura_perfil / max_elevation;
            km_grafica = parseFloat(i + precision_perfil) * anchura_perfil / parseFloat(coordenadas_local.length);
            altitud_fin = coordenadas_local[i + precision_perfil][2];	            
            altura_linea_km = parseInt(altura_perfil - elevation_grafica)
            
            line = draw.line(parseInt(km_grafica), altura_perfil, parseInt(km_grafica), altura_linea_km).stroke({ width: 1 }).animate();
            line.stroke({ color: '#FFF', width: 2})  

            if(kms_totales > 7)
              porcentaje = ((altitud_fin - altitud_inicio) / (cada_cuantos_kms * 10)).toFixed(0);
            else
              porcentaje = ((altitud_fin - altitud_inicio) / (cada_cuantos_kms * 10)).toFixed(1);		
            
            if(color_fondo[barra] == 'dark')
              text = draw.text(porcentaje).addClass('porcentaje')
            else
              text = draw.text(porcentaje).addClass('porcentaje_oscuro')

            ancho_text = text.bbox().width;
            x_texto = ancho_barra * (i / precision_perfil) + ((distancia_kms - ancho_text) / 2)
            y = parseInt(altura_perfil) - 30;
            text.move(x_texto, y)
          }
          else
          {
            elevation_grafica = coordenadas_local[coordenadas_local.length - 1][2] * altura_perfil / max_elevation;
            km_grafica = parseFloat(i + precision_perfil) * anchura_perfil / parseFloat(coordenadas_local.length);
            altitud_fin = coordenadas_local[coordenadas_local.length - 1][2];		

            porcentaje = ((altitud_fin - altitud_inicio) / (cada_cuantos_kms * 10)).toFixed(1);	

             if(color_fondo[barra] == 'dark')
              text = draw.text(porcentaje).addClass('porcentaje')
            else
              text = draw.text(porcentaje).addClass('porcentaje_oscuro')

            let ancho_km_final = anchura_perfil - i;
            ancho_text = text.bbox().width;

            if(ancho_text < ancho_km_final)
            {
               if(color_fondo[barra] == 'dark')
                text = draw.text(porcentaje).addClass('porcentaje')
              else
                text = draw.text(porcentaje).addClass('porcentaje_oscuro')
		
              ancho_text = text.bbox().width;
              x_texto = ancho_barra * (i / precision_perfil) + ((distancia_kms - ancho_text) / 2)
              y = parseInt(altura_perfil) - 30;
              text.move(x_texto, y)
            } else {
              text.clear()
            }
          }

          barra++

        }
      },

      obtenerColorTexto(colorFondo) {
        let r, g, b;

        if (colorFondo.charAt(0) === '#') {
            r = parseInt(colorFondo.slice(1, 3), 16);
            g = parseInt(colorFondo.slice(3, 5), 16);
            b = parseInt(colorFondo.slice(5, 7), 16);
        } else if (colorFondo.startsWith('rgb(')) {
            const rgbValues = colorFondo.slice(4, -1).split(',');
            r = parseInt(rgbValues[0]);
            g = parseInt(rgbValues[1]);
            b = parseInt(rgbValues[2]);
        } else {
            return null;
        }

        const luminosidad = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        if (luminosidad > 0.5) {
            return 'dark';
        } else {
            return 'light'; 
        }
      },


      getColorBarra(altitud_inicio, altitud_fin, distancia_barras) {

        let porcentaje = parseFloat((altitud_fin - altitud_inicio) / (distancia_barras * 10));	
        let colors_array = this.arrayColores(this.config.getConfig().general.color1);

        if(porcentaje < 2)
          return colors_array[0];
        else if(porcentaje < 4)
          return colors_array[1];
        else if(porcentaje < 8)				
          return colors_array[2];
        else if(porcentaje < 12)
          return colors_array[3];
        else if(porcentaje < 16)
          return colors_array[4];
        else
          return colors_array[4];

      },

      arrayColores(color_base) {
        let colors = [];
        let luminancia = chroma(color_base).luminance();

        /*if(luminancia > 0.3)
        {
          for(var i=0;i<5;i++)
            colors.push(chroma(color_base).darken(i).hex());
        }
        else
        {
          colors.push(chroma(color_base).brighten(2).hex());
          colors.push(chroma(color_base).brighten().hex());
          colors.push(chroma(color_base).darken().hex());
          colors.push(chroma(color_base).darken(2).hex());
          colors.push(chroma(color_base).darken(3).hex());
        }*/

        for(var i=0;i<5;i++)
          colors.push(chroma(color_base).darken(i).hex());

        return colors;
      }

    }
  }

</script>

<style>

  .porcentaje {
    font-weight: bold;
    fill: v-bind(foreColor);
  }

  
  .porcentaje_oscuro {
    font-weight: bold;
    fill: #FFF;
  }

</style>

<style scoped>

  .container_perfil {
    display: flex;
    justify-content: center;
  }

</style>