<template>
  <div class="row">
    <span class="year">{{ teams.any }}</span>
    <span class="name">{{ teams.nom }}</span>
  </div>
</template>

<script>

  export default {
      name: 'startlist-rider-info-teams',

      props: {
        id: Number,
        teams: Object
      },

  }
</script>

<style scoped>

  .row {    
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    padding: 5px 0;
    border-bottom: 1px solid #dbdbdb;
    font-size: 12px;
  }

  .year {
    text-align: center;
  }
  
  
</style>