<template>
  <div>
    <ResultsListItem
      v-for="(intermediate, index) in dataIntermediate()"
      :key="index"
      :intermediate="intermediate"
      :showTitulo="'1'"
      class="point"
    ></ResultsListItem>
  </div>
</template>


<script>
  import { useUpdateStore } from '@/store/update'
  import { useResultsStore } from '@/store/results'
  import ResultsListItem from '@/components/ResultsListItem'

  export default ({
    setup() {
      const update = useUpdateStore()
      const results = useResultsStore()

      return {
        update,
        results
      }
    },

    components: {
      ResultsListItem
    },

    methods: {
      dataIntermediate() {
        
        var tipo_global = this.results.getFilterClassification()

        var filtradoPorTipo = this.update.getResultsIntermediate().filter(function(item) {
          return item.tip == tipo_global
        })

        return filtradoPorTipo
      }
    }
  })
</script>

<style scoped>
  
  .point {
    border: 1px solid #dbdbdb;
    background: #fafafa;
    margin-bottom: 10px;
  }

  .point:last-child {
    margin-bottom: 0px;
  }

</style>