import { defineStore } from 'pinia'
import startlistAPI from "@/api/startlist"


export const useStartlistStore = defineStore({
  id: "startlistStore",
    state: () => ({
      riders: [],
      teams: [],
      stats: {},
      selectedRider: {},
      selectedTeam: {},
      selectedCountry: '',
      commentariesVisibleDashboard: true
    }),

    actions: {

      getStartlist() {
        return this.riders
      },

      getTeams() {
        return this.teams
      },

      getStats() {
        return this.stats
      },

      setStartlist(datos) {
        this.riders = datos.riders
        this.teams = datos.teams
        this.stats = datos.stats
        
        if(datos.riders.length > 0) {
          this.selectedRider = datos.riders[0]
          this.selectedTeam = datos.teams[0]
          this.selectedCountry = datos.stats?.countries[0].cou
        } else {
          this.selectedRider = ""
          this.selectedTeam = ""
          this.selectedCountry = ""
        }
      },

      setSelectedRider(datos) {
        this.selectedRider = datos
        this.panelVisible = 'flex'
      },

      getSelectedRider() {
        return this.selectedRider
      },

      setSelectedTeam(datos) {
        this.selectedTeam = datos
        this.panelVisible = 'flex'
      },

      getSelectedTeam() {
        return this.selectedTeam
      },

      setSelectedCountry(datos) {
        this.selectedCountry = datos
        this.panelVisible = 'flex'
      },

      getSelectedCountry() {
        return this.selectedCountry
      },

      fetchStartlist(datos) {
        return new Promise((resolve, reject) => {  
          startlistAPI.getStartlist(datos, resolve)
        })
      },

      updateStartlist(datos) {         
        this.fetchStartlist(datos)
        .then((data) => {
          this.setStartlist(data)
        })        
      }
  }
});
