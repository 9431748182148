<template>  
  <div>
    <TransitionGroup name="list" tag="List4Item" class="container_list4">
        <List4Item
          v-for="(final, index) in this.getData()"
          :key="index"
          :final="final"
        ></List4Item>
    </TransitionGroup>
  </div>
</template>

<script>

  import { useTvStore } from '@/store/tv'
  import { useStartlistStore } from '@/store/startlist'
  import List4Item from '@/components/tv/List4Item.vue'

  export default {
    name: 'tv-list4',

    data() {
      return {
        elementos: 0
      }
    },

    setup() {
      const startlist = useStartlistStore()
      const tv = useTvStore()

      return {
        startlist,
        tv
      }
    },

    methods: {
      getData() {
        let team = this.tv.data.selectedTeam.nom
        return this.startlist.getStartlist().filter((elemento) => elemento.neq === team).slice(0, this.elementos)
      },

      async init() {

        for(var i=0; i < 25; i++) {
          await this.sleep(250)
          this.elementos++
        }
      },

      sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      },
    },

    components: { 
        List4Item,
    },

  }
</script>

<style scoped>

  .container_list4 {
    display: flex;
    flex-wrap: wrap;
    width: 360px;

  }

  .list-enter-active, .list-leave-active {
    transition: all 1s ease;
  }
  .list-enter-from, .list-leave-to {
    opacity: 0;
    transform: translateX(-30px);
  }

</style>