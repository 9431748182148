<template>
  <div class="container_grupo_rider">
    <span class="dorsal" :style="color1">{{ rider.dor }}</span>
    <span class="country" v-if="rider.pai"><img :src="require(`@/assets/flags/${rider.pai}.png`)" /></span>
    <span class="nombre">{{ rider.nom }}</span>
    <span class="equipo" v-if="rider.eco">{{ rider.eco }}</span>
    <span class="general" v-if="hayClasificacionStart() && getOverall(rider.dor) !== '' && getOverall(rider.dor) !== 'leader'">{{ getOverall(rider.dor) }}</span>
    <span class="general_icon" v-if="hayClasificacionStart() && getOverall(rider.dor) !== '' && getOverall(rider.dor) === 'leader'"><img :src='getMaillotLider()'></span>
  </div>
</template>

<script>
  
  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'
  import { useStartlistStore } from '@/store/startlist'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'situation-group-rider',

    setup() {
      const config = useConfigStore()
      const startlist = useStartlistStore()
      const update = useUpdateStore()
      const pages = usePagesStore()

      return {
        config,
        startlist,
        pages,
        update
      }
    },

    props: {
      id: Number,
      rider: Object
    },

      
    computed: {
      color1() {
        if(this.config.getConfig().general.fore_color != "#f0f0f0") {
          return {
            "color":  `${this.config.getConfig().general.fore_color}`,
            "background":  `${this.config.getConfig().general.color1}`,
          }
        } else {
          return {
            "background":  `${this.config.getConfig().general.color1}`
          }
        }
      },
    },

    methods: {

      hayClasificacionStart() {

        let riders = this.update.getResultsStart().filter((item) => item.tip === '1')

        if(riders.length == 0)
          return false

        riders = this.update.getResultsStart().filter((item) => item.tip === '1')[0].riders

        if(riders.length > 0)
          return true
        else
          return false
      },
      
      getOverall(dorsal) {
        let riders = this.update.getResultsStart().filter((item) => item.tip === '1')[0].riders
        let corredor = riders.filter((item) => item.dor === dorsal)

        if(corredor?.length == 0)
          return ""
        
        if(corredor[0].pos == 1)
          return "leader"
        else
          return corredor[0].dif
      },

      getMaillotLider() {
        return this.config.getClassifications().filter((item) => item.tip === "1")[0].thumb
      }

    }

  }
</script>

<style scoped>


  @media (max-width: 768px) {
    /*.country {
      display: none;
    }

    .equipo {
      display: none;
    }*/
  }

  .container_grupo_rider {
    font-size:14px;
    text-align:left; 
    display:flex;
    align-items:center;
  }

  .dorsal {
    color:white;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 2px;
    text-align: center;
    margin-right: 5px;
    width: 25px;
  }

  .general {
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 11px;
    color: #aaa;
    margin-left: 5px;
    border: 1px solid #e9e9e9;
    background: white;
    white-space: nowrap;
  }

  .general_icon {
    margin-left: 5px;
    margin-bottom: -3px;
  }

  .general_icon img{
    height: 14px;
  }

  .nombre {
    white-space: nowrap;
  }

  .equipo {
    color: #BBB;
    padding-left: 5px;
  }

  .country img{
    height:13px;
    margin-right: 5px;
  }
  
</style>