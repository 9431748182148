<template>
  <div class="etapa_contenedor" @click="this.url()">
    <div class="etapa_numero" :style="color1">{{ item.sta }}</div>
    <div class="etapa_icon" v-if="typeof item.thu === 'undefined' || item.thu === ''" v-html="iconData(item.typ)"></div>
    <div class="etapa_thumb" v-if="typeof item.thu !== 'undefined' && item.thu !== ''"><img :src="item.thu"></div>
    <div class="etapa_texto">
      <div class="etapa_ciudades">{{ item.ci1 }} - {{ item.ci2 }}</div>
      <div class="etapa_km">{{ item.dat }} | {{ item.kms }}km</div>
    </div>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'

  export default {

    name: 'stage-option',

    props: {
      id: Number,
      item: Object
    },

    setup() {
      const config = useConfigStore()

      return {
        config
      }
    },

    computed: {
      
      color1() {
        if(this.config.getConfig().general.fore_color != "#f0f0f0") {
          return {
            "color":  `${this.config.getConfig().general.fore_color}`
          }
        } else {
          return {
            "color":  `${this.config.getConfig().general.color1}`
          }
        }
      },

    },

    methods: {
      iconData(panel) {
        switch (panel) {
          case "itt":
          case "ttt":
            return '<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke="#AAA" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6v6l4 4"/></g></svg>'
          
          case "flat":
            return '<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m5 12h14" stroke="#AAA" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          case "mountain":
            return '<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m4 16 8-8 8 8" stroke="#AAA" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          default:
            break;
        }
      },      

      url() {
        let name = this.config.getConfig().general.nameplain
        let year = this.config.getConfig().general.year
        let stage = this.item.sta

        window.location.href =  "/livetracker/" + name + "/" + year + "/" + stage + "/" + this.$i18n.locale + "/"
      }
    }

  }

</script>

<style scoped>


  .etapa_contenedor {
    cursor: pointer;
    display: grid;
    grid-template-columns: 50px 50px auto; 
    padding: 10px;
    align-items: center;  
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -ms-user-select: none; 
    -moz-user-select: none; 
    -o-user-select:none;
    user-select:none;
  }

  .etapa_contenedor:hover {
    background-color: #fafafa;
  }

  .etapa_numero {
    font-size: 30px;
    color: #DDD;
    text-align: center;
  }

  .etapa_icon {
    height: 24px;
    text-align: center;
  }

  .etapa_texto {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }

  .etapa_ciudades {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .etapa_km {
    font-size: 12px;
    color: #888;
  }

  .etapa_thumb {
    display: flex;    
    justify-content: center;
  }

  .etapa_thumb img{
    height: 30px;
    filter: drop-shadow(0 3px 3px rgba(0,0,0,.3));
    margin-top: -5px;
  }

</style>