<template>
  <div>
    <div v-if="this.pages.getCategory() === 'procyclingwomen'" class='container'>
      <div class='bloque_logo_app'>
        <img class='logo_app' src='https://tracker.helmuga.cloud/integration/carteles/pcw.png'>
      </div>
      <div class='bloque'>
        <div class='text_download'>DOWNLOAD <strong>PROCYCLINGWOMEN</strong> APP FOR FULL FEATURES</div>
      </div>
      <div class='bloque'>
        <div class='logo_container'><a href='https://apps.apple.com/us/app/procyclingwomen/id6474628009' target='_blank'><img class='logo' src='https://tracker.helmuga.cloud/images/store_apple.png'></a></div>
        <div class='logo_container'><a href='https://play.google.com/store/apps/details?id=cloud.helmuga.procyclingwomen' target='_blank'><img class='logo' src='https://tracker.helmuga.cloud/images/store_google.png'></a></div>
      </div>
    </div>

    <div v-if="this.pages.getCategory() === 'feltetdk'" class='container'>
      <div class='bloque_logo_app'>
        <img class='logo_app' src='https://tracker.helmuga.cloud/integration/carteles/feltetdk.png'>
      </div>
      <div class='bloque'>
        <div class='text_download'>HENT <strong>FELTET.DK</strong>'S APP OG PRØV ALLE FUNKTIONERNE</div>
      </div>
      <div class='bloque'>
        <div class='logo_container'><a href='https://apps.apple.com/dk/app/feltet-dk/id6477200296' target='_blank'><img class='logo' src='https://tracker.helmuga.cloud/images/store_apple.png'></a></div>
        <div class='logo_container'><a href='https://play.google.com/store/apps/details?id=cloud.helmuga.feltetdk' target='_blank'><img class='logo' src='https://tracker.helmuga.cloud/images/store_google.png'></a></div>
      </div>
    </div>

    <div v-if="this.pages.getCategory() === 'radsportlive'" class='container'>
      <div class='bloque_logo_app'>
        <img class='logo_app' src='https://tracker.helmuga.cloud/integration/carteles/radsportlive.png'>
      </div>
      <div class='bloque'>
        <div class='text_download'>DOWNLOAD <strong>RADSPORT LIVE</strong> APP für alle Funktionen</div>
      </div>
      <div class='bloque'>
        <div class='logo_container'><a href='https://apps.apple.com/de/app/radsport-live/id6475353444' target='_blank'><img class='logo' src='https://tracker.helmuga.cloud/images/store_apple.png'></a></div>
        <div class='logo_container'><a href='https://play.google.com/store/apps/details?id=cloud.helmuga.radsportlive' target='_blank'><img class='logo' src='https://tracker.helmuga.cloud/images/store_google.png'></a></div>
      </div>
    </div>

    <div v-if="this.pages.getCategory() === 'helmuga'" class='container'>
      <a class='link' href='https://helmuga.cloud' target='_blank'>
        <div>
          <img class='logo_helmuga' src='https://tracker.helmuga.cloud/images/helmuga-logos/applogo.png'>
        </div>
        <div class='bloque'>
          <div class='text_download'><strong>LIVE-DATEN-PARTNER</strong></div>
        </div>
      </a>
    </div>

    <div v-if="this.pages.getCategory() === 'itzulia'" class='container'>
      <a class='link' href='https://racecenter.itzulia.eus' target='_blank'>
        <div>
          <img style="height:30px; border: 1px solid #ddd;" src='https://tracker.helmuga.cloud/integration/carteles/itzulia.jpg'>
        </div>
        <div class='bloque'>
          <div class='text_download'><strong>{{ $t('official_racecenter') }}</strong></div>
        </div>
      </a>
    </div>

    <div v-if="this.pages.getCategory() === 'itzuliawomen'" class='container'>
      <a class='link' href='https://racecenter.itzulia-women.eus' target='_blank'>
        <div>
          <img style="height:30px; border: 1px solid #ddd;" src='https://tracker.helmuga.cloud/integration/carteles/itzulia.jpg'>
        </div>
        <div class='bloque'>
          <div class='text_download'><strong>{{ $t('official_racecenter') }}</strong></div>
        </div>
      </a>
    </div>

    <div v-if="this.pages.getCategory() === 'klasikoa'" class='container'>
      <a class='link' href='https://racecenter.klasikoa.eus' target='_blank'>
        <div>
          <img style="height:30px; border: 1px solid #ddd;" src='https://tracker.helmuga.cloud/integration/carteles/klasikoa_man.jpg'>
        </div>
        <div class='bloque'>
          <div class='text_download'><strong>{{ $t('official_racecenter') }}</strong></div>
        </div>
      </a>
    </div>

  </div>
</template>

<script>

  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'banner-app',

    setup() {
      const pages = usePagesStore()

      return {
        pages
      }
    },

  }

</script>

<style scoped>

  .container {
    border-bottom: 1px solid #dbdbdb;
    border-top: 1px solid #dbdbdb;
    padding: 10px 10px;
    gap: 15px;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .link {
    display: flex;
    gap: 10px;
    text-decoration: none;
    color: #999;
  }

  .text_download {
    text-transform: uppercase;
    text-align: center;
  }

  .bloque {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    gap: 5px;
  }

  .logo_container {
    height: 25px;
  }

  .logo {
    height: 25px;
  }

  .bloque_logo_app {
    height: 35px;
  }

  .logo_app {
    width: 35px;
    height: 35px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
  }

  .logo_helmuga {
    height: 12px;
  }

</style>
