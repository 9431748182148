<template>
  <div class="container">
    <div class="barra_menu">      
      <div class="barra_menu_elementos" v-if="this.numPages() > 0">
        <MenuItem
          v-for="(data, index) in this.config.getConfig().general.pages"
          :key="index"
          :data="data"
        ></MenuItem>

        <div style="display: flex;" v-if="typeof this.config.getConfig().general.custom_pages[$i18n.locale] !== 'undefined' && this.config.getConfig().general.custom_pages[$i18n.locale].length > 0">
          <MenuItemCustom
            v-for="(data, index) in this.config.getConfig().general.custom_pages[$i18n.locale]"
            :key="index"
            :data="data">
          </MenuItemCustom>
        </div>

        <MenuItem :data="'info'"></MenuItem>
        
      </div>
    </div>

  </div>
</template>


<script>
import { usePagesStore } from '@/store/pages'
import { useConfigStore } from '@/store/config'
import { useUpdateStore } from '@/store/update'
import MenuItem from '@/components/MenuItem'
import MenuItemCustom from '@/components/MenuItemCustom'

export default {
  name: 'Menu',

  setup() {
    const pages = usePagesStore()
    const config = useConfigStore()
    const update = useUpdateStore()

    return {
      pages,
      config,
      update
    }
  },

  components: {
    MenuItem,
    MenuItemCustom,
  },

  computed: {
    color12() {
      return {
        //"background":  `linear-gradient(270deg, ${this.config.getConfig().general.color2},${this.config.getConfig().general.color1} 45%)`
        "background":  `#fafafa`
      }
    },
    
    backcolor1() {
      return {
        "background":  `${this.config.getConfig().general.color1}`
      }
    },   
  },

  methods: {
    
    openNav() {
      document.getElementById("sidenav_mobile").style.width = "100%"
    },

    closeNav() {
      document.getElementById("sidenav_mobile").style.width = "0%"
    },

    kmsAMeta() {
      return (this.config.getConfig().general.distance - this.update.getSituation()[0].km).toFixed(1)
    },    

    numPages() {
      return this.config.getConfig().general.pages.length
    },

    setLanguage(lang) {
      this.$i18n.locale = lang
      this.closeNav()
    }

  },

}


</script>

<style scoped>

  @media (max-width: 768px) {

    .live_container {
      display: none !important;
    }

    .racetitle {
      display: flex !important;
    }

    .container {
      display: block !important;
    }
    
  }

  .container {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #dbdbdb;
    display: none;
  }

  #sidenav_mobile {
    height: calc(100vh - 60px);
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #FFF;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    text-align:center;
  }

  #sidenav_mobile span {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    cursor: pointer;
  }

  #sidenav_mobile span:hover {
    color: white;
  }

  .barra_menu{
    display: flex;
    align-items: center;
    height: 50px;
  }

  .barra_menu_elementos {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 50px !important;
  }

  .racetitle {
    display: none;
  }

  .hamburger {
    color: #000;
    cursor: pointer;
    display: none;
    align-items: center;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #dbdbdb;
    height: 100%;
  }

  .sponsor_img {
    margin-right: 10px;
    padding-right: 20px;
    border-right: 1px solid #dbdbdb;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .sponsor_img img {
    height: 30px;
  }

  .live_container {
    margin-left: auto;
    display: flex;
    align-items: center;
    border: 1px solid #dbdbdb;
  }

  .live {
    background-color: darkred;
    color: white;
    letter-spacing: 2px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .live_kms {
    background-color: white;
    color: black;
    letter-spacing: 2px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .live_img {
    background: url('https://tracker.helmuga.cloud/tv/finish.jpg');
    background-size: cover;
    height: 17px;
    width: 8px;
  }

  .live_img img {
    height: 18px;
  }

  .language_selector {
    display: flex;
    justify-content: center;
  }

  .option {
    text-transform: uppercase;
  }
  

</style>