<template>
  <div>
    <div class="container">

      <div class="separator" v-if="this.config.getConfig().general.sponsor.url">{{ $t('sponsor') }}</div>
      <div class="contenido" v-if="this.config.getConfig().general.sponsor.url">
        <a :href="this.config.getConfig().general.sponsor.url" target="_blank"><img class="sponsor_img" :src="imgLogo"></a>
      </div>

      <div style="width: calc(100% - 2px);" v-if="!this.getDominio().includes('feltet')">
        <div class="separator" v-if="this.config.getConfig().general.languages.length > 1">{{ $t('language') }}</div>
        <div class="contenido" v-if="this.config.getConfig().general.languages.length > 1"><LanguagesSelector /></div>
      </div>

      <div class="separator" v-if="this.config.getConfig().general.stages.length > 0">{{ $t('stages') }}</div>
      <div class="contenido_etapa" v-if="this.config.getConfig().general.stages.length > 0">
        <StageOption
          v-for="(item, index) in this.config.getConfig().general.stages"
          :key="index"
          :item="item"
        ></StageOption>
      </div>

      
      <div style='width: 100%;' v-if='this.hayCreditsCustom()'>
        <div 
          v-for="item in this.getCreditsCustom()"
          :key="item"
          :item="item"
          >
          <div class="separator">{{ item[this.$i18n.locale].title }}</div>
          <div class="contenido">
            <a :href="item[this.$i18n.locale].url" target="_blank"><img class="custom_credits_img" :src="item[this.$i18n.locale].image"></a>
          </div>
        </div>
      </div>

      <div class="separator">{{ $t('development') }}</div>
      <div class="contenido">
        <a href="https://helmuga.cloud" target="_blank"><img class="development_img" src="https://tracker.helmuga.cloud/images/helmuga-logos/applogo.png"></a>
        <div class="version" v-if="this.update.getUpdate().general.version > 0">Version: {{ this.update.getUpdate().general.version }}</div>
      </div>

    </div>
  </div>
</template>

<script>

  import LanguagesSelector from '@/components/LanguagesSelector'
  import StageOption from '@/components/StageOption'
  import { useConfigStore } from '@/store/config'
  import { useUpdateStore } from '@/store/update'

  export default {
    name: 'layoutinfo',

    setup() {
      const config = useConfigStore()
      const update = useUpdateStore()

      return {
        config,
        update
      }
    },

    computed: {

      imgLogo() {

        var logo = this.config.getConfig().general.sponsor.img
        var campos = logo.split('.png')
        var img = campos[0] + "_info." + campos[1] + "png"

        return img
      },

    },

    methods: {
      getDominio() {
        return window.location.hostname
      },      

      hayCreditsCustom() {
        if(typeof this.config.getConfig().general.custom_credits === 'undefined' || this.config.getConfig().general.custom_credits.length == 0)
          return false
        else
          return true
      },

      getCreditsCustom() {
        return this.config.getConfig().general.custom_credits
      }
    },

    components: {
      LanguagesSelector,
      StageOption
    },
    
  }

</script>

<style scoped>

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    height: 100%;
  }

  .separator {
    display: flex;
    width: calc(100% - 2px);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

  .contenido {
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .contenido_etapa {    
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .development_img {
    width: 200px;
  }

  .custom_credits_img {
    height: 40px;
  }
  
  .sponsor_img {
    height: 50px;
  }

  .version {
    text-transform: uppercase;
    font-size: 12px;
    color: #ddd;
    margin-top: 10px;
  }



</style>
