<template>
  <div class="container">
    <div class="barra_menu">      

      <div class="sponsor_img" v-if="this.config.getConfig().general.sponsor.img">
        <div style="height:25px;" v-if="this.config.getConfig().general.sponsor.url != ''">
          <a :href="this.config.getConfig().general.sponsor.url" v-if="this.config.getConfig().general.sponsor.url != ''" target="_blank">
            <img :src="this.config.getConfig().general.sponsor.img">
          </a>
        </div>        
        <div style="height:25px;" v-if="this.config.getConfig().general.sponsor.img != '' && this.config.getConfig().general.sponsor.url == ''">
          <img :src="this.config.getConfig().general.sponsor.img">
        </div>
      </div>
      
      <div class="barra_menu_elementos" v-if="this.numPages() > 0">
        <MenuItem
          v-for="(data, index) in this.config.getConfig().general.pages"
          :key="index"
          :data="data"
        ></MenuItem>

        <div style="display: flex;" v-if="typeof this.config.getConfig().general.custom_pages[$i18n.locale] !== 'undefined' && this.config.getConfig().general.custom_pages[$i18n.locale].length > 0">
          <MenuItemCustom
            v-for="(data, index) in this.config.getConfig().general.custom_pages[$i18n.locale]"
            :key="index"
            :data="data">
          </MenuItemCustom>
        </div>

        <MenuItem :data="'info'"></MenuItem>
      </div>

      <div class="container_titulo" v-if="!this.isMobile()">
        <div class="titulo" :style="color1">{{ this.config.getConfig().general.name }} {{ this.config.getConfig().general.year }}</div>
        <div class="thumb" v-if="this.config.getConfig().general.race_thumb"><img :src="this.config.getConfig().general.race_thumb"></div>
      </div>
      
      <div class="live_container" v-if="update.getGeneral().now > update.getGeneral().started && config.getConfig().general.distance > 0">
        <div class="live_img"><img src=""></div>
        <div class="live_kms"><span>{{ kmsAMeta() }} km</span></div>
        <div class="live" :style="backcolor1"><span>{{ $t('live') }}</span></div>
      </div>
      <RaceTitleMobile class="racetitle"></RaceTitleMobile>
    </div>

    
  </div>
</template>


<script>
import { usePagesStore } from '@/store/pages'
import { useConfigStore } from '@/store/config'
import { useUpdateStore } from '@/store/update'
import MenuItem from '@/components/MenuItem'
import MenuItemCustom from '@/components/MenuItemCustom'
import RaceTitleMobile from '@/components/RaceTitleMobile'

export default {
  name: 'Menu',

  setup() {
    const pages = usePagesStore()
    const config = useConfigStore()
    const update = useUpdateStore()

    return {
      pages,
      config,
      update
    }
  },

  components: {
    MenuItem,
    MenuItemCustom,
    RaceTitleMobile,
  },

  computed: {
    color12() {
      return {
        //"background":  `linear-gradient(270deg, ${this.config.getConfig().general.color2},${this.config.getConfig().general.color1} 45%)`
        "background":  `#fafafa`
      }
    },

    color1() {
      if(this.config.getConfig().general.fore_color != "#f0f0f0") {
        return {
          "color":  `${this.config.getConfig().general.fore_color}`,
        }
      } else {
        return {
          "color":  "white"
        }
      }
    },

    backcolor1() {
      return {
        "background":  `${this.config.getConfig().general.color1}`
      }
    },   
  },

  methods: {

    kmsAMeta() {
      return (this.config.getConfig().general.distance - this.update.getSituation()[0].km).toFixed(1)
    },    

    numPages() {
      return this.config.getConfig().general.pages.length
    },

    setLanguage(lang) {
      this.$i18n.locale = lang
    },
    
    isMobile() {
      return screen.width < 768
    },

  },

}


</script>

<style scoped>

  @media (max-width: 768px) {

    .menu_option {
      display: none !important;
    }

    .sponsor_img {
      display: none !important;
    }

    .live_container {
      display: none !important;
    }

    .racetitle {
      display: flex !important;
    }
    
  }

  .container {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #dbdbdb;
  }

  #sidenav_mobile {
    height: calc(100vh - 60px);
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #FFF;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    text-align:center;
  }

  #sidenav_mobile span {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    cursor: pointer;
  }

  #sidenav_mobile span:hover {
    color: white;
  }

  .barra_menu{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0px 10px;
  }

  .barra_menu_elementos {
    display: flex;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 50px !important;
  }

  .racetitle {
    display: none;
  }

  .sponsor_img {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 20px;
    border-right: 1px solid #cccc;    
    margin: 0 10px;
  }

  .sponsor_img img {
    height: 25px;
  }

  .container_titulo {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
  }

  .titulo {
    margin-left: auto;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    color: white;
  }

  .live {
    background-color: darkred;
    color: white;
    letter-spacing: 2px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .live_kms {
    background-color: white;
    color: black;
    letter-spacing: 2px;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .live_img {
    background: url('https://tracker.helmuga.cloud/tv/finish.jpg');
    background-size: cover;
    height: 17px;
    width: 8px;
  }

  .live_img img {
    height: 18px;
  }

  .language_selector {
    display: flex;
    justify-content: center;
  }

  .option {
    text-transform: uppercase;
  }
  
  .thumb {
    display: flex;
  }

  .thumb img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid white;
  }

</style>