<template>
  <div class="container" v-if="datosRiderFiltrado"> 
    <div class="position">{{ final.pos }}
      <br><span class="position_variation" v-if="final.pos_ofi != undefined && final.pos_ofi != ''" v-html="formatPositionVariation(final.pos_ofi)"></span>
    </div>
    <div class="container_thumb">
      <img v-if="datosRiderFiltrado.data.sum !== undefined && datosRiderFiltrado.data && datosRiderFiltrado.data.sum.thu != ''" class="thumb" :src="datosRiderFiltrado.data.sum.thu">
      <div v-if="datosRiderFiltrado.data.sum === undefined || !datosRiderFiltrado.data || datosRiderFiltrado.data.sum.thu == ''" class="icon" v-html="iconData('user')"></div>
    </div>    
    <div class="bib">{{ datosRiderFiltrado.dor }}</div>
    <div class="container_name">
      <img class="country" v-if="datosRiderFiltrado.pai" :src="require(`@/assets/flags/${datosRiderFiltrado.pai}.png`)" />
      <div class="name">{{ datosRiderFiltrado.nfu }} <span class="surname">{{ datosRiderFiltrado.afu }}</span><br>
        <span class="team">
          <span class="thumb_team" v-if="typeof this.final.equ === 'undefined' && this.getTeam(datosRiderFiltrado.eco)[0].thu"><img :src="this.getTeam(datosRiderFiltrado.eco)[0].thu"></span>
          <span class="team_name">{{ datosRiderFiltrado.neq }}</span>
          <!--<span v-if="datosRiderFiltrado.neq == 'Bahrain Victorious'" class="bike"><img src="https://tracker.helmuga.cloud/resources/sponsors/merida.svg"></span>-->
        </span>
      </div>
    </div>
    <div class="result">{{ getResult() }}<span class="bonus" v-if="typeof final.bon !== 'undefined' && final.bon"><br>(+{{ final.bon }}'')</span></div>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'resultsfull-item',

    props: {
      key: Number,
      final: Object,
    },

    setup() {
      const startlist = useStartlistStore()
      const pages = usePagesStore()

      return {
        startlist,
        pages
      }
    },

    computed: {      
      datosRiderFiltrado() {

        if(typeof this.final.equ === "undefined")
        {
          var datos = this.datosRider(this.final.dor)

          if(datos.pai == '' && typeof datos.data.sum !== 'undefined')
            datos.pai = datos.data.sum.pai

          return datos
        }
        else
        {
          var pos = this.final.pos
          var dor = this.final.equ.uci
          var pai = this.final.equ.pai
          var nom = this.final.equ.nom
          var thu = this.final.equ.thu

          const datos = {pos: pos, pos_ofi: "", dor: dor, pai: pai, nfu: '', afu: nom, neq: '', data: {sum: {thu: thu}}}
          return datos
        }
      }
    },

    methods: {

      datosRider(dorsal) {

        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        if(typeof filtradoPorTipo[0] !== "undefined")
          return filtradoPorTipo[0]
        else
          return ""

      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },

      formatPositionVariation(position) {
        if(position > 0)
          return `<span style='color:green;'>(+${position})</span>`
        else
          return `<span style='color:red;'>(${position})</span>`
      },
      
      sinPositionVariation() {
        return this.final.pos_ofi == undefined
      },
      
      conPositionVariation() {
        return this.final.pos_ofi != undefined
      },

      getResult() {
        if(this.final.res == "00:00") return ""
        if(this.final.res == "+ 274:34:17") return "DNF"
        if(this.final.res == "+ 274:34:16") return "DNS"
        if(this.final.res == "+ 274:34:15") return "DSQ"

        return this.final.res
      },
      
      getTeam(code) {
        return this.startlist.getTeams().filter((team) => team.uci == code)
      },
      
      clickRider(dorsal) {
        
        var filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        if(this.isMobile()) {
          this.pages.setPageVisible('startlist')
          this.startlist.setSelectedRider(filtradoPorTipo[0])
          this.startlist.panelVisibleDashboard = true
        } else {
          this.startlist.setSelectedRiderDashboard(filtradoPorTipo[0])
        }
      },

      isMobile() {
        return screen.width < 768
      },

      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {
          case "user":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "startlist":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          default:
            break;
        }
        
      }

    }


  }

</script>

<style scoped>

  @media (max-width: 900px) { 

    .container {
      grid-template-columns: 50px 50px auto 50px !important;
    }

    .country {
      display: none !important;
    }

    .country_dnf {
      display: none !important;
    }

    .bib {
      display: none !important;
    }

    .thumb_team {
      display: none !important;
    }

  }

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 50px 50px auto 50px;
    padding: 12px 10px;
    font-size: 14px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;    
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
  }

  .container:first-child {
    border-top: 1px solid #dbdbdb;
  }

  .position {
    text-align: center;
  }

  .position_variation {
    text-align: center;
    font-size: 10px;
  }

  .bib {
    text-align: center;
    padding: 0 10px;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bold;
    color:#777;
  }

  .container_thumb {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  
  .thumb {
    height:35px;
    margin: 0 10px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .thumb_team {
    margin-right: 3px;
    display: flex;
  }

  .thumb_team img{
    height: 13px;
  }

  .country{
    height:13px;    
    margin: 0 10px;
    border: 1px solid #eee;
  }

  .container_name {
    display: flex;
    align-items: center;
  }

  .name {
    text-align: left;
    padding: 0 10px;
  }

  .surname {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .team {
    text-align: right;
    color: #777;
    font-size: 11px;
    display:flex;    
    align-items: center;
    margin-top: 5px;
  }

  .result {
    text-align: center;
  }

  .bonus {
    font-size: 11px;
    color: green;
  }

  .team_name {
    text-align: left;
  }

  .bike {
    display: flex;
    height: 8px;
  }

  .bike img {
    margin-left: 5px;
    height: 8px;
  }
</style>