<template> 
  <div class="container_teaminfo">
    <div class="head">
      <div v-if="getTeam().thu != ''" class="thumb"><img :src="getTeam().thu"></div>
      <div class="title">
        <span class="team_code"><img v-if="getTeam().pai != ''" :src="require(`@/assets/flags/${getTeam().pai}.png`)" /> {{ getTeam().uci }}</span>
        <div class="title_name"><span class="team_name">{{ getTeam().nom }}</span></div>        
      </div>
    </div>
    <div class="contenido_teaminfo">
      <StartListRider
          v-for="(rider, index) in getTeamMates()"
          :key="index"
          :rider="rider"
          class="startlistrider child-element"
      ></StartListRider>
    </div>
  </div>
</template>

<script>
  
  import { useStartlistStore } from '@/store/startlist'
  import StartListRider from '@/components/StartListRider'

  export default {
    name: 'teamsinfo',

    setup() {
      const startlist = useStartlistStore()

      return {
        startlist,
      }
    },

    methods: {
      getTeam() {
        return this.startlist.getSelectedTeam()
      },

      getTeamMates() {
        let team = this.getTeam().nom
        return this.startlist.getStartlist().filter((elemento) => elemento.neq === team)
      }
    },

    components: {
      StartListRider
    }

  }

</script>

<style scoped>

  @media (max-width: 768px) {
    .container_teaminfo {      
      margin-top: 10px !important;
      height: calc(100% - 10px) !important;
    }

    .contenido_teaminfo {
      height: calc(100% - 60px) !important;
    }
  }


  .container_teaminfo {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .thumb {
    border-right: 1px solid #dbdbdb;
    margin-right: 20px;
  }

  .thumb img {
    height: 50px;
    padding-right: 10px;    
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .title {
    display: flex;
    flex-direction: column;
  }

  .title_name {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .team_code img {
    height: 13px;
    margin-right: 8px;
  }

  .team_code {
    text-align: left;
    color: #bbb;
    font-size: 15px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .contenido_teaminfo {
    height: calc(100% - 110px);
    overflow: auto;
  }

  .startlistrider {
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    transition: 0.3s;
  }

  .startlistrider:hover {
    background: white;
  }

  .startlistrider:last-child {
    border-bottom: 1px solid #dbdbdb;
  }


  .startlistrider:first-child {
    border-top: 1px solid #dbdbdb;
  }

</style>