import { defineStore } from 'pinia'
//import configAPI from "@/api/config"


export const useResultsStore = defineStore({
  id: "resultsStore",

    state: () => ({
      data: {
        filterClassification: '',
        filterProperties: {
          gen: [],
          cat: []
        }
      }
    }),

    actions: {

      getResults() {
        return this.data
      },

      setResults(datos) {
        this.data = datos
      },

      getFilterClassification() {
        return this.data.filterClassification
      },

      setFilterClassification(tipo) {
        this.data.filterClassification = tipo
      },

      getFilterProperties() {
        return this.data.filterProperties
      },

      setFilterProperties(tipo) {
        this.data.filterProperties = tipo
      }

  }
});
