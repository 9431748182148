<template>
  <div class="container_resultsmenu" v-if="!isMobile()">
    <ResultsMenuItem
      v-for="(classification, index) in this.filterClassificationMenus()"
      :key="index"
      :classification="classification"
    ></ResultsMenuItem>
  </div>
  
  <div class="container_resultsmenu" v-if="isMobile()">
    <ResultsMenuItemMobile
      v-for="(classification, index) in this.filterClassificationMenus()"
      :key="index"
      :classification="classification"
    ></ResultsMenuItemMobile>
  </div>
</template>


<script>
  import { useConfigStore } from '@/store/config'
  import { useUpdateStore } from '@/store/update'
  import { useResultsStore } from '@/store/results'
  import ResultsMenuItem from '@/components/ResultsMenuItem'
  import ResultsMenuItemMobile from '@/components/ResultsMenuItemMobile'

  export default ({
    setup() {
      const config = useConfigStore()
      const update = useUpdateStore()
      const results = useResultsStore()

      return {
        config,
        update,
        results
      }
    },

    components: {
      ResultsMenuItem,
      ResultsMenuItemMobile
    },
    
    methods: {
      
      isMobile() {
        return screen.width < 768
      },

      filterClassificationMenus() {
        if(this.hayResultadosFinales())
          return this.filterClassificationMenusFull()
        else
          return this.filterClassificationMenusITT()
      },

      filterClassificationMenusITT() { 

        var arrayReturnLiveTiming = []

        if(this.config.getConfig().general.type == 'itt') {                
          this.config.getClassifications().forEach(element => {
            if(element.tip < 0 || element.tip == 1)
              arrayReturnLiveTiming.push(element)
          })        
        } else {
          this.config.getClassifications().forEach(element => {
            if(element.tip < 0)
              arrayReturnLiveTiming.push(element)
          }) 
        }
        return arrayReturnLiveTiming

      },

      filterClassificationMenusFull() { 

        var arrayMenus = []
        var arrayReturn = []
        var arrayReturnResults = []
        var arrayReturnLiveTiming = []

        this.update.getResultsFinal().forEach(element => {
          if(((element.tip == "0") && element.riders.length > 0) || (element.tip != "0")) {
            arrayMenus.push(element.tip)
          }
        })

        arrayReturnResults = this.multiFilter(this.config.getClassifications(), { tip: arrayMenus})
        arrayReturn = arrayReturnLiveTiming.concat(arrayReturnResults)
        
        if(arrayReturn.length > 0 && this.results.getFilterClassification() == "") {
          this.results.setFilterClassification(arrayReturn[0].tip)
        }

        return arrayReturnLiveTiming.concat(arrayReturnResults)

      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },
      
      hayResultadosFinales() {

        var filteredArray = this.update.getResultsFinal().filter(function(itm){
          return itm.tip == 1
        })

        if(typeof filteredArray[0] !== 'undefined' && filteredArray[0].riders.length > 0)
          return true
        else
          return false

      },
    }
  })
</script>

<style scoped>  

  @media (max-width: 768px) {
    .container_resultsmenu {
      justify-content: space-around !important;
      align-items: center !important;
      padding: 0 5px !important;
    }

  }
  
  .container_resultsmenu {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

</style>