<template>
  <div class="menu_option" @click="setPageVisible(data.url)" :style="color1">
    <div class="icon_menu" v-html="iconData(data.title)"></div>
    <!--<span>{{ $t(data) }}</span>-->
  </div>


  <!--<div class="menu_option" @click="setPageVisible(data[$i18n.locale].url)" :style="color1">{{ data[$i18n.locale].title }}</div>-->
</template>

<script>
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'

  export default {

    name: 'menu-item',

    props: {
      id: Number,
      data: Object
    },

    data () {
      return {
        fillicon: this.config.getConfig().general.color1
      }
    },

    setup() {
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        config,
        pages,
      }
    },

    computed: {

      color1() {
        return {
          "color":  `${this.config.getConfig().general.color1}`
        }
      },

    },

    methods: {

      setPageVisible(url) {
        this.pages.setPageVisible('custom')
        this.pages.setCustomUrl(url)
        window.scrollTo(0,0)
      },

      strokeColorHex() {
        return this.config.data.general.fore_color
      },

      iconData(panel) {

        switch (panel) {
          case "dashboard":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m3 10.25v9.75c0 .5523.44771 1 1 1h4.42857c.55229 0 1-.4477 1-1v-6.7143h5.14283v6.7143c0 .5523.4477 1 1 1h4.4286c.5523 0 1-.4477 1-1v-9.75c0-.31476-.1482-.61115-.4-.8l-8.6-6.45-8.6 6.45c-.25181.18885-.4.48524-.4.8z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'
          
          case "startlist":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "results":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m8 6h8m-8 4h8m-8 4h3m-5 8h12c1.1046 0 2-.8954 2-2v-16c0-1.10457-.8954-2-2-2h-12c-1.10457 0-2 .89543-2 2v16c0 1.1046.89543 2 2 2z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          case "tracker":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m6.0325 15.2873 5.9676 6.7127 5.9676-6.7127c4.577-5.1485.9217-13.2873-5.9676-13.2873-6.88931 0-10.54461 8.1388-5.9676 13.2873z"/><circle cx="12" cy="10" r="3"/></g></svg>'
            
          case "info":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6.01953v-.01953"/><path d="m12 10v8"/></g></svg>'
          
          case "video":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '; fill: transparent;" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m16.1176 12 5.8824-4.66667v9.33337zm0 0v-4.66667c0-1.28866-1.0534-2.33333-2.3529-2.33333h-9.41176c-1.29949 0-2.35294 1.04467-2.35294 2.33333v9.33337c0 1.2886 1.05345 2.3333 2.35294 2.3333h9.41176c1.2995 0 2.3529-1.0447 2.3529-2.3333z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          case "radiotour":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m2 14.5v6.5c0 .5523.44772 1 1 1h3.71428c.55229 0 1.00001-.4477 1.00001-1v-5.5c0-.5523-.44772-1-1-1zm0 0v-2.5c0-5.52285 4.47715-10 10-10 5.5228 0 10 4.47715 10 10v2.5m0 0v6.5c0 .5523-.4477 1-1 1h-3.7143c-.5523 0-1-.4477-1-1v-5.5c0-.5523.4477-1 1-1z" fill="' + this.config.getConfig().general.color1 + '" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          case "livetiming":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '"  viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="' + this.config.getConfig().general.color1 + '"><circle cx="12" cy="12" r="10"/><path d="m12 6v6l4 4"/></g></svg>'

          default:
            break;
        }

      }

    },

  }

</script>

<style scoped>

  .menu_option {
    cursor: pointer;
    color: #DDD;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0px 10px;
    transition: 0.3s;
    letter-spacing: 1px;
  }

  .menu_option:hover {
    color: #FFF;
  }
  
  .icon_menu {
    fill: v-bind(fillicon)
  }

  .icon {
    fill:green;
  }

</style>
