<template>
  <div class="container">
    <iframe :src="getTrackerMap()" id="iframe"></iframe>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'

  export default {
    name: 'layouttracker',

    props: {
      url: String
    },

    setup() {
      const config = useConfigStore()

      return {
        config,
      }
    },

    methods: {
      getTrackerMap() {
        let race = this.config.getConfig().general.nameplain
        let year = this.config.getConfig().general.year
        let stage = this.config.getConfig().general.stage

        return "https://tracker.helmuga.cloud/map/" + race + "/" + year + "/" + stage + "/"
      }
    }
  }

</script>

<style scoped>

  @media (max-width: 768px) {

    .container {
      height: calc(100% - 0px) !important;
    }
    
  }

  .container {
    width: 100%;
    height: 100%;
    background:url(https://tracker.helmuga.cloud/images/loading.gif) center center no-repeat;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

</style>
