<template>
  <div class="map_container">
    <div id="mapid"></div>
  </div>
</template>

<script>
  import { onMounted, ref } from "vue";
  var LabeledMarker = require('leaflet-labeled-circle');
  import leaflet from "leaflet";
  import 'leaflet/dist/leaflet.css';
  import { useMapStore } from '@/store/map'
  import { useUpdateStore } from '@/store/update'
  import { useTimetableStore } from '@/store/timetable'
  import { useStartlistStore } from '@/store/startlist'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'
  import "leaflet-velocity"
  import moment from 'moment'  

  export default {
    
    name: "Home",

    mounted() {
      this.updateMapa()
    },

    setup() {

      const map = useMapStore()
      const update = useUpdateStore()
      const timetable = useTimetableStore()
      const startlist = useStartlistStore()
      const config = useConfigStore()
      const pages = usePagesStore()
      
      let mymap
      let marker = []
      let marker_timetable = []

      onMounted(() => {

        if(screen.width < 768) {
          mymap = leaflet.map("mapid", {
            dragging: false,
            tap: false
          })
        } else {
          mymap = leaflet.map("mapid", {
            dragging: true,
            tap: true
          })
        }

        mymap.on('load', onMapLoad)

        leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        {
          attribution: 'Tiles by <a href="http://mapc.org">MAPC</a>, Data by <a href="http://mass.gov/mgis">MassGIS</a>',
          tileOptions: {crossOriginKeyword: null}
        }).addTo(mymap)

        let geojsonLayer = leaflet.geoJson(map.getMap()[0], {style: {
					weight: 8,
					color: config.data.general.fore_color
				}}).addTo(mymap)
        
        var timetable_data = timetable.getTimetable().data
        var j = 0;

        for(var i = 0; i < timetable_data.length; i++) {
          if(timetable_data[i].hit == "-1") {
            var start = parseFloat(timetable_data[i].cat) * 100
            var finish = parseFloat(timetable_data[i].km) * 100
            var color = timetable_data[i].des

            leaflet.geoJson(map.getMapSegment(start, finish), {style: {
              weight: 3,
              color: color
            }}).addTo(mymap)

            j++;
          }          
        }

        if(j == 0) {
          leaflet.geoJson(map.getMap()[0], {style: {
            weight: 3,
            color: routeColor()
          }}).addTo(mymap)
        }

        markersTimetable()

        leaflet.geoJSON(map.getMap()[1], {

          pointToLayer: function (feature, latlng) {

            switch(feature.properties.tipo)
            {              
              case "start":
                var startIcon = leaflet.icon({
                  iconUrl: 'https://tracker.helmuga.cloud/images/start.png',
                  iconSize: [18, 18]
                });

                var idhorario_start = timetable.getTimetable().data.filter((point) => point.hit === 'start')

                if(idhorario_start.length == 0)
                  return null

                return leaflet.marker(latlng, {icon: startIcon}).bindTooltip('Start', tooltipStyle()).on('click', leaflet.bind(onClickMarker, null, idhorario_start[0].idh))
              
              case "finish":
                var finishIcon = leaflet.icon({
                  iconUrl: 'https://tracker.helmuga.cloud/images/finish.png',
                  iconSize: [18, 18]
                });

                var idhorario_finish = timetable.getTimetable().data.filter((point) => point.hit === 'finish')
                
                if(idhorario_finish.length == 0)
                  return null

                return leaflet.marker(latlng, {icon: finishIcon}).bindTooltip('Finish', tooltipStyle()).on('click', leaflet.bind(onClickMarker, null, idhorario_finish[0].idh))

              case "intermediate":
                finishIcon = leaflet.icon({
                  iconUrl: 'https://tracker.helmuga.cloud/images/' + feature.properties.titulo.toLowerCase() + '.png',
                  iconSize: [18, 18]
                });
                
                return leaflet.marker(latlng, {icon: finishIcon}).bindTooltip(feature.properties.popupContent, tooltipStyle()).on('click', leaflet.bind(onClickMarker, null, feature.properties.idhorario))

              default:
                return null
            }
          }
        }).addTo(mymap);

        mymap.fitBounds(geojsonLayer.getBounds())
          
      })


      const removeMarkers = function() {

        marker.forEach(function(item) {
          mymap.removeLayer(item)
          item.remove()
        })

        marker = []      
        
      }

      const addMarker = function(idgrupo, texto, coordinates) {

        var idgrupo_texto = idgrupo

        if(idgrupo == 0)
          idgrupo_texto = "P"

        if(marker[idgrupo])
        {
          marker[idgrupo].remove()
        }

        var feature = {
          "type": "Feature",
          "properties": {
            "text": idgrupo_texto,
            "labelPosition": coordinates.slice().reverse()
          },
          "geometry": {
            "type": "Point",
            "coordinates": coordinates.slice().reverse()
          }
        };

        marker[idgrupo] = new LabeledMarker(
          feature.geometry.coordinates.slice().reverse(),
          feature, {
            markerOptions: markerStyle()
          }).addTo(mymap);

        marker[idgrupo].bindTooltip(texto, tooltipStyle())

        marker[idgrupo].on('click', leaflet.bind(onClickMarker, null, "situationgroup-" + idgrupo))
      }

      const onClickMarker = async function(idhorario) {

        if(typeof idhorario === 'undefined')
          return


        if(isNaN(idhorario)) {       

          var campos = idhorario.split("-")
          var idg = campos[1]

          if(idg != 0) {
            pages.setPopupType('')
            await new Promise(r => setTimeout(r, 100))
            pages.setPopupType('situationgroup')
            pages.setSelectedSituationGroup(idg)

            if(isMobile())
              pages.setVisiblePopup(true)
          }

        } else {

          pages.setPopupType('')
          await new Promise(r => setTimeout(r, 100))
          pages.data.selectedTimetablePoint = idhorario
          pages.setPopupType('timetablepointinfo')  

          if(isMobile())
            pages.setVisiblePopup(true)
        }

      }

      const onMapLoad = function() {
        pages.setLoading(false)
      }

      const markerStyle = function() {
        return {
          "radius": 9,
          "fillOpacity": 1,
          "fillColor": "#0087d2",
          "color": "#000000",
          "weight": 1,
        }
      }

      const tooltipStyle = function() {
        return {
          "direction": "top",
          "opacity": 1,
          "offset": [0, -10]
        }
      }

      const tooltipStyleTimetable = function() {
        return {
          "className": 'custom-tooltip',  // Clase para el estilo personalizado
          "direction": "top",
          "opacity": 1,
          "offset": [0, -1],
          "padding": 1
        }
      }
      
      const routeColor = function() {
        return config.getConfig().general.color1
      }
            
      const hora = function (date) {
        let formatString = 'HH:mm[h]'
        return moment(parseInt(date)).format(formatString);
      }

      const isMobile = function() {
        return screen.width < 768
      }

      const markersTimetable = function () {
        const smallDotIcon = leaflet.divIcon({
            html: '<div class="small-dot"></div>',  // HTML para el punto
            className: '',  // Sin clases adicionales de Leaflet
            iconSize: [8, 8],  // Tamaño del icono (ajústalo según prefieras)
            iconAnchor: [5, 5] // Para centrar el punto en la coordenada
        });

        const filtro = timetable.getTimetable().data.filter((item) => {
          return item.idh === '' && item.hor1 !== '' && item.km != 0;
        })

        if(filtro.length == 0)
          return

        filtro.forEach(obj => {

          const latlng = [obj.lat, obj.lon];
          const texto_tooltip = hora(obj.hor1 * 1000 + update.getGeneral().started)

          const marker = leaflet.marker(latlng, { icon: smallDotIcon  })
            .bindTooltip(texto_tooltip, { permanent: true, ...tooltipStyleTimetable() })
            .addTo(mymap);

          marker_timetable.push(marker)
        })

        /*let last_km = timetable.getTimetable().data[timetable.getTimetable().data.length - 1]

        if(last_km.hor1 !== '') {

          const latlng = [last_km.lat, last_km.lon];
          const texto_tooltip = hora(last_km.hor1 * 1000 + update.getGeneral().started)

          const marker = leaflet.marker(latlng, { icon: smallDotIcon })
            .bindTooltip(texto_tooltip, { permanent: true, ...tooltipStyleTimetable() })
            .addTo(mymap);

          marker_timetable.push(marker)
        }*/

        marker_timetable.forEach(marker => {
            mymap.removeLayer(marker);
        });

        /*const toggleControl = leaflet.control({ position: 'topright' });

        toggleControl.onAdd = function (map) {
            const div = leaflet.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
            div.innerHTML = 'Timetable'; // El texto del botón
            div.style.backgroundColor = 'white';
            div.style.padding = '5px';
            div.style.cursor = 'pointer';

            leaflet.DomEvent.disableClickPropagation(div);

            div.onclick = function () {
              toggleTimetableMarkers();
            };

            return div;
        };

        toggleControl.addTo(mymap);*/

      }

      const toggleTimetableMarkers = function () {
          if (marker_timetable.length > 0 && mymap.hasLayer(marker_timetable[0])) {
              marker_timetable.forEach(marker => {
                  mymap.removeLayer(marker);
              });
          } else {
              marker_timetable.forEach(marker => {
                  marker.addTo(mymap);
              });
          }
      };

      return {
        map,
        update,
        timetable,
        startlist,
        addMarker,
        removeMarkers,
        config,
        pages
      }
      
    },

    methods: {
      updateMapa() {
        let situation = this.update.getSituation()
        let coordenadas = this.map.getMap()[0].coordinates

        this.removeMarkers()

        if(this.finishStageData() || this.config.getConfig().general.type === "itt" || this.config.getConfig().general.type === "ttt")
          return

        for(var i=0;i<situation.length;i++)
        {
          let posicion_array = parseInt(situation[i].km * 100)

          if(typeof coordenadas[posicion_array] !== 'undefined') {
            let lat = coordenadas[posicion_array][1]
            let lon = coordenadas[posicion_array][0]
            let idgrupo = situation[i].idg
            let num_corredores = situation[i].nco

            if(idgrupo != "0")
              idgrupo = i + 1;

            let corredores_datos = this.multiFilter(this.startlist.riders, { dor: situation[i].riders})
            let apellidos = []
            for(var j=0;j<corredores_datos.length;j++)
            {
              apellidos.push(corredores_datos[j].afu)
            }

            let text = "<strong>" + this.nombreGrupo(situation[i].idg) + "</strong>"
            if(num_corredores > 5) {
              text += " (x" + num_corredores + ")"
            }
            else {
              if(apellidos.length > 0)
                text += "<br>" + apellidos.join(", ")
            }

            if(situation[i].sgr > 0)
              text += "<br>" + this.aMinSegundos(situation[i].sgr)

            this.addMarker(idgrupo, text, [lat, lon])
          }
        }

      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },

      aMinSegundos(milisegundos) {
        const value = milisegundos / 1000;
        const sec = parseInt(value, 10); 
        let hours   = Math.floor(sec / 3600); 
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60); 
        
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        
        return '+ ' + minutes + ':' + seconds;
      },

      nombreGrupo(id) {
        if(id < 2)
          return this.$t('group_names[' + parseInt(id) + ']')
        else
          return this.$t('group_names[2]') + " " + (parseInt(id) - 2)
      },

      finishStageData() {

        var filtradoPorTipo = null

        filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
          return item.tip == 0
        })

        if(filtradoPorTipo.length > 0 && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false

      },

    }

  }
</script>

<style scoped>

  .map_container, #mapid {
    width:100%;
    height: 100%;
  }

</style>

<style>
  .leaflet_remove_tooltip {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
  }

  .custom-tooltip {
    font-size: 11px;
    border-radius: 8px;
  }

  .small-dot {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid black
  }

  .custom-tooltip.leaflet-tooltip::before {
    display: none !important; /* Oculta la flecha del tooltip */
  }
</style>