<template>
    <div class="container_riders">
      <StartListRider
          v-for="(rider, index) in this.getData()"
          :key="index"
          :rider="rider"
          class="startlistrider child-element"
      ></StartListRider>
    </div>

</template>

  export default {

  }

<script>

  import { useStartlistStore } from '@/store/startlist'
  import StartListRider from '@/components/StartListRider'

  export default {
    name: 'start-list',

    setup() {
      const startlist = useStartlistStore()

      return {
        startlist
      }
    },

    props: {
      filterValue: String
    },

    components: { 
        StartListRider
    },

    methods: {
      getData() {
        return this.startlist.riders.filter((elemento) => elemento.nfu.toLowerCase().includes(this.filterValue.toLowerCase()) || elemento.afu.toLowerCase().includes(this.filterValue.toLowerCase()) || elemento.dor == this.filterValue)
      }
    }
  }
</script>

<style scoped>


  .startlistrider {
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    transition: 0.3s;
  }

  .startlistrider:hover {
    background: white;
  }

  .startlistrider:last-child {
    border-bottom: 1px solid #dbdbdb;
  }


  .startlistrider:first-child {
    border-top: 1px solid #dbdbdb;
  }

</style>
