<template>
  <div class="container">
    <iframe :src="this.pages.getCustomUrl()" id="iframe"></iframe>
  </div>
</template>

<script>

  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'layoutcustom',

    props: {
      url: String
    },

    setup() {
      const pages = usePagesStore()

      return {
        pages,
      }
    },
  }

</script>

<style scoped>

  @media (max-width: 768px) {
    #iframe {
      height: calc(100% - 4px) !important;
    }    
  }

  .container {
    width: 100%;
    height: 100%;
    background:url(https://tracker.helmuga.cloud/images/loading.gif) center center no-repeat;
  }

  #iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

</style>
