<template>
  <div>
    <div class='point'>
      <div class='title' v-if="this.showTitulo === '1'">
        <div><img class='img_category' :src="'https://tracker.helmuga.cloud/images/profile_' + intermediate.tip + '_' + intermediate.cat.toLowerCase() + '.png'"></div>
        <div class='title_text'>
          <span class='point_title'>{{ intermediate.tit }}</span>
          <span class='point_subtitle'>{{ getNombreClasificacion() }} - {{ intermediate.km }} km</span>
        </div>
      </div>
      <div class='results'>
        <ResultsListItemPoint
          v-for="(rider_result, index) in intermediate.riders"
          :key="index"
          :rider_result="rider_result"
          class="rider"
        ></ResultsListItemPoint>
      </div>
    </div>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'
  import ResultsListItemPoint from '@/components/ResultsListItemPoint'

  export default {
    name: 'resultslist-item',

    props: {
      id: Number,
      intermediate: Object,
      showTitulo: String
    },

    setup() {

      const config = useConfigStore()

      return {
        config
      }
    },

    components: {
      ResultsListItemPoint
    },
    
    methods: {
      getDataIntermediate(tipo) {

        var classification_idiomas = this.config.getClassifications().filter(function(item){
            if(item.tip === tipo) return item
        });

        return classification_idiomas[0].langs
      },

      getNombreClasificacion() {
        if(typeof this.getDataIntermediate(this.intermediate.tip)[this.$i18n.locale] !== 'undefined')
          return this.getDataIntermediate(this.intermediate.tip)[this.$i18n.locale]
        else
          return this.$t('ranking_' + this.intermediate.tip)
      },
    }

  }
</script>

<style scoped>

  .title_text {
    display: flex;
    flex-direction: column;
  }

  .title {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .point {    
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .point_title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .point_subtitle {
    font-size: 12px;
  }

  .img_category {
    height: 20px;
    padding: 10px 10px 10px 0px;
  }

  .rider {
    border-bottom: 1px solid #dbdbdb;
  }

  .rider:last-child {
    border-bottom: 0px;
  }

</style>