<template>
  <div>
    <div class="container">
      <div class="separator_winner">{{ $t('race_cancelled') }}</div>
    </div>
    <div v-if="this.showSummaryMaillots()">
      <div class="separator">{{ $t('leaders') }}</div>
      <SummaryMaillots />
    </div>
  </div>
</template>

<script>

  import { useUpdateStore } from '@/store/update'
  import SummaryMaillots from '@/components/SummaryMaillots'

  export default ({
    setup() {
      const update = useUpdateStore()

      return {
        update,
      }
    },    
    
    components: {
      SummaryMaillots
    },

    methods: {      

      isMobile() {
        return screen.width < 768
      },

      showSummaryMaillots() {
        if(typeof this.update.getResultsRidersFinal().length === 'undefined')
          return true
        else
          return false
      },

    }
  })

</script>

<style scoped>

  @media (max-width: 768px) {
    
    .separator {
      display: flex !important;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .thumb {
    text-align: center;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .thumb img {
    height: 100px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .name {
    text-align: center;
  }

  .surname {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 5px;
  }

  .team {
    color: #777;
    font-size: 11px;
    text-align: center;
  }

  .title {    
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .separator {
    display: none;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

  .separator_winner {
    display: flex;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

</style>