<template>  
  <div>
    <TransitionGroup name="list" tag="List2Item">
      <List2Item
        v-for="(final, index) in this.getData()"
        :key="index"
        :final="final"
      ></List2Item>
    </TransitionGroup>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'
  import List2Item from '@/components/tv/List2Item.vue'

  export default {
    name: 'tv-list2',

    data() {
      return {
        elementos: 0
      }
    },

    setup() {
      const config = useConfigStore()

      return {
        config
      }
    },

    methods: {
      getData() {
        return this.config.data.stats.lastwinners.slice(0, this.elementos)
      },

      async init() {
        for(var i=0; i < 25; i++) {
          await this.sleep(50)
          this.elementos++
        }
      },

      sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      },
    },

    components: { 
        List2Item,
    },

  }
</script>

<style scoped>

  .list-enter-active, .list-leave-active {
    transition: all 0.1s ease;
  }
  .list-enter-from, .list-leave-to {
    opacity: 0;
    transform: translateX(-30px);
  }

</style>