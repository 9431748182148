<template>
  <div>
    <div class="title">
    <div style="display:flex;">
      <div v-if="idclasificacion == '-1'" class='laps' style="padding:0"><img class='img_category_time' src="https://tracker.helmuga.cloud/images/finish.png"></div>        
      <div v-if="idclasificacion < -1 && idclasificacion != -9999" class='laps' style="padding:0"><img class='img_category_time' :src="imageIntermedio(getTipo(idclasificacion).num)"></div>
      <div v-if="idclasificacion == -9999" class='laps' style="padding:0"><img class='img_category_time' src="https://tracker.helmuga.cloud/images/start.png"></div>
      <div class="container_classification_name">
        <div>{{ getNombreClasificacion(idclasificacion) }}</div>
      </div>
    </div>
    </div>
    <div class="container_riders_live_timing" v-if="getData(idclasificacion).riders.length > 0 && idclasificacion != -9999">
      <ResultsFullItemLiveTimingSmall
        v-for="(final, index) in getData(idclasificacion).riders"
        :key="index"
        :final="final"
      ></ResultsFullItemLiveTimingSmall>
    </div>
    <div class="container_riders_live_timing" v-if="getStartTimesFilterByTime().length > 0 && idclasificacion == -9999">
      <ResultsFullItemStartTimesSmall
        v-for="(final, index) in this.getStartTimesFilterByTime()"
        :key="index"
        :final="final"
      ></ResultsFullItemStartTimesSmall>
    </div>
    <div class="container_no_riders_live_timing" v-if="getData(idclasificacion).riders.length == 0 && idclasificacion != -9999">
      <div class="no_results">{{ $t('no_results_available') }}</div>
    </div>
    <div class="container_no_riders_live_timing" v-if="getStartTimes().length == 0 && idclasificacion == -9999">
      <div class="no_results">{{ $t('no_start_times_available') }}</div>
    </div>
    <div class="container_no_riders_live_timing" v-if="getStartTimes().length > 0 && getStartTimesFilterByTime().length == 0 && idclasificacion == -9999">
      <div class="no_results">{{ $t('all_riders_started') }}</div>
    </div>
  </div>
</template>


<script>

  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'
  import { useResultsStore } from '@/store/results'
  import ResultsFullItemLiveTimingSmall from '@/components/ResultsFullItemLiveTimingSmall'
  import ResultsFullItemStartTimesSmall from '@/components/ResultsFullItemStartTimesSmall'

  export default {
    name: 'results-full-live-timing',

    props: {
      key: Number,
      idclasificacion: Number
    },

    setup() {
      const update = useUpdateStore()
      const config = useConfigStore()
      const results = useResultsStore()

      return {
        update,
        config,
        results
      }
    },

    methods: {
      
      getData(tipo) {
        var filtradoPorTipo

        filtradoPorTipo = this.update.getResultsLiveTiming().filter(function(item) {
          return item.raceid == tipo
        })

        if(typeof filtradoPorTipo[0] !== "undefined")
        {
          return filtradoPorTipo[0]
        }
        else
        {
          return {riders:[]}
        }
      },

      getTipo(tipo) {

        var item_seleccionado = this.config.getConfig().classifications.filter(function(item) {
          if(item.tip == tipo)
            return item
        })

        return item_seleccionado[0]
      },
      
      imageIntermedio(num) {
        return "https://tracker.helmuga.cloud/images/time_" + num + ".png";
      },

      getStartTimes() {
        return this.config.getConfig().general.starttimes
      },

      getStartTimesFilterByTime() {
        var now = Date.now()
        return this.config.getConfig().general.starttimes.filter(item => item.tim > now)
      },

      getNombreClasificacion(idclasificacion) {
        if(typeof this.getTipo(idclasificacion).langs[this.$i18n.locale] !== 'undefined')
          return this.getTipo(idclasificacion).langs[this.$i18n.locale]
        else
          return this.$t('ranking_' + this.getTipo(idclasificacion).tip)
      },

    },

    components: {
      ResultsFullItemLiveTimingSmall,
      ResultsFullItemStartTimesSmall  
    },



  }


</script>

<style scoped>

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 10px;
    font-size: 14px;
    background: #fafafa;
    border-top: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .container_classification_name {
    display:flex;
    flex-direction: column;
    justify-content: center;
  }

  .container_riders_live_timing {
    overflow: auto;
    height: 180px; /* 365px */
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .container_no_riders_live_timing {
    overflow: auto;
    height: 180px; 
    border: 1px solid #dbdbdb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no_results {
    text-transform: uppercase;
    font-weight: bold;
    color: #bbb;
    font-size: 12px;
  }
  
  .subtitle {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }

  .img_category_time {
    height: 16px;
    padding: 10px 10px 10px 0px;
  }

  .laps {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .laps_number {
    font-size: 16px;
  }

  .laps_text {
    font-size: 11px;
    font-weight: normal;
  }

  .img_category_container {
    display: flex;
    align-items: center;
  }

</style>