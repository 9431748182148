<template>  
  <div class="container">
    <div class="position">{{ final.yea }}</div>
    <div style="height:12px"><img class="country" :src="require(`@/assets/flags/${final.cou}.png`)" /></div>
    <div>{{ final.nam }} <span style="text-transform: uppercase; font-weight: bold;">{{ final.sur }}</span></div>
  </div>
</template>

<script>


  export default {
    name: 'racetop10item',

    props: {
      key: Number,
      final: Object,
    },

  }
</script>

<style scoped>

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px auto;
    padding: 12px 10px;
    font-size: 12px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
  }

  .country {
    height: 12px;
  }

  .result {
    text-align: right;
  }


</style>