<template> 
  <div class="container_teaminfo">
    <div class="head">
      <div class="thumb"><img :src="require(`@/assets/flags/${getCountry()}.png`)" /></div>
      <div class="title">        
        <div class="title_name"><span class="team_code">{{ getCountry().toUpperCase() }}</span><span class="team_name">{{ nombrePais(getCountry().toUpperCase()) }}</span></div>        
      </div>
    </div>
    <div class="contenido_teaminfo">
      <StartListRider
          v-for="(rider, index) in getCountryRiders()"
          :key="index"
          :rider="rider"
          class="startlistrider child-element"
      ></StartListRider>
    </div>
  </div>
</template>

<script>
  
  import { useStartlistStore } from '@/store/startlist'
  import StartListRider from '@/components/StartListRider'

  export default {
    name: 'ridercountryinfo',

    setup() {
      const startlist = useStartlistStore()

      return {
        startlist,
      }
    },

    methods: {
      getCountry() {
        return this.startlist.getSelectedCountry()
      },

      getCountryRiders() {
        let country = this.startlist.getSelectedCountry()
        return this.startlist.getStartlist().filter((elemento) => elemento.pai === country)
      },

      nombrePais(codigo) {
        if(codigo == "CC")
          codigo = "CA"
          
        let regionNames = new Intl.DisplayNames([this.$i18n.locale], {type: 'region'})
        return regionNames.of(codigo)
      },
    },

    components: {
      StartListRider
    }

  }

</script>

<style scoped>

  @media (max-width: 768px) {
    .container_teaminfo {      
      margin-top: 10px !important;
      height: calc(100% - 10px) !important;
    }

    .contenido_teaminfo {
      height: calc(100% - 75px) !important;
    }
  }


  .container_teaminfo {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 5px;
    height: 40px;
  }

  .thumb {
    border-right: 1px solid #dbdbdb;
    margin-right: 15px;
  }

  .thumb img {
    height: 30px;
    margin-right: 20px;
    margin-left: 5px;
    border: 1px solid #eee;
  }

  .title {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }

  .title_name {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 20px;
    font-weight: bold;
  }

  .team_code img {
    height: 18px;
    margin-right: 10px;
  }

  .team_code {
    text-align: left;
    color: #bbb;
    font-size: 20px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 10px;
  }

  .contenido_teaminfo {
    height: calc(100% - 40px);
    overflow: auto;
  }

  .startlistrider {
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    transition: 0.3s;
  }

  .startlistrider:hover {
    background: white;
  }

  .startlistrider:last-child {
    border-bottom: 1px solid #dbdbdb;
  }


  .startlistrider:first-child {
    border-top: 1px solid #dbdbdb;
  }

</style>