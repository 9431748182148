<template>
    <div class="container_group" v-if="this.grupoVisible(group)">
      <div class="km" v-if="group.pos === 0">
        <span class="km_img"><img src="https://tracker.helmuga.cloud/images/finish.png"></span>
        <span class="km_text">{{ group.km_a_meta }} km</span>
      </div>        
      <div class="groupname" :class="tituloFondo">
        <span v-if="(group.idgrupo === '1' && typeof group.km_a_meta === 'undefined') || (group.idgrupo !== '1' && group.nombregrupo != '')" :class="nombreGrupoPadding">{{ group.nombregrupo }}</span>
        <div class="timegap" v-if="group.pos !== 0 && group.tiempo_a_cabeza_carrera !== ''" :class="tituloFondo">{{ aMinSegundos(group.tiempo_a_cabeza_carrera) }}</div>
      </div>

        <div class="riders_container" v-show="this.tv.data.selectedSituationData === 'riders'">          
          <div class="riders_small_container" v-if="this.ridersGroup().length > 0">
            <div class="riders" v-if="this.ridersGroup().length < 6">
              <SituationGroupRiders
                v-for="(rider, index) in this.ridersGroup()"
                :key="index"
                :rider="rider"
              ></SituationGroupRiders>
            </div>
            <div class="riders_number" v-if="this.ridersGroup().length >= 6">{{ this.ridersGroup().length }} {{ $t('riders' + this.config.getGenere()) }}</div>
          </div>
        </div>
        
        <div class="riders_small_container" v-show="this.tv.data.selectedSituationData === 'speed' && group.velocidad !== ''">
          <div class="speed_container">
            <span class="speed_value">{{ group.velocidad }}</span>
            <span class="speed_unit">km/h</span>
          </div>
        </div>
        
        <div class="riders_small_container" v-show="this.tv.data.selectedSituationData === 'speedminkm' && group.velocidad !== ''">
          <div class="speed_container">
            <span class="speed_value">{{ (3600 / parseInt(group.velocidad)) / 60 }}</span>
            <span class="speed_unit">min/km</span>
          </div>
        </div>

    </div>
</template>

<script>
  
  import { useTvStore } from '@/store/tv'
  import { useConfigStore } from '@/store/config'
  import { useStartlistStore } from '@/store/startlist'
  import SituationGroupRiders from '@/components/tv/SituationGroupRiders.vue'

  export default {
    name: 'tv-situationgroup',

    props: {
      key: Number,
      group: Object,
    },


    setup() {
      const config = useConfigStore()
      const tv = useTvStore()
      const startlist = useStartlistStore()

      return {
        tv,
        startlist,
        config
      }
    },

    computed: {
      tituloFondo() {
        switch(this.group.idgrupo) {
          case "0":
            return 'titlePeloton'

          case "1":
            return 'titleHead'

          default:
            return 'titleGroup'
        }
      },

      nombreGrupoPadding() {
        if(this.group.pos !== 0 && this.group.tiempo_a_cabeza_carrera !== '')
          return 'groupname_text_bottom'
        else
          return 'groupname_text'
      }

    },


    methods: {

      grupoVisible(group) {
        return group.visible === '1' && this.tv.data.selectedSituationGroup != group.idgrupoorden
      },

      ridersGroup() {
        return this.multiFilter(this.startlist.riders, { dor: this.group.dorsales})
      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },

      aMinSegundos(milisegundos) {
        const value = milisegundos / 1000;
        const sec = parseInt(value, 10); 
        let hours   = Math.floor(sec / 3600); 
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60); 
        
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        
        return '+ ' + minutes + ':' + seconds;
      },
    },

    components: {
      SituationGroupRiders
    }
    

  }
</script>

<style scoped>

  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
  }
  .component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active for <2.1.8 */ {
    opacity: 0;
  }

  .container_group {
    /*width: 225px;*/
    width: 260px;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 5px;
  }
  
  .container_group_full {
    width: 500px;
    padding: 10px;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
  }

  .km {
    width: 100%;
    background: #111;
    text-transform: unset;
    font-size: 30px;
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px;
    align-items: center;
    letter-spacing: 1px;
  }  

  .riders_full_container {
    width: 100%;
  }
  
  .groupname {
    text-transform: unset;
    font-size: 16px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
    flex-direction: column;
  }

  .groupname_text {
    padding: 5px 10px;
  }

  .groupname_text_bottom {
    padding-top: 5px;
    padding-right: 10px;
  }

  .timegap {
    font-size: 25px;
    text-align: right;
    display: inline-block;
    padding: 5px 10px;
  }

  .km_img {
    display: flex;
    height: 25px;
    margin-right: 10px;
  }

  .km_img img{
    height: 25px;
  }

  .title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 25px;
    color: #aaa;
    margin-bottom: 10px;
  }

  .riders {
    width: 100%;
    overflow: hidden;
  }
 
  .riders_small_container {
    padding: 10px;
  }

  .riders_container {
    position: relative;
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .riders_number {
    display: flex;
    justify-content: center;
    font-size: 18px;
  }

  .titlePeloton {
    background: #122E6B;
  }
  
  .titleGroup {
    background: #A00E2C;
  }
  
  .titleHead {
    background: #E4AD46;
    color: #111;
  }

  .speed_container {
    display: flex;
    align-items: center;
  }

  .speed_value {
    font-size: 30px;
    margin-right: 5px;
  }

  .speed_unit {
    font-size: 14px;
  }

</style>