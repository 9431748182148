<template>

  <div class="container" v-if="item.tip == 'com' && typeof item.tex[$i18n.locale] !== 'undefined'">
    <div style="width:100%;">
      <div class="container_commentary">
        <div class="titulo">
          <div class="km" v-if="item.km > 0 && item.km <= parseFloat(this.config.getConfig().general.distance)"><img src="https://tracker.helmuga.cloud/images/finish.png" class="icon"><span>{{ kmToGo(item.km) }}km</span></div>
          <div class="titulo_right">
            <div class="options" v-if="item.tex[$i18n.locale].replace(/(<([^>]+)>)/gi, '').trim() !== ''">
              <div class="icon_social">
                <ShareNetwork
                  network="twitter"
                  url=""
                  :title="textSocialShareTwitter()"
                  hashtags=""
                >
                  <div class="icon_menu" v-html="iconData('twitter')"></div>
                </ShareNetwork>
              </div>
            </div>            
            <div class="fecha"><span>{{ formattedDate(item.hor) }}</span></div>
          </div>
        </div>
        <div class="texto">
          <v-runtime-template :template="item.tex[$i18n.locale]"></v-runtime-template>
        </div>
        
      </div>
    </div>    
  </div>

  <div class="tweet_embed" v-if="item.tip == 'twe' && typeof item.tex[$i18n.locale] !== 'undefined'">
    <iframe 
      width=100%
      border=0 
      frameborder=0 
      height=300
      :src="embedTweet(item.tex[$i18n.locale])">
    </iframe>
  </div>

  <div class="container" v-if="item.tip == 'aud' && typeof item.tex[$i18n.locale] !== 'undefined'">
    <div class="container_commentary" style="width:100%;">
      <CommentaryAudio
        style="width:100%;"
        :item="item">
      </CommentaryAudio>
    </div>
  </div>
  
  <div class="container" v-if="item.tip == 'ytb' && typeof item.tex[$i18n.locale] !== 'undefined'">
    <div class="container_commentary" style="width:100%;">
      <CommentaryYouTube
        style="width:100%;"
        :item="item">
      </CommentaryYouTube>
    </div>
  </div>

  <div class="container" v-if="item.tip == 'iframe' && typeof item.tex[$i18n.locale] !== 'undefined'">
    <div class="container_commentary" style="width:100%;">
       <div class="titulo">
        <div class="km" v-if="item.km > 0 && item.km <= parseFloat(this.config.getConfig().general.distance)"><img src="https://tracker.helmuga.cloud/images/finish.png" class="icon"><span>{{ kmToGo(item.km) }}km</span></div>
        <div class="titulo_right">
          <div class="options" v-if="item.tex[$i18n.locale].replace(/(<([^>]+)>)/gi, '').trim() !== ''"></div>            
          <div class="fecha"><span>{{ formattedDate(item.hor) }}</span></div>
        </div>
      </div>
      <div class="texto">
        <iframe :src="item.tex[$i18n.locale]" class="iframe"></iframe>
      </div>     
    </div>
  </div>

</template>

<script>

  import moment from 'moment'
  import CommentaryAudio from '@/components/CommentaryAudio'
  import CommentaryYouTube from '@/components/CommentaryYouTube'
  import { useStartlistStore } from '@/store/startlist'
  import { usePagesStore } from '@/store/pages'
  import { useConfigStore } from '@/store/config'
  import { useUpdateStore } from '@/store/update'
  import VRuntimeTemplate from 'vue3-runtime-template'

  export default {
    name: 'commentary-item',

    props: {
      id: Number,
      item: Object
    },

    data() {
      return {
        color: 'red',
        linkcolor: this.config.getConfig().general.color1,
      }
    },

    setup() {
      const startlist = useStartlistStore()
      const pages = usePagesStore()
      const config = useConfigStore()
      const update = useUpdateStore()

      return {
        startlist,
        pages,
        config,
        update
      }
    },

    components: {
      CommentaryAudio,
      CommentaryYouTube,
      VRuntimeTemplate
    },

    methods: {

      async clickRider(dorsal) {
        var rider = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('riders')

        if(this.isMobile()) {          
          if(this.config.getConfig().general.fcintegration === '1')
            this.setPageVisible("https://firstcycling.com/m2/rider.php?r=" + rider[0].ifc)
          else {
            this.startlist.setSelectedRider(rider[0])
            this.pages.setVisiblePopup(true)
          }     
        } else {
          this.startlist.setSelectedRider(rider[0])
        }
      },

      setPageVisible(url) {
        this.pages.setPageVisible('custom')
        this.pages.setCustomUrl(url)
        window.scrollTo(0,0)
      },

      clickImageDetail(idcomentario) {

        if(!this.isMobile()) {
          let datos = this.update.getUpdate().commentary.filter((item) => item.idm === idcomentario)[0]
          
          this.pages.setImageDetail(datos.ima)
          this.pages.setImageDetailText(this.item.tex[this.$i18n.locale].replaceAll("<br>", "::br::").replace(/(<([^>]+)>)/gi, "").replaceAll("::br::", "<br>"))
        }
          
      },

      formattedDate(timestamp) {
        return moment(parseInt(timestamp)).format('HH:mm\\h');
      },

      embedTweet(urlTweet) {
        return "https://tracker.helmuga.cloud/embed_tweet.php?url=" + urlTweet
      },

      iconData(name) {
        switch (name) {
          case "km":
            return '<svg fill="none" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#BBB" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m6.0325 15.2873 5.9676 6.7127 5.9676-6.7127c4.577-5.1485.9217-13.2873-5.9676-13.2873-6.88931 0-10.54461 8.1388-5.9676 13.2873z"/><circle cx="12" cy="10" r="3"/></g></svg>'
          
          case "fecha":
            return '<svg fill="none" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#BBB" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6v6l4 4"/></g></svg>'
          
          case "twitter":
            return '<svg class="icon_menu" fill="#fff" stroke="#ccc" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m8.288 21c7.544 0 11.672-6.5456 11.672-12.21228 0-.18415 0-.36829-.008-.55244.8-.61103 1.496-1.36436 2.048-2.21813-.752.34318-1.544.57755-2.36.67799.856-.53569 1.496-1.3811 1.8-2.37716-.808.50222-1.688.85377-2.608 1.03792-1.552-1.73266-4.152-1.81636-5.808-.18415-1.064 1.04629-1.52 2.61153-1.184 4.10145-3.288-.17578-6.368-1.80799-8.448-4.48649-1.088 1.95866-.536 4.46975 1.272 5.72529-.656-.0167-1.288-.2009-1.864-.5357v.0502c0 2.0424 1.376 3.8001 3.296 4.2103-.608.1758-1.24.2009-1.856.0753.536 1.7494 2.08 2.9464 3.832 2.9799-1.456 1.1969-3.248 1.8414-5.096 1.8414-.328 0-.656-.0251-.976-.0586 1.88 1.2639 4.056 1.9252 6.288 1.9252z" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/></svg>'
          
          case "facebook":
            return '<svg class="icon_menu" fill="#fff" stroke="#ccc" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m15 2h3v4h-2c-1.1046 0-2 .89543-2 2v2h4l-1.0435 4h-3.1304v8h-4.17393v-8h-3.65217v-3.9604h4v-3.0396c0-2.76142 2.2386-5 5-5z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'
          
          default:
            break;
        }        
      },
      
      isMobile() {
        return screen.width < 768
      },

      kmToGo(km_covered) {
        return parseFloat(this.config.getConfig().general.distance - km_covered).toFixed(1)
      },

      getStringBetween(str, start, end) {
        const result = str.match(new RegExp(start + "(.*)" + end));

        return result[1];
      },

      formatKmsToGo(kms) {
        if(kms < 10)
          return kms
        else
          return Math.floor(kms)
      },

      textSocialShareTwitter() {
        let texto = ""
        let textoComentario = this.item.tex[this.$i18n.locale].replaceAll("<br>", "\n").replace(/(<([^>]+)>)/gi, "")
        let maximumCharacters = 280
        let hashtags
        
        if(typeof this.config.getConfig().general.tw_hash !== "undefined")
          hashtags = this.config.getConfig().general.tw_hash.split(",").map(item => "#" + item).join(" ")
        else
          hashtags = ""

        let textoInicio = ""

        if(this.item.km > 0) {
          textoInicio += "🏁 " + this.formatKmsToGo(this.kmToGo(this.item.km)) + " km"
          textoInicio += " "
        }
        
        if(this.config.getConfig().general.tw_hash != "") {
          textoInicio += hashtags
          textoInicio += "\n\n"
        }        

        let textoFinal = ""
        /*textoFinal += "\n\n"
        textoFinal += "🔗 " + window.location.href*/

        if(textoInicio.length + textoComentario.length + textoFinal.length < maximumCharacters)
          texto = textoInicio + textoComentario + textoFinal
        else {
          let lengthTextoComentario = maximumCharacters - textoInicio.length - textoFinal.length - 5
          texto = textoInicio + textoComentario.substring(0, lengthTextoComentario) + "... " + textoFinal
        }          

        return texto
      }
    },

  }
</script>

<style scoped>
  @media (max-width: 768px) {
    .fecha {
      padding-right: 10px !important;
    }

    .titulo {
      padding-left: 10px !important;
    }

    .tweet_embed {
      padding: 0 10px !important;
      width: calc(100% - 20px) !important;
    }
  }

  .container {
    width: 100%;
    border-bottom:1px solid #dbdbdb;
    align-items:center;
    display:flex;
    justify-content: center;
    flex-direction: column;
    background: white;
  }

  .container_commentary {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  .titulo {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;    
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .titulo_right {
    display: flex;
    align-items: center
  }

  .km {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #AAA;
  }

  .icon_social {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .fecha {
    color: #CCC;
    font-size: 12px;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .texto {
    text-align: left;
    font-size: 12px;
    width: 100%;
  }

  .tweet_embed {
    width: 100%;
    margin-bottom: 10px;
  }

  .icon {
    height: 10px;
    margin-right: 3px;
    opacity: 0.2;
  }
  
  .icon_menu {
    fill: #fff;
    margin-right: 5px;
  }  
  
  .icon_menu:hover {
    fill: #eee;
  }

  .options {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .iframe {
    border: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  

</style>

<style>

  @media (max-width: 768px) {
    
    .text_commentary {
      padding: 0 10px !important;
    }

    .img_commentary img {
      border-radius: 0px !important;
    }

  }

  .text_commentary {
    font-size: 12px;
    float:left;
    padding-bottom: 10px !important;
  }    

  .texto a {
    text-decoration: none;
    font-weight: bold;
    color: v-bind(linkcolor);
  }

  .texto a:visited {
    color: v-bind(linkcolor);
  }

  .texto a:hover {
    text-decoration: underline;
  }
 
  .img_commentary {
    padding-bottom: 0px !important;
    cursor: pointer;
  }

  .img_commentary img {
    border-radius: 5px;
    width: 100%;
    max-width: 800px;
  }

  .link_rider {
    font-size: 13px;
    text-decoration: none;
    color: black;
    cursor: pointer;
  }

  .link_rider:hover {
    text-decoration: underline;
  }

</style>