<template>
  <div class="container_dashboard1">
    <div class="container_profile">    
      <Profile 
        v-if="this.map.getProfileData().length > 0"
        ref="perfil"
        class="perfil"
      ></Profile>
    </div>
    <div class="container_bottom">
      <div class="container_bottom_element" style="padding-right: 10px;">
        <SituationMain />
      </div>
      <div class="container_bottom_element">
        <Map v-if="this.map.getProfileData().length > 0" ref="mapa" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useMapStore } from '@/store/map'
  import { useUpdateStore } from '@/store/update'
  import SituationMain from '@/components/SituationMain.vue'
  import Profile from '@/components/Profile.vue'
  import Map from '@/components/Map.vue'

  export default {
    name: 'dashboard1',

    setup() {
      const update = useUpdateStore()
      const map = useMapStore()

      return {
        update,
        map
      }
    },

    updated() {
      this.updateIframeHeight()
    },

    methods: {     
      
      updateIframeHeight() {
        let message = { element: 'helmuga-dashboard1', height: parseInt(document.getElementsByClassName('container_dashboard1')[0].scrollHeight + 20), width: document.getElementsByClassName('container_dashboard1')[0].scrollWidth };	
        window.top.postMessage(message, "*");
      },

      updateComponents() {
        this.$refs.perfil.updatePerfil()
        this.$refs.mapa.updateMapa()
        this.updateIframeHeight()
        //this.$refs.commentary.refresh()
      },
    },

    components: {
      Profile,
      Map,
      SituationMain
    }

  }


</script>


<style scoped>

  .container_situation1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .container_profile {
    width: 100%;
    height: 150px;
  }

  .container_bottom {
    width: calc(100% - 20px);
    display: flex;
    gap: 10px;
    padding: 0 10px;
  }

  .container_bottom_element {
    width: 50%;
  }

</style>