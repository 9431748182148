<template>
  <div>
    <div class="container" v-if="['road', 'itt', 'ttt'].includes(this.config.getConfig().general.type)"> 
      <div class="menu" v-if="!isMobile() && this.config.getConfig().general.type == 'road'">
        <ResultsMenu />
      </div>
      <div class="menu" v-if="!isMobile() && (this.config.getConfig().general.type == 'itt' || this.config.getConfig().general.type == 'ttt')">
        <ResultsMenuLiveTiming />
      </div>
      <div class="main">
        <ResultsList class="results_list" v-if="this.update.getResultsIntermediate().length > 0 && this.getTiempoClasificacion(this.results.getFilterClassification()) == '0' && this.update.getResultsIntermediateFiltered(this.update.getResultsIntermediate(), this.results.getFilterClassification()).length > 0"></ResultsList>
        <ResultsFull class="results_full"></ResultsFull>      
        <div class="livetiming_log"  v-if="this.results.getFilterClassification() < 0">
          <div class="topheader" v-if="typeof getData() !== 'undefined' && getData().length > 0">{{ $t('latest_results') }}</div>
          <div class="header" v-if="typeof getData() !== 'undefined' && getData().length > 0">
            <div class="header_item">{{ $t('point') }}</div>
            <div class="header_item">{{ $t('position') }}</div>
            <div class="header_item" style="text-align:left;">{{ $t('name') }}</div>
            <div class="header_item" style="text-align:right">{{ $t('result') }}</div>
          </div>          
          <div v-if="typeof getData() !== 'undefined'" class="ResultsFullItemLiveTimingLog_nomobile">
            <ResultsFullItemLiveTimingLog
              v-for="(final, index) in getData()"
              :key="index"
              :final="final"
            ></ResultsFullItemLiveTimingLog>
          </div>
          <div v-if="typeof getData() !== 'undefined'" class="ResultsFullItemLiveTimingLog_mobile">
            <ResultsFullItemLiveTimingLog
              v-for="(final, index) in getData().slice(0, 5)"
              :key="index"
              :final="final"
            ></ResultsFullItemLiveTimingLog>
          </div>
        </div>
      </div>    
      <div class="menu_mobile" v-if="isMobile() && this.config.getConfig().general.type == 'road'">
        <ResultsMenu />
      </div>
      <div class="menu_mobile" v-if="isMobile() && (this.config.getConfig().general.type == 'itt' || this.config.getConfig().general.type == 'ttt')">
        <ResultsMenuLiveTiming />
      </div>
    </div>

    <div class="container1" v-if="['popular'].includes(this.config.getConfig().general.type)">
      <ResultsFilter />
      <div class="main">
        <ResultsFull class="results_full"></ResultsFull>      
        <div class="livetiming_log"  v-if="this.results.getFilterClassification() < 0">
          <div class="topheader" v-if="typeof getData() !== 'undefined' && getData().length > 0">{{ $t('latest_results') }}</div>
          <div class="header" v-if="typeof getData() !== 'undefined' && getData().length > 0">
            <div class="header_item">{{ $t('point') }}</div>
            <div class="header_item">{{ $t('position') }}</div>
            <div class="header_item" style="text-align:left;">{{ $t('name') }}</div>
            <div class="header_item" style="text-align:right">{{ $t('result') }}</div>
          </div>
          <div v-if="typeof getData() !== 'undefined'" class="ResultsFullItemLiveTimingLog_nomobile">
            <ResultsFullItemLiveTimingLog
              v-for="(final, index) in getData()"
              :key="index"
              :final="final"
            ></ResultsFullItemLiveTimingLog>
          </div>
          <div v-if="typeof getData() !== 'undefined'" class="ResultsFullItemLiveTimingLog_mobile">
            <ResultsFullItemLiveTimingLog
              v-for="(final, index) in getData().slice(0, 5)"
              :key="index"
              :final="final"
            ></ResultsFullItemLiveTimingLog>
          </div>
        </div>
      </div>    
    </div>

    <div class="container" v-if="['laps'].includes(this.config.getConfig().general.type)">
      <div class="menu" v-if="!isMobile()">
        <ResultsMenuLaps />
      </div>
      <div class="main">
        <ResultsList class="results_list" v-if="this.update.getResultsIntermediate().length > 0 && this.getTiempoClasificacion(this.results.getFilterClassification()) == '0' && this.update.getResultsIntermediateFiltered(this.update.getResultsIntermediate(), this.results.getFilterClassification()).length > 0"></ResultsList>
        <ResultsFull class="results_full"></ResultsFull>      
        <div class="livetiming_log"  v-if="this.results.getFilterClassification() < 0">
          <div class="topheader" v-if="typeof getData() !== 'undefined' && getData().length > 0">{{ $t('latest_results') }}</div>
          <div class="header" v-if="typeof getData() !== 'undefined' && getData().length > 0">
            <div class="header_item">{{ $t('point') }}</div>
            <div class="header_item">{{ $t('position') }}</div>
            <div class="header_item" style="text-align:left;">{{ $t('name') }}</div>
            <div class="header_item" style="text-align:right">{{ $t('result') }}</div>
          </div>          
          <div v-if="typeof getData() !== 'undefined'" class="ResultsFullItemLiveTimingLog_nomobile">
            <ResultsFullItemLiveTimingLog
              v-for="(final, index) in getData()"
              :key="index"
              :final="final"
            ></ResultsFullItemLiveTimingLog>
          </div>
          <div v-if="typeof getData() !== 'undefined'" class="ResultsFullItemLiveTimingLog_mobile">
            <ResultsFullItemLiveTimingLog
              v-for="(final, index) in getData().slice(0, 5)"
              :key="index"
              :final="final"
            ></ResultsFullItemLiveTimingLog>
          </div>
        </div>
      </div>    
      <div class="menu_mobile" style="overflow: auto;" v-if="isMobile()">
        <ResultsMenuLaps />
      </div>
    </div>

  </div>

</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import ResultsMenu from '@/components/ResultsMenu.vue'
  import ResultsList from '@/components/ResultsList.vue'
  import ResultsMenuLiveTiming from '@/components/ResultsMenuLiveTiming.vue'
  import ResultsMenuLaps from '@/components/ResultsMenuLaps.vue'
  import ResultsFilter from '@/components/ResultsFilter'
  import { useResultsStore } from '@/store/results'  
  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'

  export default {
    name: 'LayoutResults',

    setup() {
      const results = useResultsStore()
      const update = useUpdateStore()
      const config = useConfigStore()

      return {
        update,
        results,
        config
      }
    },

    components: {
      ResultsList,
      ResultsMenu,
      ResultsMenuLiveTiming,
      ResultsFilter,
      ResultsMenuLaps,
      ResultsFull: defineAsyncComponent(() => import('@/components/ResultsFull.vue')),      
      ResultsFullItemLiveTimingLog: defineAsyncComponent(() => import('@/components/ResultsFullItemLiveTimingLog.vue')), 
    },

    methods: {
      getData() {

        var tipo_global = this.results.getFilterClassification()
        var filtradoPorTipo = null
        
        if(this.update.getResultsLiveTimingLog().length > 0) {
          return this.update.getResultsLiveTimingLog()
        } else {
          filtradoPorTipo = this.update.getResultsLiveTiming().filter(function(item) {
            return item.raceid == tipo_global
          })
        }
        
        if(typeof filtradoPorTipo[0] !== "undefined")
          return filtradoPorTipo[0].log
        else
          return []
      },

      getTiempoClasificacion(idclasificacion) {
        var filtradoPorTipo = null
        filtradoPorTipo = this.config.getClassifications().filter(function(item) {
          return item.tip == idclasificacion
        })

        if(filtradoPorTipo.length > 0)
          return filtradoPorTipo[0].tie
        else
          return "1"
      },
      
      isMobile() {
        return screen.width < 768
      },
    }
  }
</script>

<style scoped>

  @media (max-width: 768px) {

    .results_list {
      width: 100% !important;
      height: unset !important;
      overflow: unset !important;
      padding-right: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 20px !important;
    }

    .results_full {
      width: 100% !important;
      height: unset !important;
      overflow: unset !important;
      padding-right: 0px !important;
      margin-right: 0px !important;
    }

    .livetiming_log {
      width: 100% !important;
      height: unset !important;
      overflow: unset !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-top: 20px !important;
    }

    .menu {
      width: 100% !important;
    }

    .results_full {
      padding: 0px !important;
    }

    .main {
      flex-direction: column !important;
      overflow-y: auto;
      height: calc(100% - 62px) !important;
      border-bottom: 1px solid #dbdbdb !important
      /*justify-content: flex-end !important;*/
    }

    .container {
      width: calc(100% - 20px) !important;
      overflow: hidden !important;
      justify-content: space-between !important;
    }

    .container1 {
      height: unset !important;
    }

    .ResultsFullItemLiveTimingLog_mobile {
      display:block !important;
      margin-bottom: 20px;
    }

    .ResultsFullItemLiveTimingLog_nomobile {
      display:none !important;
    }
  }

  .container {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .container1 {
    width: calc(100% - 20px);
    height: calc(100% - 90px);
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .menu {
    width: calc(100% - 20px);
    height: 50px;
    margin-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
  }

  .main {
    width: 100%;
    height: calc(100% - 92px);
    display: flex;
    flex-direction: row;
  }

  .results_list {
    width: 50%;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: 10px;
  }

  .results_full {
    width: calc(50% - 50px);
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
  }

  .livetiming_log {
    width: 50%;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .rider_info{
    width: 50%;
  }

  .topheader {
    font-size: 11px;
    padding: 10px;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-top: 1px solid #dbdbdb;
    text-transform: uppercase;
    background: #fafafa;
    font-weight: bold;
  }

  .header {
    display: grid;
    align-items: center;
    grid-template-columns: 0.1fr 0.2fr 1fr 0.2fr;
    padding: 6px 10px;
    font-size: 14px;
    width: calc(100% - 22px); 
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-top: 1px solid #dbdbdb;
  }

  .header_item {
    text-align: center;
    font-size: 10px;
    text-transform: lowercase;
    color: #AAA;
  }  

  .ResultsFullItemLiveTimingLog_mobile {
    display:none;
  }

  .menu_mobile {
    height: 50px;
  }


</style>

