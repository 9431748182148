<template>

  <div class="separator" style="margin-top: 0px;padding-top: 40px;">{{ this.getGroupName() }}</div>

  <div v-if="this.getTeams().length > 0">
    <div class="separator" style="margin-top: 10px;">{{ $t('teams') }}</div>
    <SituationGroupInfoTeam
      v-for="(team, index) in this.getTeams()"
      :key="index"
      :team="team"
    ></SituationGroupInfoTeam>
  </div>

  <div v-if="this.getAge().length > 0">
    <div class="separator">{{ $t('age') }}</div>
    <SituationGroupInfoAge
      v-for="(rider, index) in this.getAge()"
      :key="index"
      :rider="rider"
    ></SituationGroupInfoAge>
  </div>

  <div v-if="this.getCountries().length > 0">
    <div class="separator">{{ $t('countries') }}</div>
    <SituationGroupInfoCountry
      v-for="(country, index) in this.getCountries()"
      :key="index"
      :country="country"
    ></SituationGroupInfoCountry>
  </div>
  
</template>

<script>
  import { useUpdateStore } from '@/store/update'
  import { usePagesStore } from '@/store/pages'
  import { useStartlistStore } from '@/store/startlist'
  import SituationGroupInfoCountry from '@/components/SituationGroupInfoCountry.vue'  
  import SituationGroupInfoTeam from '@/components/SituationGroupInfoTeam.vue'  
  import SituationGroupInfoAge from '@/components/SituationGroupInfoAge.vue'  

  export default {
    name: 'situationgrouinfo',

    setup() {
      const update = useUpdateStore()
      const pages = usePagesStore()
      const startlist = useStartlistStore()

      return {
        update,
        pages,
        startlist
      }
    },

    methods: {

      getAge() {
        let idgroup = this.pages.getSelectedSituationGroup()
        return this.update.getSituation().filter((item, index) => item.idg === idgroup)[0].age
      },

      getCountries() {
        let idgroup = this.pages.getSelectedSituationGroup()
        return this.update.getSituation().filter((item, index) => item.idg === idgroup)[0].cou
      },

      
      getTeams() {
        let idgroup = this.pages.getSelectedSituationGroup()
        let array_teams = this.update.getSituation().filter((item, index) => item.idg === idgroup)[0].tea

        let return_array = []
        let team = {}

        array_teams.forEach(element => {
          
          let team_name = element.nom
          let total = element.tot

          team = this.startlist.getTeams().filter((item, index) => item.nom === team_name)[0]
          team.tot = total

          return_array.push(team)
          
        })

        return return_array
      },

      getGroupName() {
        let id = this.pages.getSelectedSituationGroup()
        if(id < 2)
          return this.$t('group_names[' + parseInt(id) + ']')
        else {
          return this.$t('group_names[2]')
        }
          
      },
      
      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },
    },

    components: {
      SituationGroupInfoCountry,
      SituationGroupInfoTeam,
      SituationGroupInfoAge
    }

  }

</script>

<style scoped>

  .separator {
    display: flex !important;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 20px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }


</style>