<template>  
  <div>
    <TransitionGroup name="list" tag="List3Item">
      <List3Item
        v-for="(final, index) in this.getData()"
        :key="index"
        :final="final"
      ></List3Item>
    </TransitionGroup>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { useTvStore } from '@/store/tv'
  import List3Item from '@/components/tv/List3Item.vue'

  export default {
    name: 'tv-list2',

    data() {
      return {
        elementosporpagina: 25,
        tiempopagina: 5000,
        elementos: 0,
        start: 0
      }
    },

    setup() {
      const startlist = useStartlistStore()
      const tv = useTvStore()

      return {
        startlist,
        tv
      }
    },

    methods: {
      getData() {
        return this.startlist.getStartlist().slice(this.start, this.elementos)
      },

      async init() {
        let pages = Math.floor(this.startlist.getStartlist().length / this.elementosporpagina)

        for(var j=0; j <= pages; j++) {
          for(var i=0; i < this.elementosporpagina; i++) {
            await this.sleep(50)
            this.elementos++
          }

          await this.sleep(this.tiempopagina)
          this.start = this.elementos
        }

        this.tv.data.tvShowColumn = false
      },

      sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      },
    },

    components: { 
        List3Item,
    },

  }
</script>

<style scoped>

  .list-enter-active, .list-leave-active {
    transition: all 0.1s ease;
  }
  .list-enter-from, .list-leave-to {
    opacity: 0;
    transform: translateX(-30px);
  }

</style>