<template>
  <div class="container_resultswidget_item" :class="{ resultswidget_full: this.pages.getCategory() == ''}">
    <div v-if="hayResultadosVirtuales() || hayResultadosFinales()" class="container_virtual">
      <div class="title" v-if="this.startedRace() && !hayResultadosFinales()">
          <div class="img_category_container" v-if="getTipo().thumb"><img class='img_category' :src="getTipo().thumb"></div>
          <div class="title_text">
            <div class="point_title">{{ getNombreClasificacion() }}</div>
            <div class="point_subtitle">{{ $t('virtual_classification') }}</div>
          </div>
      </div>

      <div class="title" v-if="this.startedRace() && hayResultadosFinales()">
          <div class="img_category_container" v-if="getTipo().thumb"><img class='img_category' :src="getTipo().thumb"></div>
          <div class="title_text">
            <div class="point_title">{{ getNombreClasificacion() }}</div>
            <div class="point_subtitle">{{ $t('final_classification') }}<br>
              <span v-if="this.getData().off == '0'">{{ $t('provisional') }}</span>
              <span v-if="this.getData().off == '1'">{{ $t('official') }}</span>
            </div>
          </div>
      </div>

      <div class="title" v-if="!this.startedRace()">
          <div class="img_category_container" v-if="getTipo().thumb"><img class='img_category' :src="getTipo().thumb"></div>
          <div class="title_text">
            <div class="point_title">{{ getNombreClasificacion() }}</div>
            <div class="point_subtitle">{{ $t('start_classification') }}</div>
          </div>
      </div>

      <div class="list">
        <ResultsWidgetItem
          v-for="(final, index) in getData().riders"
          :key="index"
          :final="final"
        ></ResultsWidgetItem>
      </div>
      
    </div>
    <div v-if="!hayResultadosVirtuales() && !hayResultadosFinales()"  class="title" style="border: 1px solid #dbdbdb;justify-content: center;padding: 20px;">
      {{ $t('no_results_available') }}
    </div>

  </div>
</template>

<script>
  import { useUpdateStore } from '@/store/update'
  import { usePagesStore } from '@/store/pages'
  import { useResultsStore } from '@/store/results'
  import { useConfigStore } from '@/store/config'
  import ResultsWidgetItem from '@/components/ResultsWidgetItem'

  export default ({

    props: {
      key: Number,
      final: Object,
    },

    setup() {
      const update = useUpdateStore()
      const pages = usePagesStore()
      const results = useResultsStore()
      const config = useConfigStore()

      return {
        update,
        results,
        config,
        pages
      }
    },

    components: {
      ResultsWidgetItem
    },    

    methods: {

      hayResultadosVirtuales() {
        var tipo_global

        if(this.pages.getCategory() == '')
          tipo_global = this.final.tip
        else
          tipo_global = this.pages.getCategory()

        var filtradoPorTipo = this.update.getResultsVirtual().filter(function(item) {
          return item.tip == tipo_global
        })

        if(typeof filtradoPorTipo[0] !== "undefined" && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false
      },
      
      hayResultadosFinales() {

        var tipo_global

        if(this.pages.getCategory() == '')
          tipo_global = this.final.tip
        else
          tipo_global = this.pages.getCategory()

        var filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
          return item.tip == tipo_global
        })

        if(typeof filtradoPorTipo[0] !== "undefined" && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false

      },

      getData() {
        var tipo_global

        if(this.pages.getCategory() == '')
          tipo_global = this.final.tip
        else
          tipo_global = this.pages.getCategory()

        var filtradoPorTipo

        if(this.startedRace()) {
          if(this.hayResultadosFinales()) {
            filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
              return item.tip == tipo_global
            })
          }
          else {
            filtradoPorTipo = this.update.getResultsVirtual().filter(function(item) {
              return item.tip == tipo_global
            })
          }
        } else {
            filtradoPorTipo = this.update.getResultsStart().filter(function(item) {
              return item.tip == tipo_global
            })
        }

        if(typeof filtradoPorTipo[0] !== "undefined") {
          return filtradoPorTipo[0]
        } else {
          return {riders:[]}
        }

      },

      getTipo() {
        var tipo_global

        if(this.pages.getCategory() == '')
          tipo_global = this.final.tip
        else
          tipo_global = this.pages.getCategory()

        var item_seleccionado = this.config.getConfig().classifications.filter(function(item) {
          if(item.tip == tipo_global)
            return item
        })

        return item_seleccionado[0]
      },

      getNombreClasificacion() {
        if(typeof this.getTipo().langs[this.$i18n.locale] !== 'undefined')
          return this.getTipo().langs[this.$i18n.locale]
        else
          return this.$t('ranking_' + this.getTipo().tip)
      },

      startedRace() {
        return this.update.getUpdate().situation[0].km > 0 || this.update.getResultsLiveTiming().length > 0
      },     
    }

    
  })

</script>

<style scoped>

  .resultswidget_full {
    margin-bottom: 20px;
    height: 369px;
    overflow: auto;
    border-bottom: 1px solid #ddd;
  }

  .container_virtual {
    display: flex;
    flex-direction: column;
  }

  .container_classification_name {
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    padding: 0 10px;
    font-size: 14px;
    background: #fafafa;
    border-top: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-transform: uppercase;
  }
   
  .title_logo {
    height: 40px;
    opacity: 0.5;
  } 

  .title_logo img {
    height: 40px;
  }

  .title_text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .point_title {
    text-transform: uppercase;
  }
  
  .show {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    text-transform: uppercase;
    font-size: 12px;
    justify-content: center;
    background: #fafafa;
    color: #999;
    border-bottom: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    cursor: pointer;
    transition: 0.3s;
  }

  .show:hover {
    background: #FFF;
  }
  
  .show_top {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 10px;
    font-size: 12px;
    color: #999;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    transition: 0.3s;
  }

  .show:hover {
    background: #FFF;
  }

  .number_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .number {
    font-size: 25px;
    color: #333;
  }

  .point_subtitle {
    font-size: 10px;
    color: #bbb;
    text-align: right;
  }

  .img_category {
    height: 18px;
    padding: 10px 10px 10px 0px;
  }

  .img_category_time {
    height: 20px;
    padding: 10px 10px 10px 0px;
  }

  .laps {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .laps_number {
    font-size: 16px;
  }

  .laps_text {
    font-size: 11px;
    font-weight: normal;
  }

  .img_category_container {
    display: flex;
    align-items: center;
  }

</style>