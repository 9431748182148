import { defineStore } from 'pinia'
import mapAPI from "@/api/map"


export const useMapStore = defineStore({
  id: "mapStore",
    state: () => ({
      data: [],
      profile_data: [],
    }),

    actions: {

      getMap() {
        return this.data
      },

      getProfileData() {
        return this.profile_data
      },

      setProfileData(data) {
        this.profile_data = data
      },
      
      getMapSegment(start, finish) {
        const clone_obj = { ...this.getMap()[0] }        
        var new_segment_coordinates = clone_obj.coordinates.slice(start, finish)
        clone_obj.coordinates = new_segment_coordinates
        
        return clone_obj
      },

      setMap(datos) {

        this.data = datos
        this.profile_data = datos[2]

        let coordinates_data = datos[0]['coordinates']
        
        var puntos = [];

        for(var i=0;i<coordinates_data.length;i++)
        {
          var x = i;
          var y = coordinates_data[i][2];
          
          puntos.push([x, y]);
        }
      },

      fetchMap(datos) {
        return new Promise((resolve, reject) => {            
          mapAPI.getMap(datos, resolve)
        })
      },

      updateMap(datos) {         
        this.fetchMap(datos)
        .then((data) => {
          this.setMap(data)
        })        
      },
      
  }
});
