<template>  
  <div>
    <RaceHistoryItem
      v-for="(final, index) in this.config.data.stats.lastwinners.slice(0, 10)"
      :key="index"
      :final="final"
    ></RaceHistoryItem>

    <div v-if="!this.showLess">
      <RaceHistoryItem
        v-for="(final, index) in this.config.data.stats.lastwinners.slice(10)"
        :key="index"
        :final="final"
      ></RaceHistoryItem>
    </div>

    <div v-if='this.config.data.stats.lastwinners.length > 10 && this.showLess' class="show_more" @click="this.showLess = !this.showLess">{{ $t('show_more') }}</div>
    <div v-if='!this.showLess' class="show_more" @click="this.showLess = !this.showLess">{{ $t('show_less') }}</div>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'
  import RaceHistoryItem from '@/components/RaceHistoryItem.vue'

  export default {
    name: 'racehistory',

    setup() {
      const config = useConfigStore()

      return {
        config
      }
    },
    
    data () {
      return { 
        showLess: true
      }
    },

    components: { 
        RaceHistoryItem,
    },

  }
</script>

<style scoped>

  .show_more {
    display: flex;
    align-items: center;
    padding: 12px 10px;
    font-size: 12px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
    text-transform: uppercase;
    font-weight: bold;
    justify-content: center;
    cursor: pointer;
  }

  .show_more:hover {
    background: #fafafa;
  }

</style>