import { defineStore } from 'pinia'
import updateAPI from "@/api/update"


export const useUpdateStore = defineStore({
  id: "updateStore",

    state: () => ({
      data: {
        general: {},
        situation: [
          {
            riders: [],
            idg: "",
            ngr: "",
            nco: "",
            sgr: ""
          }
        ],
        commentary: [],
        results: {
          intermediate: [],
          start: [],
          final: [],
          virtual: [],
          livetiming: [],
          livetiming_log: [],
          riders_start: [],
          riders_virtual: [],
          riders_final: [],
          dnf: []
        }
      },      
      internal: {
        last_commentary_id: 0
      }
    }),

    actions: {

      getUpdate() {
        return this.data
      },     
      
      getGeneral() {
        return this.data.general
      },
      
      getSituation() {
        return this.data.situation
      },

      getResultsRidersStart() {
        return this.data.results.riders_start
      },

      getResultsRidersVirtual() {
        return this.data.results.riders_virtual
      },

      getResultsRidersFinal() {
        return this.data.results.riders_final
      },

      getResultsStart() {
        return this.data.results.start
      },

      getResultsIntermediate() {
        return this.data.results.intermediate
      },

      getResultsIntermediateFiltered(results, tipo) {
        var filtradoPorTipo = results.filter(function(item) {
          return item.tip == tipo
        })
        return filtradoPorTipo
      },

      getResultsVirtual() {
        return this.data.results.virtual
      },

      getResultsFinal() {
        return this.data.results.final.filter((item) => item.cat === "2")
      },

      getResultsLiveTiming() {
        return this.data.results.livetiming
      },

      getResultsLiveTimingLog() {
        return this.data.results.livetiming_log
      },

      getDNF() {
        return this.data.results.dnf
      },

      setUpdate(datos) {
        this.data = datos
      },

      setLastCommentaryId(id) {
        this.internal.last_commentary_id = id
      },

      getLastCommentaryId() {
        return this.internal.last_commentary_id
      },

      fetchUpdate(datos) {
        return new Promise((resolve, reject) => {
          updateAPI.getUpdate(datos, resolve)
        })
      },

      updateUpdate(datos) {    
        this.fetchUpdate(datos)
        .then((data) => {
          this.setUpdate(data)
        })        
      }
  }
});
