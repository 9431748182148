<template>
  <div class="container">
    <div v-if="this.update.getUpdate().general.pause > 0" class="separator_winner">{{ $t('race_paused') }}</div>
    <div class="container_texto">
        <span>{{ tiempoCarrera() }}</span>
        <span>{{ this.update.getUpdate().situation[0].km.toFixed(1) }} km {{ $t('covered') }}</span>
        <span>{{ velocidadMedia() }} km/{{ $t('h') }}</span>
    </div>
    <div class="linea">
      <div class="linea_kms" style="width: 40%;" :style="stylelinea"></div>
      <div class="linea_kms_totales"></div>
    </div>
  </div>
</template>



<script>

  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'

  export default {
    name: 'situation-summary',

    setup() {
      const update = useUpdateStore()
          const config = useConfigStore()

      return {
        update,
        config
      }
    },

    methods: {
      tiempoCarrera() {
        var ts_start = this.update.getUpdate().general.started
        var ts_now = Date.now()

        return this.aMinSegundos(ts_now - ts_start)
      },

      aMinSegundos(milisegundos) {
        const value = milisegundos / 1000;
        const sec = parseInt(value, 10); 
        let hours   = Math.floor(sec / 3600); 
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60); 
        
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        
        return hours + this.$t('h') + minutes + 'm';
      },

      velocidadMedia() {

        return this.update.getUpdate().general.average_speed

        /*var ts_start = this.update.getUpdate().general.started
        var ts_now = Date.now()
        var ts_carrera = ts_now - ts_start
        var kms_recorridos = this.update.getUpdate().situation[0].km

        return (kms_recorridos / (ts_carrera / 1000 / 3600)).toFixed(1)*/
      }
    },

    computed: {
      stylelinea() {

        var kms_recorridos = this.update.getUpdate().situation[0].km
        var kms_totales = this.config.getConfig().general.distance

        var porcentaje = (kms_recorridos / kms_totales) * 100

        return {
          "background":  `${this.config.getConfig().general.color1}`,
          "width": porcentaje + "%"
        }
      },
    },

  }

  

</script>


<style scoped>

  @media (max-width: 768px) {

    .container_texto {
      padding: 0 !important;
    }

  }

  .container {
    width: 100%;
    margin-bottom: 15px;
  }

  .container_texto {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 3px;
  }

  .separator {
    margin-left: 5px;
    margin-right: 5px;
  }

  .texto_izda {
    display: flex;
  }

  .texto_dcha {
    display: flex;
    margin-left: auto;
  }

  .linea {
    position: relative;
  }

  .linea_kms {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    z-index: 1;    
    border-radius: 8px;
  }

  .linea_kms_totales {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #dbdbdb;
    border-radius: 8px;
  }

  .separator_winner {
    display: flex;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 25px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

</style>