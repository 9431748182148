<template>
  <div class="container_raceinfo">
    <span class="thumb" v-if="type === 'advanced'"><img :src="this.config.getConfig().general.race_thumb"></span>
    <div class="full_racename" v-if="fullracename === '1'">
      <span class="racename">{{ this.config.getConfig().general.name }} {{ this.config.getConfig().general.year }}</span>
      <span class="stage" v-if="this.config.getConfig().general.stage">S{{ this.config.getConfig().general.stage }}</span>
    </div>
    <div class="full_racename" v-if="fullracename === '0'">
      <span class="racename">{{ this.config.getConfig().general.name }}</span>
    </div>
  </div>
</template>

<script>
  
  import { useConfigStore } from '@/store/config'

  export default {
    name: 'tv-raceinfo',
    
    props: ['type', 'fullracename'],

    setup() {
      const config = useConfigStore()

      return {
        config,
      }
    },


  }

</script>

<style scoped>

  .container_raceinfo {
    display: flex;
    align-items: center;
    height: 30px;
    color: #999;
    margin-bottom: 20px;
    width: 100%;
  }

  .thumb {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }

  .thumb img {
    height: 50px;
  }

  .full_racename {
    display: flex;
    border-top: 2px solid #FFEB01;
    padding-top: 5px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .racename {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 16px;
  }

  .stage {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 16px;
  }

</style>