<template>
  <div class="container">
    <div class="container_title">
      <div class="thumb" v-if="this.config.getConfig().general.race_thumb"><img :src="this.config.getConfig().general.race_thumb"></div>
      <div class="name">
        <div class="title">{{ this.config.getConfig().general.name }}</div>
        <div class="subtitle" v-if="this.config.getConfig().general.city_start">
          <span class='stage' v-if="this.config.getConfig().general.stage != 'NA' && this.config.getConfig().general.stage != ''">
            {{ $t('stage') }} {{ this.config.getConfig().general.stage }} | 
          </span>
          {{ this.config.getConfig().general.city_start }} - {{ this.config.getConfig().general.city_finish }}
        </div>
      </div>
    </div>
    <div class="icon_menu" style="height:24px" v-html="iconData('info')" @click="abrirPopup()" v-if="typeof this.config.getConfig().stats !== 'undefined'"></div>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'race-title',

    setup() {
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        config,
        pages
      }
    },
    
    methods: {

      abrirPopup() {
        this.pages.setPopupType('race')
        this.pages.setVisiblePopup(true)
      },

      
      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {

          case "info":
            return '<svg class="icon_menu_header" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6.01953v-.01953"/><path d="m12 10v8"/></g></svg>'

          default:
            break;
        }
        
      },

      strokeColorHex() {
        return '#ccc'
      },
    }

  }
</script>

<style scoped>

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container_title {
    display: flex;
    align-items: center;
  }

  .thumb {
    display: flex;
  }

  .thumb img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    margin-right: 10px;
    border: 1px solid #DBDBDB;
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
  }

  .subtitle {
    color: #555;
    font-size: 11px;
  }

</style>

<style>

  .icon_menu_header {
    fill: #fafafa;
  }

</style>