<template>
  <div class="container_freetext">
    <div class="panel_freetext" :class="{ panel_freetext_enter: enter, panel_freetext_leave: leave }">
      <div class="text_freetext" :class="{ text_freetext_enter: enter, text_freetext_leave: leave }">{{ this.text }}</div>
    </div>
  </div>
</template>


<script>

  import { useTvStore } from '@/store/tv'

  export default {
    name: 'freetext',

    setup() {
      const tv = useTvStore()

      return {
        tv,
      }
    },

    data: function() {
      return {
        text: '',
        enter: false,
        leave: false
      }
    },

    methods: {

      showPanel(text) {
        this.text = text
        this.enter = true
        this.leave = false
      },

      hidePanel() {
        this.enter = false
        this.leave = true
      }

    }

  }

</script>

<style scoped>

  .container_freetext {
    position: absolute;
    left: 75px;
    bottom: 75px;
    font-size: 25px;
  }

  .panel_freetext {
    position: relative;
    overflow: hidden;
  }

  .panel_freetext_enter::before {
    animation: entrar 0.75s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #111;
    transform: translateX(-101%);
    transition-property: transform;
    z-index: 99;
  }

  .panel_freetext_leave::before {
    animation: salir 0.75s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #111;
    transform: translateX(-101%);
    transition-property: transform;
    z-index: 99;
  }
  
  .text_freetext {
    position: relative;
    background-color: white;
    color: #111;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .text_freetext_enter {    
    animation: mostrar 0.75s;
    padding: 10px 20px;
  }
  
  .text_freetext_leave {    
    animation: ocultar 0.75s;
    padding: 10px 20px;
    opacity: 0;
  }


  @keyframes entrar {
    0% {
      transform: translateX(-100%);
    }

    50% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  @keyframes salir {
    0% {
      transform: translateX(100%);
    }

    50% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes mostrar {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    51% {
      opacity: 1;
    }
  }

  @keyframes ocultar {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 1;
    }

    51% {
      opacity: 0;
    }
  }


</style>
