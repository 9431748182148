<template>
  <div>    
    <div class="contenedor_countdown" v-if="this.timeStartDifference() < 21600000">
      <vue-countdown :time="this.timeStartDifference()" v-slot="{ hours, minutes, seconds }">
        <div class="countdown">
          <span style="height: 20px;margin-right: 5px;"><img src="https://tracker.helmuga.cloud/images/start.png" style="height: 20px"></span>
          <span style="display: flex;align-items: center;">
            <span style="font-weight: bold; margin-right: 5px;">{{ this.timeStartText(hours, minutes, seconds) }}</span>
            <span style="text-transform: uppercase;color: #00913c; font-weight: bold;">{{ $t('to_start') }}</span>
          </span>
        </div>
      </vue-countdown>
    </div>
    <div class="contenedor">
      <div class="contenedor_datos">        
        <div class="izda">
          <div class="stage" v-if="this.config.getConfig().general.stage !== 'NA'">{{ $t('stage') }} {{ this.config.getConfig().general.stage }}</div>
          <div class="city"><div class="icon_city"><img src='https://tracker.helmuga.cloud/images/start.png'></div><div class="label_city">{{ this.config.getConfig().general.city_start }}</div></div>
          <div class="city"><div class="icon_city"><img src='https://tracker.helmuga.cloud/images/finish.png'></div><div class="label_city">{{ this.config.getConfig().general.city_finish }}</div></div>
        </div>
        <div class="dcha">
          <span>{{ dia(update.getGeneral().started).nombre }}</span>
          <span>{{ dia(update.getGeneral().started).date }}</span>
          <span>{{ hora(update.getGeneral().started) }}</span>
          <span>{{ config.getConfig().general.distance_official }} km</span>
        </div>
      </div>
    </div>
    <div class="separator" v-if="this.showSummaryMaillots()">{{ $t('leaders') }}</div>
    <SummaryMaillots v-if="this.showSummaryMaillots()" />
  </div>
</template>


<script>

  import { useConfigStore } from '@/store/config'  
  import { useTimetableStore } from '@/store/timetable'  
  import { useUpdateStore } from '@/store/update'  
  import SummaryMaillots from '@/components/SummaryMaillots'
  import moment from 'moment'  

  export default {
    name: 'situationnotstarted',

    setup() {
      const config = useConfigStore()
      const update = useUpdateStore()
      const timetable = useTimetableStore()

      return {
        config,
        update,
        timetable
      }      
    },
    
    components: {
      SummaryMaillots
    },

    methods: {

      timeStartText(hours, minutes, seconds) {

        let hours2 = hours < 10 ? "0" + hours : hours
        let minutes2 = minutes < 10 ? "0" + minutes : minutes
        let seconds2 = seconds < 10 ? "0" + seconds : seconds

        return hours2 + ":" + minutes2 + ":" + seconds2
      },

      timeStartDifference() {
        return this.update.getGeneral().started - Date.now()
      },

      showSummaryMaillots() {        
        if(typeof this.update.getResultsRidersStart().length === 'undefined' || typeof this.update.getResultsRidersVirtual().length === 'undefined')
          return true
        else
          return false
      },

      dia: function (date) {
        var fecha = {}
        var code = this.$i18n.locale == 'cz' ? 'cs' : this.$i18n.locale
        moment.locale(code)

        switch(code) {
          case "eu":
            fecha.nombre = moment(parseInt(date)).format('dddd')
            fecha.date = moment(parseInt(date)).format('YYYY\\ko MMMM\\k D')
          break

          case "es":
            fecha.nombre = moment(parseInt(date)).format('dddd')
            fecha.date = moment(parseInt(date)).format('D \\d\\e MMMM YYYY')
          break
          
          default:
            fecha.nombre = moment(parseInt(date)).format('dddd')
            fecha.date = moment(parseInt(date)).format('D MMMM YYYY')
          break
        }
        
        return fecha

      },

      hora: function (date) {
        moment.locale(this.$i18n.locale)
        let formatString = 'HH:mm[' + this.$t('h') + ']'
        return moment(parseInt(date)).format(formatString);
      },

      getCities() {

        if(this.config.getConfig().general.city_start == this.config.getConfig().general.city_finish)
          return this.config.getConfig().general.city_start
        else
          return this.config.getConfig().general.city_start + " - " + this.config.getConfig().general.city_finish
      },

      sportsTriathlon() {
        var timetable_data = this.timetable.getTimetable().data
        var html = ""
        
        if(typeof timetable_data === "undefined")
          return ""

        for(var i=0; i<timetable_data.length; i++) {
          var start_sport = parseFloat(timetable_data[i].cat)
          var finish_sport = parseFloat(timetable_data[i].km)
          var distance_sport = finish_sport - start_sport

          html += "<div class='linea'>"
          html += "<div class='thumb'><img src='https://tracker.helmuga.cloud/resources/icons/sport-" + (i + 1) + ".png'></div>"
          html += "<div class='titulo'>" + distance_sport + " km</div>"
          html += "</div>"
        }

        return html

      }

    },

  }

</script>


<style scoped>

  @media (max-width: 768px) {
    .linea {
      justify-content: center !important;
    }

    .separator {
      display: flex !important;
    }

    .contenedor {
      margin-left: 10px !important;
      margin-bottom: 10px !important;
      margin-right: 10px !important;
      margin-top: 10px !important;
    }

    .contenedor_countdown {
      margin-top: 10px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }

  .contenedor {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    border: 1px solid #dbdbdb;
    padding: 10px;
    background: #fafafa;
    margin-top: 10px;
  } 
 

  .contenedor_countdown {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    border-top: 2px solid #00913c;
    background: #fafafa;
    padding: 5px 10px;
    color: black;
    margin-top: 10px;
  } 

  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  .city {
    letter-spacing: 1px;
    display: flex;
    align-items: center;
  }

  .icon_city {
    height: 14px;
  }

  .icon_city img {
    height: 14px;
    margin-right: 5px;
  }

  .linea {
    display: flex;
    width: 100%;
    margin: 5px 0;
  }

  .titulo {
    display: flex;
  }

  .thumb {
    margin-right: 8px;
  }

  .thumb img {
    height: 15px;
    opacity: 0.5;
  }

  .contenedor_datos {
    display: flex;
    justify-content: space-between;
  }

  .stage {
    color: #aaa;
    font-size: 12px;
  }

  .separator {
    display: none;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

  .izda {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
  }

  .izda_weather {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    border-right: 1px solid #dbdbdb;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dcha {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: right;    
    justify-content: center;
  }

</style>


<style>

  @media (max-width: 768px) {
    .situationnotstarted_sport .linea {
      justify-content: center !important;
    }
  }

  .situationnotstarted_sport {
    margin: 0;
  }

  .situationnotstarted_sport .linea {
    display: flex;
    width: 100%;
    margin: 5px 0;
  }

  .situationnotstarted_sport .titulo {
    display: flex;
  }

  .situationnotstarted_sport .thumb {
    margin-right: 8px;
  }

  .situationnotstarted_sport .thumb img {
    height: 15px;
  }

</style>