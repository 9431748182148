<template>
  <div class="container" @click="clickCountry" >
    <div class="bib">{{ country.cou }}</div>
    <div class="country"><img :src="require(`@/assets/flags/${country.cou}.png`)" /></div>
    <div class="name">{{ nombrePais(country.cou.toUpperCase()) }}</div>
    <div class="number">{{ country.tot }}</div>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'ridercountryitem',

    props: {
        id: Number,
        country: Object
    },

    setup() {
      const startlist = useStartlistStore()
      const pages = usePagesStore()

      return {
        startlist,
        pages
      }
    },

    methods: {
      nombrePais(codigo) {

        if(codigo == "CC")
          codigo = "CA"

        let regionNames = new Intl.DisplayNames([this.$i18n.locale], {type: 'region'})
        return regionNames.of(codigo)
      },

      clickCountry() {        
        
        this.startlist.setSelectedCountry(this.country.cou)

        this.pages.setPopupType('ridercountry')

        if(this.isMobile()) {
          this.pages.setVisiblePopup(true)
        }
      },  
      
      isMobile() {
        return screen.width < 768
      },

    }

  }

</script>

<style scoped>

  
  .container {
    display: grid;
    grid-template-columns: 50px 50px auto 50px;
    align-items: center;
    height: 30px;
    padding: 12px 6px;
    font-size: 14px;
    background: #FAFAFA;
    cursor: pointer;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }

  .container:first-child {
    border-top: 1px solid #dbdbdb;
  }

  .bib {
    text-align: center;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bold;
    color:#777;
    text-transform: uppercase;
  }
  
  .country{
    height:15px;
    margin-left: 10px;
  }

  .country img {
    height:13px;    
    border: 1px solid #eee;
  }
  
  .name {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .number {
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

</style>