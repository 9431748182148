<template>
  <div class="image_detail_container" @click="closeImageDetail()">
    <div class='close' @click="closePanel()">×</div>
    <div class="image_container">
      <div class="text" v-if="this.pages.getImageDetailText() !== ''" v-html="this.pages.getImageDetailText()"></div>
      <img class="image_detail_image" :src="this.pages.getImageDetail()">
    </div>
  </div>
</template>

<script>

import { usePagesStore } from '@/store/pages'
import { useUpdateStore } from '@/store/update'

export default {
  name: 'image_detail',

  setup() {
    const pages = usePagesStore()
    const update = useUpdateStore()

    return {
      pages,
      update,
    }
  },

  methods: {
    closeImageDetail() {
      this.pages.setImageDetail('')
      this.pages.setImageDetailText('')
    },
  }

}

</script>

<style scoped> 

  .image_detail_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image_detail_image {
    width: 100%;
    border-radius: 5px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    color: #CCC;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

  .image_container {
    position: relative;
    max-height: 100%;
  }

  .text {
    position: absolute;
    background: rgba(0, 0, 0, 0.95);
    color: #ccc;
    bottom: 0;
    right: 0;
    max-width: 300px;
    padding: 20px;
    font-size: 12px;    
    max-height: 200px;
    overflow: auto;
    border-top-left-radius: 10px;
  }

</style>