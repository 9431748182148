<template>
  <div class="container_top10">
    <div class="title">STARTLIST</div>
    <RaceInfo type="simple" fullracename="1" />
    <List3 ref="list3" />
  </div>

</template>

<script>

  import List3 from '@/components/tv/List3.vue'
  import RaceInfo from '@/components/tv/RaceInfo.vue'  

  export default {
    name: 'tv-startlist',

    mounted() {
      this.$refs.list3.init()
    },

    components: { 
      List3,
      RaceInfo
    },

  }
</script>

<style scoped>

  .title {
    font-size: 30px;
    letter-spacing: 5px;
    font-weight: bold;
    height: 35px;
    color: white;
  }

</style>