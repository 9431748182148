<template>
  <div class="contenedor_datos_weather">    
    <div class="contenedor_temperature_weather">
      <span class="temperature">{{ this.getWeather().data.main.temp.toFixed(0) }} ºC</span>
      <span><span class="max">max</span> {{ this.getWeather().data.main.temp_max.toFixed(0) }}º</span>
      <span><span class="min">min</span> {{ this.getWeather().data.main.temp_min.toFixed(0) }}º</span>
    </div>        
    <div class="contenedor_wind_weather">
      <span class="wind_text">{{ this.windDirection() }}</span>
      <span class="wind_speed">{{ (this.getWeather().data.wind.speed * 3.6).toFixed(0) }} km/h</span>
    </div>
    <div class="contenedor_name_weather">
      <div class="contenedor_icon_weather">
        <span class="icon_weather"><img :src='imgWeather(this.getWeather().data.weather[0].icon)'></span>
      </div>
      <span>{{ $t('weather_' + this.getWeather().data.weather[0].main.toLowerCase()) }}</span>
    </div>
  </div>
</template>

<script>

  import { useUpdateStore } from '@/store/update'

  export default {
    name: 'weather',

    setup() {
      const update = useUpdateStore()

      return {
        update
      }
    },

    props: {
      point: String
    },

    methods: {
      
      imgWeather(icon) {
        return 'https://openweathermap.org/img/wn/' + icon + '@2x.png'
      },

      getWeather() {

        let data

        //console.log(this.point)

        switch(this.point) {

          case "start":
            data = this.update.getGeneral().weather[0]
          break

          case "finish":
            data = this.update.getGeneral().weather[this.update.getGeneral().weather.length - 1]
          break

          default:
            data = this.update.getGeneral().weather.filter((item) => item.km === this.point)[0]

            if(typeof data === 'undefined')
              data = {}
          break

        }

        return data
      },

      windDirection() {

        let val = parseInt((this.getWeather().data.wind.deg/22.5) + 0.5)
        let arr = ["N","NNE","NE","ENE","E","ESE", "SE", "SSE","S","SSW","SW","WSW","W","WNW","NW","NNW"]
        
        return arr[val % 16]
      }
    }

  }


</script>


<style scoped>

  .contenedor_datos_weather {
    display: flex;
    align-items: center;
    padding: 12px 10px;
    font-size: 12px;
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
    justify-items: center;
  }

  .contenedor_datos_weather > div{
    flex-basis: 0;
    flex-grow: 1;
  }

  .contenedor_icon_weather {
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    margin-bottom: 5px;
  }

  .contenedor_name_weather {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: bold;
    flex-direction: column;
  }

  .contenedor_temperature_weather {
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contenedor_wind_weather {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    flex-direction: column;
    align-items: center;
  }    

  .temperature {
    font-size: 20px;
  }

  .icon_weather {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #ccc;
    border: 1px solid #aaa;
  }

  .icon_weather img {
    height: 30px;
    width: 30px;
  }
  
  .max {
    color: crimson;
  }

  .min {
    color: dodgerblue;
  }

  .wind_text {
    font-size: 18px;
  }

</style>