<template>  
  <div>
    <TransitionGroup name="list" tag="List1Item">
      <List1Item
        v-for="(final, index) in this.getData()"
        :key="index"
        :final="final"
      ></List1Item>
    </TransitionGroup>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'
  import { useTvStore } from '@/store/tv'
  import List1Item from '@/components/tv/List1Item.vue'

  export default {
    name: 'tv-list1',

    data() {
      return {
        elementos: 0
      }
    },

    setup() {
      const config = useConfigStore()
      const tv = useTvStore()

      return {
        config,
        tv,
      }
    },

    methods: {

      init() {
        this.animate()
      },

      getData() {
        switch(this.tv.data.tvPanelType) {

          case "top10":
            return this.config.data.stats.lasttopten.slice(0, this.elementos)

        }
        
      },

      async animate() {
        await this.sleep(50)
        for(var i=0; i < 25; i++) {
          await this.sleep(50)
          this.elementos++
        }
      },

      sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      },
    },

    components: { 
        List1Item,
    },

  }
</script>


<style scoped>

  .list-enter-active, .list-leave-active {
    transition: all 0.1s ease;
  }

  .list-enter-from, .list-leave-to {
    opacity: 0;
    transform: translateX(-30px);
  }

</style>