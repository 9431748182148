<template>
  <div class="container" @click="clickRider(datosRiderFiltrado.dor)">
    <div class="container_name">
      <div class="thumbnail" v-if="tienenThumb && typeof datosRiderFiltrado.data.sum !== 'undefined' && datosRiderFiltrado.data.sum.thu"><img :src="datosRiderFiltrado.data.sum.thu"></div>
      <div class="thumbnail" v-if="tienenThumb && typeof datosRiderFiltrado.data.sum !== 'undefined' && datosRiderFiltrado.data.sum.thu == ''" v-html="iconData('user')"></div>
      <div class="thumbnail" v-if="tienenThumb && typeof datosRiderFiltrado.data.sum === 'undefined'" v-html="iconData('user')"></div>
      <div class="fullname">        
        <div class="container_dorsal_pais">
          <div class="dorsal">{{ datosRiderFiltrado.dor }}</div>
          <div class="pais" v-if="datosRiderFiltrado.pai"><img :src="require(`@/assets/flags/${datosRiderFiltrado.pai}.png`)" /></div>
        </div>
        <div class="container_name_surname">
          <span class="name">{{ datosRiderFiltrado.nfu }}</span> <span class="surname">{{ datosRiderFiltrado.afu }}</span></div>
        </div>
    </div>
    <div class="maillots" v-html="maillotsRiders(clasificaciones.clas)"></div>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'

  export default {

    name: 'summarymaillots-item',

    props: {
      index: Number,
      clasificaciones: Object,
      tienenThumb: Boolean
    },

    setup() {
      const startlist = useStartlistStore()
      const update = useUpdateStore()
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        startlist,
        update,
        config,
        pages
      }
    },

    computed: {      
      datosRiderFiltrado() {
        var datosRider = this.datosRider(this.clasificaciones.dorsal)

        if(datosRider.pai == '' && typeof datosRider.data.sum.pai !== 'undefined')
          datosRider.pai = datosRider.data.sum.pai

        return datosRider
      }
    },

    methods: {

      datosRider(dorsal) {
        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })
        
        return filtradoPorTipo[0]
      },

      maillotsRiders(maillots) {  
        
        var html = ""

        if(typeof maillots !== "undefined") {
          for(var i=0;i<maillots.length;i++) {
            var thumb = this.getClassificationThumb(maillots[i])
            html += thumb
          }
        }
        
        return html
      },

      getClassificationThumb(idtipo) {
        var classification_idiomas = this.config.getClassifications().filter(function(item){
            return item.tip == idtipo
        });

        return "<img style='height:25px;' src='" + classification_idiomas[0].thumb + "'>"
      },

      async clickRider(dorsal) {
        var rider = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('riders')

        if(this.isMobile()) {          
          if(this.config.getConfig().general.fcintegration === '1')
            this.setPageVisible("https://firstcycling.com/m2/rider.php?r=" + rider[0].ifc)
          else {
            this.startlist.setSelectedRider(rider[0])
            this.pages.setVisiblePopup(true)
          }     
        } else {
          this.startlist.setSelectedRider(rider[0])
        }
      },

      async setPageVisible(url) {
        this.pages.setCustomUrl('about:blank')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPageVisible('custom')
        this.pages.setCustomUrl(url)
        window.scrollTo(0,0)
      },
      
      isMobile() {
        return screen.width < 768
      },

      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {
          case "user":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "startlist":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "star":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2 2.3607 7.26543h7.6393l-6.1803 4.49027 2.3606 7.2654-6.1803-4.4902-6.18034 4.4902 2.36068-7.2654-6.18034-4.49027h7.63932z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          default:
            break;
        }
        
      }

    }

  }

</script>

<style scoped>

  .container {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    padding: 5px;
    cursor:pointer;
  }

  .container:last-child {
    border-bottom: 0px;
  }

  .thumbnail {
    height: 40px;
    width: 40px;
    text-align: center;
    margin-right: 10px;
    display: flex;
    justify-content: center;
  }

  .thumbnail img {
    height: 40px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .container_name {
    display: flex;
    align-items: center;
  }

  .container_dorsal_pais {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-right: 15px;
    width: 20px;
    justify-content: center;
  }

  .fullname {
    display: flex;
  }

  .container_name_surname {
    display: flex;
    flex-direction: column;
  }

  .surname {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .pais img {
    height: 10px;    
    border: 1px solid #eee;
  }

  .maillots {
    text-align: right;
    height: 20px;
    display: flex;
    margin-right: 5px;
    align-items: center;
  }

  .dorsal {
    color: #CCC;
    font-size: 12px;
  }

</style>