<template>
  <div>    
    <div class="container_situation">
      <SituationSummary></SituationSummary>
      <situation-group
          v-for="(group, index) in update.data.situation"
          :key="index"
          :id="index"
          :group="group"
      ></situation-group>
      <div v-if="this.showSummaryMaillots()" class="summary_maillots"><SummaryMaillots /></div>
    </div>
  </div>
</template>

<script>

  import { useUpdateStore } from '@/store/update'
  import SituationGroup from '@/components/SituationGroup'
  import SituationSummary from '@/components/SituationSummary'
  import SummaryMaillots from '@/components/SummaryMaillots'

  export default {
    name: 'situation',

    setup() {
      const update = useUpdateStore()

      return {
        update
      }
    },

    methods: {

      showSummaryMaillots() {
        
        if(typeof this.update.getResultsRidersStart().length === 'undefined' || typeof this.update.getResultsRidersVirtual().length === 'undefined')
          return true
        else
          return false

      }

    },

    components: { 
        SituationGroup,
        SituationSummary,
        SummaryMaillots,
    },

  }
</script>

<style scoped>

  @media (max-width: 768px) {
    .container_situation {
      flex-direction: column;
      padding-right: 10px !important;
      padding-left: 10px !important;
      padding-top: 10px !important;
    }

    .summary_maillots {
      display: none !important;
      background: red !important;
    }
  }

  .container_situation {
    margin:10px 0;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .summary_maillots {
    width: 100%;
  }


</style>
