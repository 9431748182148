<template>  
  <div class="container" @click="clickRider(rider.dor)">
    <div class="position">{{ rider.dor }}</div>
    <div style="height:12px"><img class="country" :src="require(`@/assets/flags/${rider.pai}.png`)" /></div>
    <div class="rider_name">{{ rider.nfu }} <span style="text-transform: uppercase; font-weight: bold;">{{ rider.afu }}</span></div>
    <div class="result" v-if="rider.fec !== 0">{{ rider.fec }}</div>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { usePagesStore } from '@/store/pages'
  import { useConfigStore } from '@/store/config'

  export default {
    name: 'situationgroupinfoage',

    props: {
        id: Number,
        rider: Object
    },

    setup() {
      const startlist = useStartlistStore()
      const pages = usePagesStore()
      const config = useConfigStore()

      return {
        startlist,
        pages,
        config
      }
    },

    methods: {

      async clickRider(dorsal) {
        var rider = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('riders')

        if(this.isMobile()) {          
          if(this.config.getConfig().general.fcintegration === '1') {
            this.setPageVisible("https://firstcycling.com/m2/rider.php?r=" + rider[0].ifc)
            this.pages.setVisiblePopup(false)
          } else {
            this.startlist.setSelectedRider(rider[0])
            this.pages.setVisiblePopup(true)
          }     
        } else {
          this.startlist.setSelectedRider(rider[0])
        }
      },

      setPageVisible(url) {
        this.pages.setPageVisible('custom')
        this.pages.setCustomUrl(url)
        window.scrollTo(0,0)
      },
      
      isMobile() {
        return screen.width < 768
      },


    }

  }

</script>

<style scoped>

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px auto 50px;
    padding: 12px 10px;
    font-size: 12px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
    cursor: pointer;
  }

  .container:hover {
    background: #fafafa;
  }

  .country {
    height: 12px;
    display:flex;
  }

  .result {
    text-align: right;
  }


</style>