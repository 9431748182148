<template>
  <div class="container_history">
    <div class="title">HISTORY</div>
    <RaceInfo type="simple" fullracename="0" />
    <List2 ref="list2" />
  </div>

</template>

<script>

  import List2 from '@/components/tv/List2.vue'
  import RaceInfo from '@/components/tv/RaceInfo.vue'  

  export default {
    name: 'tv-history',

    mounted() {
      this.$refs.list2.init()
    },

    components: { 
      List2,
      RaceInfo
    },

  }
</script>

<style scoped>

  .title {
    font-size: 30px;
    color: white;
    letter-spacing: 5px;
    font-weight: bold;
    height: 35px;
  }

</style>