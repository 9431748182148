<template>
  <div class="container" @click="abrirPopup()">
    <div class="container_racetitle">
      <div class="thumb" v-if="this.config.getConfig().general.race_thumb"><img :src="this.config.getConfig().general.race_thumb"></div>
      <div class="name">
        <div class="title">{{ this.config.getConfig().general.name }}</div>
        <div class="subtitle" v-if="this.config.getConfig().general.city_start">
          <span class='stage' v-if="this.config.getConfig().general.stage != 'NA' && this.config.getConfig().general.stage != ''">
            {{ $t('stage') }} {{ this.config.getConfig().general.stage }}
          </span>
          {{ this.config.getConfig().general.city_start }} - {{ this.config.getConfig().general.city_finish }}
        </div>
      </div>
    </div>
    <div class="icon_menu" style="height:24px" v-html="iconData('info')"></div>
  </div>
</template>

<script>

  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'

  export default {
    name: 'race-title',

    setup() {
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        config,
        pages
      }
    },

    methods: {

      abrirPopup() {

        if(this.pages.getPopupType() === '')
          this.pages.setPopupType('race')
        else
          this.pages.setPopupType('')

        if(this.isMobile())
          this.pages.setVisiblePopup(true)
      },

      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {

          case "info":
            return '<svg class="icon_menu_header" style="stroke:#ddd" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6.01953v-.01953"/><path d="m12 10v8"/></g></svg>'

          default:
            break;
        }
        
      },

      isMobile() {
        return screen.width < 768
      },
    }

  }
</script>

<style scoped>

  .container {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border-radius: 5px;
  }

  .container_racetitle {
    display: flex;
    align-items: center;
  }

  .container:hover {
    background: white;
  }

  .thumb {
    display: flex;
  }

  .thumb img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: 10px;
    border: 1px solid #DBDBDB;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.05));
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .subtitle {
    color: #555;
    font-size: 13px;
  }

  .stage {
    font-weight: bold;
    text-transform: uppercase;
    color: #999;
  }

</style>