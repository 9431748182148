<template>
  
  <div class="container_rider">
    <RaceInfo type="simple" />
    <Transition name="thumb" @after-enter="this.endTransition('thumb')">
      <div class="thumb_container" v-if="this.panelVisible.includes('thumb')"> 
        <div class="bib">#{{ startlist.selectedRider.dor }}</div>
        <div class="flag"><img v-if="startlist.selectedRider.pai" :src="require(`@/assets/flags/${startlist.selectedRider.pai}.png`)" /></div>
        <img class="thumb" :src="startlist.selectedRider.data.sum.thu">
        <div class="title">
          <div class="name">{{ startlist.selectedRider.nfu }} {{ startlist.selectedRider.afu.toUpperCase() }}</div>
          <span class="name_team">{{ startlist.selectedRider.neq }}</span>
        </div>
      </div>
    </Transition>
    <Transition name="thumb">
      <div class="data" v-if="this.panelVisible.includes('data')">
        <div class="data_linea"><span class="data_linea_titulo">{{ $t('country') }}</span><span class="data_linea_titulo_dato">{{ this.nombrePais(startlist.selectedRider.pai.toUpperCase()) }}</span></div>
        <div class="data_linea"><span class="data_linea_titulo">{{ $t('birthdate') }}</span><span class="data_linea_titulo_dato">{{ startlist.selectedRider.data.sum.fec }}</span></div>
        <div class="data_linea"><span class="data_linea_titulo">{{ $t('age') }}</span><span class="data_linea_titulo_dato">{{ getAge(startlist.selectedRider.data.sum.fec) }}</span></div>
        <div class="data_linea"><span class="data_linea_titulo">{{ $t('wins') }}</span><span class="data_linea_titulo_dato">{{ startlist.selectedRider.data.stats.wins }}</span></div>
      </div>
    </Transition>
  </div>

</template>

<script>
  
  import { useStartlistStore } from '@/store/startlist'
  import RaceInfo from '@/components/tv/RaceInfo.vue'  

  export default {
    name: 'tv-rider',

    data() {
      return {
        panelVisible: []
      }
    },

    created() {
      this.init()
    },

    setup() {
      const startlist = useStartlistStore()

      return {
        startlist
      }
    },

    methods: {

      async init() {
        await this.sleep(10)
        this.panelVisible.push('thumb')
      },

      endTransition(panel) {
        switch (panel) {
          case 'thumb':
            this.panelVisible.push('data')
          break

        }
      },

      getTeam(code) {
        return this.startlist.getTeams().filter((team) => team.uci == code)
      },

      getAge(date) {
        var dob = new Date(date)
        var month_diff = Date.now() - dob.getTime()
        var age_dt = new Date(month_diff)
        var year = age_dt.getUTCFullYear()
        var age = Math.abs(year - 1970)

        return age
      }, 

      nombrePais(codigo) {
        let regionNames = new Intl.DisplayNames([this.$i18n.locale], {type: 'region'})
        return regionNames.of(codigo)
      },

      sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      },
    },

    components: {
      RaceInfo
    }

  }
</script>

<style scoped>

  .thumb-enter-active, .thumb-leave-active {
    transition: all 1s ease;
  }

  .thumb-enter-from, .thumb-leave-to {
    opacity: 0;
    transform: translateX(-30px);
  }

  .container_rider {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .thumb_container {
    width: 360px;    
    height: 360px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .thumb {
    height: 250px;
  }

  .thumb > img {
    width: 100%;
    margin-top: 0px;
  }

  .bib {
    position: absolute;
    top: 0px;
    left: 0px;
    color: #ddd;
    font-size: 40px;
    font-weight: bold;
  }

  .flag {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .flag > img {
    height: 35px;
  }

  .name {
    color: white;
    font-size: 30px;
    letter-spacing: 2px;
    text-align: center;
  }

  .title {
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }

  .name_team {
    color: #888;
    font-size: 20px;
    letter-spacing: 2px;
  }

  .data {
    display: flex;
    width: 360px;
    flex-wrap: wrap;
    margin-top: 10px;
    font-size: 20px;
  }

  .data_linea {
    width: 154px;
    height: 60px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    background: crimson;
    margin: 3px;
    border-radius: 5px;
  }

  .data_linea_titulo {
    color: #ddd;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 5px;
    text-align: center;
    letter-spacing: 1px;
  }

  .data_linea_titulo_dato {
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
  }

</style>