<template>
  <div class="container">
    <iframe :src="this.getURL()" id="iframe" scrolling="no"></iframe>
  </div>
</template>

<script>

  import { usePagesStore } from '@/store/pages'
  import { useStartlistStore } from '@/store/startlist'

  export default {
    name: 'layoutcustom',

    props: {
      url: String
    },

    data() {
      return {
        iframeHeight: '300px', // initial height
      };
    },

    setup() {
      const pages = usePagesStore()
      const startlist = useStartlistStore()

      return {
        pages,
        startlist
      }
    },

    mounted() {

      let iframe = document.querySelector("#iframe");
      /*window.addEventListener('message', function(e) {
        let message = e.data;

        if(typeof message.height !== 'undefined') {
        /iframe.style.height = message.height + 'px';
        }
      } , false);*/

      iframe.style.height = '3000px';
      iframe.style.opacity = "1";

    },

    methods: {
      getURL() {
        //return "https://tracker.helmuga.cloud/temp/testpogi.htm"
        return "https://firstcycling.com/m2/rider.php?r=" + this.startlist.getSelectedRider().ifc
      }
    }
  }

</script>

<style scoped>

  @media (max-width: 768px) {
    
    #iframe {
      height: calc(100% - 4px) !important;
    }
    
  }

  .container {
    width: 100%;
    height: 100%;
    background:url(https://tracker.helmuga.cloud/images/loading.gif) center center no-repeat;
    overflow: auto;
  }

  #iframe {
    width: 100%;
    border: 0;
    opacity: 0;
  }

</style>
