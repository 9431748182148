/**
 * Mocking client-server processing
 */

 import axios from 'axios'

 export default {
 
   getUpdate (raceid, callback) {
 
    //let url = 'https://tracker.helmuga.cloud/livetracker-backend/livetracker_update.php?tv=1&idcarrera=' + raceid
    let url = 'https://tracker.helmuga.cloud/position_gps_tv.php?idcarrera=' + raceid
        
    axios.get(url)
    .then(response => { 
      callback(response.data)
    })
 
   }
 }
 