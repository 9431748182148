<template>
  <div class="container_situation">
    <SituationGroup
      v-for="(group, index) in this.tv.getUpdate()"
      :key="index"
      :group="group"
    ></SituationGroup>
  </div>
</template>

<script>
  
  import { useTvStore } from '@/store/tv'
  import SituationGroup from '@/components/tv/SituationGroup.vue'

  export default {
    name: 'tv-situation',

    setup() {
      const tv = useTvStore()

      return {
        tv
      }
    },
    
    components: {
      SituationGroup,
    }

  }
</script>

<style scoped>

  .container_situation {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }

</style>